import React, {ReactElement, ReactNode} from 'react'
import {Controller} from 'react-hook-form'
import {ControllerCommonProps} from './ControllerInput'
import {Checkbox} from 'ac_ui'
import {getErrorsControllerInput} from '../utils/getErrorsControllerInput'

export interface IControllerCheckboxProps {
  name: string
  label?: React.ReactElement
  required?: boolean
  disabled?: boolean
  labelClassName?: string
  common: ControllerCommonProps<any> | any
  className?: string
}

const ControllerCheckbox: React.FC<IControllerCheckboxProps> = ({
  name,
  label,
  required,
  disabled,
  labelClassName,
  common,
  className,
}): React.ReactElement => {
  const {setValue, control} = common

  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field, formState}) => {
        const onChangeValue = (newChecked: boolean): void => {
          field.onChange(newChecked)
          setValue(field.name, newChecked)
        }

        const errorItems: ReactNode[] = getErrorsControllerInput<any>(formState, field.name, true)

        const feedBack: ReactElement | undefined = errorItems.length ? (
          <div className={'d-flex flex-column'}>{errorItems}</div>
        ) : undefined

        return (
          <div className={className ? className : 'mb-x6'}>
            <Checkbox
              id={field.name}
              name={field.name}
              type={'checkbox'}
              labelClassName={labelClassName}
              color={'primary'}
              checked={!!field.value}
              className={errorItems.length > 0 ? 'mb-x2' : 'mb-0'}
              label={label}
              disabled={disabled}
              onChange={onChangeValue}
            />
            {feedBack}
          </div>
        )
      }}
    />
  )
}

export {ControllerCheckbox}
