import React, {ReactElement, ReactNode} from 'react'
import {Controller} from 'react-hook-form'
import {ControllerCommonProps} from './ControllerInput'
import {Select, SelectOption} from 'ac_ui'
import {getErrorsControllerInput} from '../utils/getErrorsControllerInput'

export interface IControllerSelectProps {
  name: string
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
  options: SelectOption[]
  menuPosition?: 'absolute' | 'fixed'
  common: ControllerCommonProps<any> | any
  className?: string
}

const ControllerSelect: React.FC<IControllerSelectProps> = ({
  name,
  placeholder,
  label,
  required,
  disabled,
  options,
  menuPosition,
  common,
  className,
}): React.ReactElement => {
  const {control, setValue} = common
  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field, formState}) => {
        const onChangeValue = (newValue: string): void => {
          const newSubjectId: string = newValue?.trim()
          field.onChange(newSubjectId)
          setValue(field.name, newSubjectId)
        }
        const errorItems: ReactNode[] = getErrorsControllerInput<any>(formState, field.name, true)

        const feedBack: ReactElement | undefined = errorItems.length ? (
          <div className={'d-flex flex-column'}>{errorItems}</div>
        ) : undefined

        return (
          <Select
            id={field.name}
            name={field.name}
            isClearable
            value={field.value}
            valid={feedBack ? false : undefined}
            disabled={disabled}
            feedback={feedBack}
            label={label}
            options={options}
            isShowRequired={required}
            placeholder={placeholder}
            className={className ? className : ''}
            onChange={onChangeValue}
            menuPosition={menuPosition}
          />
        )
      }}
    />
  )
}

export {ControllerSelect}
