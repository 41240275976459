import React from 'react'
import {ButtonLink} from 'ac_ui'
import {acArrowRight} from 'ac-icon'

const NotFoundPage: React.FC = () => {
  return (
    <div className={'not-found-page'}>
      <div className={'go-to-home'}>
        <ButtonLink
          size={'lg'}
          color={'white'}
          iconPosition={'right'}
          className={'btn-shadow'}
          iconPath={acArrowRight}
          href={'/'}>
          На главную
        </ButtonLink>
      </div>
    </div>
  )
}

export {NotFoundPage}
