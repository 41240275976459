import React from 'react'
import {acCheck, acPlay} from 'ac-icon'
import {Icon, THEME_COLORS} from 'ac_ui'
import {TaskDto, TaskListItemDto} from 'learndb-share'
import {URLS} from '../../../constants/urls'

interface ITaskListProps {
  tasks: TaskDto[] | TaskListItemDto[]
}

const TaskList: React.FC<ITaskListProps> = ({tasks}) => {
  if (tasks) {
    const items = tasks.map((task, index) => (
      <li key={task.taskId} className={'task-list-item'}>
        <a href={URLS.tasks + '/' + task.taskId}>
          <span className={'task-list-item-number'}>{index + 1}.</span>
          <span className={'task-list-item-name'}>{task.name}</span>
          <span className={'task-list-item-controls'}>
            {task.isSolved ? (
              <span className={'solved'}>
                <Icon path={acCheck} color={THEME_COLORS.success} className={'mr-x1'} title={'Выполнено'} />
                <span>Выполнено</span>
              </span>
            ) : undefined}
            <span className={'unsolved'}>
              <Icon path={acPlay} color={THEME_COLORS.primary} className={'mr-x1'} title={'Начать'} />
              <span className={'text'}>Начать</span>
            </span>
          </span>
        </a>
      </li>
    ))
    return (
      <nav>
        <ul className={'task-list'}>{items}</ul>
      </nav>
    )
  } else {
    return null
  }
}

export default TaskList
