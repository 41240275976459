import React, {useEffect, useState} from 'react'
import * as yup from 'yup'
import {getName, getNowTime, isEmptyObject, isServer, regExp, StringAnyMap} from 'ac-share'
import {FeedbackCreateDto, ResponseError, UserDto} from 'learndb-share'
import {yupResolver} from '@hookform/resolvers/yup'
import {FormProvider, useForm} from 'react-hook-form'
import {ControllerCommonProps} from '../common/formController/components/ControllerInput'
import {apiPost} from '../../api'
import {useLoadFeedbackThemes} from './hooks/useLoadFeedbackThemes'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {FeedbackForm} from './components/FeedbackForm'
import {ymTarget} from '../../metrics/ym/ymTarget'
import {YmTargetEnum} from '../../metrics/ym/YmTargetEnum'

interface IFeedbackFormModalProps {
  onClose: () => void
}

export interface IFeedbackFormModalState {
  localFeedback: FeedbackCreateDto
  yupSchemaConfig?: StringAnyMap
}

export interface IFeedbackFormValues {
  name: string
  email: string
  feedbackThemeId: string
  message: string
  isAgreement: boolean
}

const FeedbackFormModal: React.FC<IFeedbackFormModalProps> = ({onClose}): React.ReactElement => {
  const feedbackThemes = useLoadFeedbackThemes()

  const currentUrl = !isServer ? window.location.href : '-'

  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  const initState: IFeedbackFormModalState = {
    localFeedback: {
      name: undefined,
      email: undefined,
      feedbackThemeId: undefined,
      message: undefined,
      isAgreement: false,
    },
    yupSchemaConfig: {
      name: yup
        .string()
        .required('Необходимо заполнить имя')
        .nullable()
        .max(256, 'Имя превышает допустимую длину 256 символов'),
      email: yup
        .string()
        .nullable()
        .required('Необходимо заполнить email')
        .matches(regExp.checkMail, 'Неверный формат email')
        .max(320, 'Email превышает допустимую длину 320 символов'),
      feedbackThemeId: yup.string().nullable().required('Необходимо выбрать тему обращения'),
      message: yup
        .string()
        .required('Необходимо заполнить сообщение')
        .nullable()
        .max(8192, 'Сообщение превышает допустимую длину 8192 символов'),
      isAgreement: yup.boolean().oneOf([true], 'Необходимо принять соглашение'),
    },
  }

  const [submitError, setSubmitError] = useState<ResponseError | undefined>(undefined)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false)
  const [isSubmitRequest, setIsSubmitRequest] = useState<boolean>(false)

  const [state] = useState<IFeedbackFormModalState>({...initState})

  const methods = useForm<IFeedbackFormValues>({
    resolver: yupResolver(yup.object(state.yupSchemaConfig), {abortEarly: false}),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      name: user ? getName(user) : undefined,
      email: user && user.email ? user.email : undefined,
      feedbackThemeId: undefined,
      message: undefined,
      isAgreement: false,
    },
  })

  const errors = methods?.formState?.errors

  useEffect(() => {
    ymTarget(YmTargetEnum.OPEN_FEEDBACK)
  }, [])

  useEffect(() => {
    if (!isEmptyObject(errors)) {
      let id
      for (const key in errors) {
        if (key) {
          id = key
          break
        }
      }
      if (id) {
        setTimeout(() => {
          const element = document.getElementById(`feedback-${id}`)
          element.scrollIntoView({behavior: 'smooth'})
        }, 100)
      }
    }
  }, [errors])

  const onSubmit = async (): Promise<void> => {
    const {watch} = methods
    const {name, feedbackThemeId, email, isAgreement, message} = watch()
    const newFeedback: FeedbackCreateDto = {
      name: name,
      email: email,
      feedbackThemeId: feedbackThemeId,
      message: message,
      isAgreement: isAgreement,
      url: currentUrl,
    }

    const startTime: number = getNowTime()
    try {
      setIsSubmitRequest(true)
      setSubmitError(undefined)
      await apiPost('/support/feedback-create', newFeedback)

      const endTime: number = getNowTime()
      const timeout: number = startTime - endTime
      setTimeout(
        () => {
          setIsSubmitRequest(false)
          setIsSubmitSuccess(true)
        },
        timeout > 1000 ? 0 : 1000 - timeout,
      )
    } catch (err: any) {
      setSubmitError({
        status: err.status,
        errorMessage: err.errorMessage,
      })
      setIsSubmitRequest(false)
    }
  }

  const common: ControllerCommonProps<IFeedbackFormValues> = {
    control: methods.control,
    setValue: methods.setValue,
  }

  return (
    <FormProvider {...methods}>
      <FeedbackForm
        submitError={submitError}
        feedbackThemes={feedbackThemes}
        common={common}
        onSubmit={methods.handleSubmit(async () => {
          await onSubmit()
        })}
        onClose={onClose}
        isSubmitRequest={isSubmitRequest}
        isSubmitSuccess={isSubmitSuccess}
      />
    </FormProvider>
  )
}

export {FeedbackFormModal}
