import {YmTargetEnum} from './YmTargetEnum'
import {isUseCounters} from '../utils'
import {SEO_COUNTERS} from '../constants'

function ymTarget(target: YmTargetEnum): void {
  if (isUseCounters()) {
    try {
      if (target) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ym(SEO_COUNTERS.YANDEX, 'reachGoal', target)
      }
    } catch (e) {
      console.error(e)
    }
  } else {
    console.log('NOT USE YM "' + target + '"')
  }
}

export {ymTarget}
