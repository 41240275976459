import React, {useEffect} from 'react'
import {URLS} from '../../constants/urls'
import {isServer} from 'ac-share'
import {useLoadLegal} from './hooks/useLoadLegal'
import {HtmlView, Loading} from 'ac_ui'

const BASE_URL = 'https://learndb.ru'

const OfferLegal: React.FC = () => {
  let offerLegal = useLoadLegal('offerLegal')

  useEffect(() => {
    if (!isServer) {
      window.scrollTo(0, 0)
    }
  }, [])

  if (offerLegal) {
    offerLegal = offerLegal.replace(/\{BASE_URL\}/gi, BASE_URL)
    offerLegal = offerLegal.replace(/\{URLS.legal \+ URLS.offerLegal\}/gi, BASE_URL + URLS.legal + URLS.offerLegal)
    offerLegal = offerLegal.replace(/\{URLS.themes\}/gi, BASE_URL + URLS.themes)
    offerLegal = offerLegal.replace(/\{URLS.payment\}/gi, BASE_URL + URLS.payment)
  }

  return (
    <div className={'legal-content'}>
      {offerLegal ? (
        <HtmlView html={offerLegal} />
      ) : (
        <div className={'d-flex flex-column align-items-center w-100 pt-x4'}>
          <Loading />
        </div>
      )}
    </div>
  )
}

export {OfferLegal}
