import React from 'react'
import {CollapseWrapper, COLORS, Icon, PopoverUncontrolled, MultiLink} from 'ac_ui'
import {acTimerSand} from 'ac-icon'
import {ArticleInSectionDto, ArticleStatusEnum, SectionArticlesDto} from 'learndb-share'
import {StringBooleanMap} from 'ac-share'
import classNames from 'classnames'

interface IArticleSectionProps {
  idPrefix: string
  articleSection: SectionArticlesDto
  sectionNumber: string
  collapseMap: StringBooleanMap
  setCollapseMap: (map: StringBooleanMap) => void
  activeArticleId?: number
  withBorderBottom?: boolean
  onClickHref: (e?: any) => void
  isInnerArticle: boolean
}

const ArticleSection: React.FC<IArticleSectionProps> = ({
  idPrefix,
  articleSection,
  sectionNumber,
  collapseMap,
  setCollapseMap,
  activeArticleId,
  withBorderBottom,
  onClickHref,
  isInnerArticle,
}) => {
  const getRenderAnnounceBlock = (article: ArticleInSectionDto): React.ReactElement => {
    return (
      <div className={'d-flex align-items-center'}>
        <span className={'article-name-announce '}>{article.number + ' ' + article.name}</span>
        <div id={'article-announce' + article.articleId}>
          <Icon path={acTimerSand} color={COLORS.gray500} title={'Скоро'} id={'announce-icon' + article.articleId} />
        </div>
        <PopoverUncontrolled target={'article-announce' + article.articleId} placement={'right'} trigger={'hover'}>
          Скоро появится
        </PopoverUncontrolled>
      </div>
    )
  }

  const items = articleSection.articles.map((article) => (
    <li
      className={classNames('article-name', {'is-inner-article': isInnerArticle})}
      id={`list-item-${idPrefix}-${article.articleId}`}
      key={article.articleId}>
      {article.statusId !== ArticleStatusEnum.ANNOUNCE ? (
        <div>
          <MultiLink
            href={`/articles/article/${article.articleId}`}
            onClick={onClickHref}
            className={'article-name-link ' + (article.articleId === activeArticleId ? 'is-active' : '')}>
            <div>{article.number + ' ' + article.name}</div>
          </MultiLink>
        </div>
      ) : (
        getRenderAnnounceBlock(article)
      )}
    </li>
  ))

  return (
    <div id={articleSection.sectionName} className={'article-section'}>
      <CollapseWrapper
        collapseChevronPosition={'center-left'}
        label={sectionNumber + '. ' + articleSection.sectionName}
        labelBlockClassName={classNames('collapse-label-hover', {
          'border-bottom': withBorderBottom && !collapseMap[articleSection.sectionId],
        })}
        labelTextClassName={classNames('collapse-label', {'is-inner-article': isInnerArticle})}
        labelBlockId={`list-${idPrefix}-${articleSection.sectionId}`}
        isOpenProp={collapseMap[articleSection.sectionId]}
        toggleProp={(value) => {
          collapseMap[articleSection.sectionId] = value
          setCollapseMap({...collapseMap})
        }}>
        <ul
          className={classNames('list', {
            'border-bottom': withBorderBottom,
            'is-inner-article': isInnerArticle,
          })}>
          {items}
        </ul>
      </CollapseWrapper>
    </div>
  )
}

export {ArticleSection}
