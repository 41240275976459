import React from 'react'
import {Avatar, Container} from 'ac_ui'
import {SubscriptionCard} from './subscription/components/SubscriptionCard'
import {SubscriptionPeriod, UserDto} from 'learndb-share'
import classNames from 'classnames'
import {useLoadPriceMap} from '../payment/hooks/useLoadPriceMap'
import {CONFIG} from '../../constants'

interface IUserInfoProps {
  user: UserDto
  className?: string
}

const UserInfo: React.FC<IUserInfoProps> = ({user, className}) => {
  const priceMap = useLoadPriceMap()

  let priceMonth
  if (priceMap && !priceMonth) {
    priceMonth = priceMap[SubscriptionPeriod.MONTH]
  }

  if (user) {
    const {firstName, lastName, userId, dateEndSubscription, isProAccount} = user
    const css = classNames('user-info', className)
    return (
      <section className={css}>
        <Container className={'user-info-container'}>
          <div className={'user-info-wrapper'}>
            <div className={'d-flex'}>
              <Avatar
                size={'xxl'}
                imageUrl={user?.avatarFileName ? CONFIG.cdnWeb + '/' + user?.avatarFileName : undefined}
              />
            </div>
            <div className={'user-info-content'}>
              <p className={'user-info-content-fio'}>{lastName}</p>
              <p className={'user-info-content-fio'}>{firstName}</p>
              <p className={'user-info-content-id'}>id{userId}</p>
            </div>
          </div>
          <SubscriptionCard
            isAuth={!!user}
            dateEndSubscription={dateEndSubscription}
            priceMonth={priceMonth}
            isProAccount={isProAccount}
          />
        </Container>
      </section>
    )
  } else {
    return <></>
  }
}

export {UserInfo}
