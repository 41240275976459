import React from 'react'
import {LearnDBPlusSvg, MultiLink} from 'ac_ui'
import classNames from 'classnames'
import {useSelector} from 'react-redux'
import {UserDto} from 'learndb-share'
import {IReduxState} from '../../../redux/types/IReduxState'
import {URLS} from '../../../constants/urls'

interface IFeePayingBadgeProps {
  id?: string
  isFeePaying: boolean
  className?: string
}

const FeePayingBadge: React.FC<IFeePayingBadgeProps> = ({isFeePaying, id, className}) => {
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  const isAuth = !!user
  if (isFeePaying) {
    const css = classNames('', className)
    return (
      <MultiLink href={isAuth ? URLS.profile + URLS.profileSubscription : URLS.payment} type={'href'}>
        <span className={css} title={'По подписке'}>
          <LearnDBPlusSvg id={id} />
        </span>
      </MultiLink>
    )
  } else {
    return null
  }
}

export default FeePayingBadge
