import React from 'react'
import {Col, Container, Loading, Row} from 'ac_ui'
import {AchievementCard} from '../components/AchievementCard'
import {AchievementCategoryDto, AchievementDto} from 'learndb-share'
import {EmptyList} from '../../../common/EmptyList'

interface IAchievementViewProps {
  achievementCategories: AchievementCategoryDto[]
}

const AchievementView: React.FC<IAchievementViewProps> = ({achievementCategories}): React.ReactElement => {
  const renderAchievementBlock = (achievements: AchievementDto[]): React.ReactElement => {
    const items: React.ReactElement[] = []
    if (achievements.length > 0) {
      for (let i = 0; i < achievements.length; i++) {
        const achievement = achievements[i]
        items.push(
          <Col
            key={achievement.achievementId}
            attribute={{
              id: 'achievement-card-' + achievement.achievementId,
            }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            className={'achievement-view-list-item'}>
            <AchievementCard achievement={achievement} className={'h-100'} />
          </Col>,
        )
      }
    } else {
      items.push(
        <Col key={'not-found-achievement'} xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={'d-flex flex-row align-items-center w-100'}>
            <EmptyList
              type={'NOT_FOUND'}
              title={'Пока нет достижений'}
              description={'Выполняйте задания чтобы получить достижения'}
            />
          </div>
        </Col>,
      )
    }

    return <Row>{items}</Row>
  }

  const renderAchievementCategory = (): React.ReactElement => {
    const item: React.ReactElement[] = []
    for (const category of achievementCategories || []) {
      item.push(
        <section key={category.achievementCategoryId} className={'achievement-view-list'}>
          <Container>
            <h1 className={'achievement-view-list-h1'}>{category.name}</h1>
            {renderAchievementBlock(category.achievements || [])}
          </Container>
        </section>,
      )
    }
    return <div className={'achievement-view-list-wrapper'}>{item}</div>
  }

  const renderHeader = (): React.ReactElement => {
    let allAchievementLen = 0
    let completedAchievementLen = 0
    for (const category of achievementCategories || []) {
      for (const achievement of category.achievements || []) {
        if (achievement.userAchievement) {
          completedAchievementLen++
        }
        allAchievementLen++
      }
    }

    return (
      <section className={'profile-view-header'}>
        <Container>
          <h1 className={'profile-view-header-h1'}>Достижения</h1>
          <p className={'profile-view-header-p'}>
            Получено {completedAchievementLen} из {allAchievementLen}
          </p>
        </Container>
      </section>
    )
  }

  return (
    <div className={'achievement-view'}>
      {renderHeader()}
      {achievementCategories ? (
        renderAchievementCategory()
      ) : (
        <div className={'d-flex align-items-center justify-content-center pt-x6'}>
          <Loading />
        </div>
      )}
    </div>
  )
}

export {AchievementView}
