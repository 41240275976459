import React from 'react'
import {Container} from 'ac_ui'
import {getCountString} from 'ac-share'
import {LandingDto} from 'learndb-share'

interface IStatisticsProps {
  landing: LandingDto | undefined
}

const Statistics: React.FC<IStatisticsProps> = ({landing}) => {
  const numberWithSpaces = (x: number): string => {
    if (!x) {
      return '-'
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  let countUsersTriedOutCourse: number
  let countCompletedTask: number
  if (landing) {
    countUsersTriedOutCourse = landing.taskSolveStatistics.countUsersTriedOutCourse
    countCompletedTask = landing.taskSolveStatistics.countCompletedTask
  }
  return (
    <section className={'statistics-landing'}>
      <Container className={'statistics-landing-container'}>
        <h2 className={'landing-h2 statistics-landing-h2'}>Статистика</h2>
        <div className={'statistics-landing-list'}>
          <div className={'statistics-landing-card'}>
            <span className={'count'}>{numberWithSpaces(countCompletedTask)}</span>
            <span className={'name'}>
              {getCountString(countCompletedTask, 'Пройденный', 'Пройденных', 'Пройденных')} <br />
              {getCountString(countCompletedTask, 'урок', 'урока', 'уроков')}
            </span>
          </div>
          <div className={'statistics-landing-card'}>
            <span className={'count'}>{numberWithSpaces(countUsersTriedOutCourse)}</span>
            <span className={'name'}>
              Опробовали <br />
              курсы
            </span>
          </div>
        </div>
        <p className={'statistics-landing-help'}>Цифры честные и обновляются раз в час</p>
      </Container>
    </section>
  )
}

export {Statistics}
