import React from 'react'
import classNames from 'classnames'
import {ButtonLink, LearnDBPlusSvg, MultiLink} from 'ac_ui'
import {URLS} from '../../../../constants/urls'
import {SubscriptionHistoryModal} from './SubscriptionHistoryModal'
import {useLocation} from 'react-router-dom'
import {SubscriptionPriceDto} from 'learndb-share'
import {formatDate} from 'ac-share'

interface ISubscriptionCardProps {
  isAuth: boolean
  dateEndSubscription: Date
  priceMonth: SubscriptionPriceDto
  isProAccount: boolean
  classNameCard?: string
  className?: string
}

const SubscriptionCard: React.FC<ISubscriptionCardProps> = ({
  isProAccount,
  dateEndSubscription,
  priceMonth,
  isAuth,
  classNameCard,
  className,
}): React.ReactElement => {
  const location = useLocation()

  let isDiscount = false
  if (priceMonth) {
    isDiscount = priceMonth.discountPercent > 0 || priceMonth.discountSum > 0
  }
  //TODO:: когда нет скидки отображение

  const css = classNames('subscription-card-wrapper', className)
  const cssCard = classNames('subscription-card', classNameCard)

  const name = isProAccount ? 'Подписка действует' : isDiscount ? 'Оформить подписку' : 'Подписка'

  const description = isProAccount
    ? 'до ' + formatDate(dateEndSubscription)
    : 'Подписка открывает доступ ко всем темам, включая все обновления на период подписки.'

  const cardContent: React.ReactElement = (
    <>
      <div className={'d-flex align-items-center'} title={'Подписка'}>
        <LearnDBPlusSvg id={'subscription-card-plus'} width={56} height={56} />
      </div>
      <div className={'subscription-card-info'}>
        {isProAccount ? (
          <>
            <p className={'subscription-card-active-name'}>{name}</p>
            <p className={'subscription-card-active-description'}>{description}</p>
          </>
        ) : (
          <>
            <p className={'subscription-card-name'}>{name}</p>
            <p className={'subscription-card-description'}>{description}</p>
            <ButtonLink
              color={'lime'}
              className={'w-100'}
              size={'sm'}
              to={URLS.profile + URLS.profileSubscription + '?toMonth=true'}>
              {isDiscount ? (
                <>
                  Со скидкой {priceMonth.discountPercent || priceMonth.discountSum}
                  {priceMonth.discountPercent ? '%' : <>&#8381;</>}
                </>
              ) : (
                <>Оформить</>
              )}
            </ButtonLink>
          </>
        )}
      </div>
    </>
  )

  let content: React.ReactElement
  const href = isAuth ? URLS.profile + URLS.profileSubscription : URLS.payment
  if (isProAccount && location.pathname !== href) {
    content = (
      <MultiLink href={href} type={'href'} className={classNames(cssCard, 'text-decoration-none-i')}>
        {cardContent}
      </MultiLink>
    )
  } else {
    content = <div className={cssCard}>{cardContent}</div>
  }

  return (
    <div className={css}>
      {content}
      {isAuth && <SubscriptionHistoryModal />}
    </div>
  )
}

export {SubscriptionCard}
