import {constants} from '../../../redux/constants'

export interface IFeedbackState {
  isOpenModal?: boolean
}

const INITIAL_STATE: IFeedbackState = {
  isOpenModal: false,
}

function feedbackReducers(state: IFeedbackState = INITIAL_STATE, action): IFeedbackState {
  switch (action.type) {
    case constants.SET_FEEDBACK_STATE: {
      return {...state, ...action.state}
    }

    default:
      return state
  }
}

export {feedbackReducers}
