import React, {useEffect} from 'react'
import {isServer} from 'ac-share'
import {HtmlView, Loading} from 'ac_ui'
import {useLoadLegal} from './hooks/useLoadLegal'
import {URLS} from '../../constants/urls'

const BASE_URL = 'https://learndb.ru'

const License: React.FC = () => {
  let license = useLoadLegal('license')

  useEffect(() => {
    if (!isServer) {
      window.scrollTo(0, 0)
    }
  }, [])

  if (license) {
    license = license.replace(/\{URLS.legal \+ URLS.license\}/gi, BASE_URL + URLS.legal + URLS.license)
    license = license.replace(/\{URLS.legal \+ URLS.privacy\}/gi, BASE_URL + URLS.legal + URLS.privacy)
  }

  return (
    <div className={'legal-content'}>
      {license ? (
        <HtmlView html={license} />
      ) : (
        <div className={'d-flex flex-column align-items-center w-100 pt-x4'}>
          <Loading />
        </div>
      )}
    </div>
  )
}

export {License}
