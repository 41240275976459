import {constants} from '../../../redux/constants'
import {IProfileState} from './IProfileState'

export const profileActions = {
  setState: (state: IProfileState) => ({
    type: constants.SET_PROFILE_STATE,
    state,
  }),

  getCertificate: (certificateId: string) => ({
    type: constants.GET_API_CERTIFICATE,
    propId: 'certificate',
    uri: '/certificate/' + certificateId,
  }),

  getWalletHistories: () => ({
    type: constants.GET_WALLET_HISTORIES,
    propId: 'walletHistories',
    uri: '/wallet/history',
  }),

  changePassword: (
    passwordOld: string,
    passwordNew: string,
    onSuccess: () => void,
    onError: (error: {errorMessage: string}) => void,
  ) => ({
    type: constants.POST_CHANGE_PASSWORD,
    uri: '/user/password',
    data: {
      passwordOld,
      passwordNew,
    },
    onSuccess,
    onError,
  }),
}
