import React from 'react'
import {Input} from 'ac_ui'
import classNames from 'classnames'

interface ISearchPanelProps {
  value: string
  handleChangeString: (value: string) => void
  searchId: string
  className?: string
  classNameInput?: string
}

const SearchPanelCommon: React.FC<ISearchPanelProps> = ({
  value,
  handleChangeString,
  searchId,
  className,
  classNameInput,
}) => {
  const handleSubmit = async (evt): Promise<void> => {
    evt.preventDefault()
  }

  const cssInput = classNames('themes-filter-level-search', classNameInput)

  return (
    <form className={'themes-search-panel ' + (className ? className : '')} onSubmit={handleSubmit}>
      <div className={cssInput}>
        <Input
          id={'search-input'}
          name={'search-input'}
          type={'text'}
          value={value}
          className={'mb-0 w-100'}
          onChange={handleChangeString}
          search={{
            id: searchId,
            buttonType: 'submit',
            onClickSearch: undefined,
            title: 'Найти…',
          }}
          placeholder={'Найти…'}
        />
      </div>
    </form>
  )
}

export {SearchPanelCommon}
