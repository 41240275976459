import {constants} from '../../../redux/constants'

export const articleActions = {
  getSections: (onSuccess) => ({
    type: constants.GET_ARTICLE_SECTIONS,
    propId: 'articleSections',
    uri: '/article/section',
    onSuccess,
  }),

  getArticle: (articleId, onSuccess) => ({
    type: constants.GET_ARTICLE,
    propId: 'article',
    uri: '/article/' + articleId,
    onSuccess,
  }),

  findArticleByName: (name) => ({
    type: constants.GET_ARTICLE_BY_NAME,
    propId: 'articleSections',
    uri: '/article/find-by-name/' + name,
  }),
}
