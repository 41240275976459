import React from 'react'
import {ArticleDto, ArticleTaskDto} from 'learndb-share'
import {ButtonTask} from '../../../../common/buttonTask/ButtonTask'

interface IArticleTaskListProps {
  id: string
  article: ArticleDto
  isMobile?: boolean
  className?: string
}

const ArticleTaskList: React.FC<IArticleTaskListProps> = ({article, isMobile, id, className}) => {
  const tasks: ArticleTaskDto[] = article && article.tasks && article.tasks.length > 0 ? article.tasks : []
  const items: React.ReactElement[] = tasks.map((task, index) => (
    <ButtonTask key={task.taskId} task={task} className={index !== tasks.length - 1 ? 'mb-x2' : 'mb-0'} id={id} />
  ))

  if (items.length > 0) {
    return (
      <ul id={id} className={'article-task-list ' + (isMobile ? 'is-mobile ' : ' ') + (className ? className : '')}>
        {items}
      </ul>
    )
  } else {
    return <></>
  }
}

export {ArticleTaskList}
