import React from 'react'
import {UserInfo} from '../auth/UserInfo'
import {URLS} from '../../constants/urls'
import {CoinSvg, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, DropdownToggleType, Icon} from 'ac_ui'
import {
  acAccountPlusCenter,
  acCrystal,
  acDotsHorizontal,
  acHelpCircleOutline,
  acLogin,
  acMail,
  acOpenBook,
  acRubCircleOutline,
  acTelegramCircle,
} from 'ac-icon'
import {getIconColor} from './utils'
import {IReduxState} from '../../redux/types/IReduxState'
import {useDispatch, useSelector} from 'react-redux'
import classNames from 'classnames'
import {UserDto} from 'learndb-share'
import {CoinsCounter} from './CoinsCounter'
import {feedbackActions} from '../feedback/redux/actions'
import {ymTarget} from '../../metrics/ym/ymTarget'
import {YmTargetEnum} from '../../metrics/ym/YmTargetEnum'

interface IMainMenuProps {
  isTaskHeader: boolean
}

const MainMenu: React.FC<IMainMenuProps> = ({isTaskHeader}) => {
  const feedbackDispatch = useDispatch()
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  const isAuth = !!user

  const items = [
    {id: 'courses', name: 'Курсы', url: URLS.themes, iconName: acCrystal},
    {id: 'articles', name: 'Учебник', url: URLS.articles, iconName: acOpenBook},
    // {id: 'marathon', name: 'Марафон', url: URLS.marathon, iconName: acArrowAlt},
  ]

  const iconColor = getIconColor(isTaskHeader)

  const menuItems = items.map((item) => (
    <li
      key={item.id}
      className={classNames('main-menu-item', {
        'is-task-header': isTaskHeader,
      })}>
      <a href={item.url} aria-label={item.name} className={'d-flex flex-row align-items-center'}>
        <div className={'icon-wrapper d-flex'} title={item.name}>
          <Icon path={item.iconName} color={iconColor} size={'24px'} title={undefined} />
        </div>
        <span>{item.name}</span>
      </a>
    </li>
  ))

  const renderCoin = (): React.ReactElement => {
    return (
      <li
        className={classNames('main-menu-item header-coins', {
          'is-task-header': isTaskHeader,
          'un-auth': !user,
        })}>
        <a href={isAuth ? URLS.profile + URLS.coins : URLS.coins} className={'d-flex flex-row align-items-center'}>
          <div className={'d-flex'} title={'Монеты'}>
            <CoinSvg id={'header-coin'} />
          </div>
          <span>
            <CoinsCounter />
          </span>
        </a>
      </li>
    )
  }

  const renderSupport = (): React.ReactElement => {
    if (isTaskHeader) {
      return (
        <Dropdown id={'support-dd'} direction={'bottom-end'}>
          <DropdownToggle
            type={DropdownToggleType.buttonIcon}
            buttonIcon={{
              title: 'Поддержка',
              iconPath: acHelpCircleOutline,
              color: 'white',
              className: 'mr-x2',
            }}
          />
          <DropdownMenu className={'support-dd header-dropdown'}>
            <DropdownItem
              text={'Чат в телеграмме'}
              link={URLS.socialTelegramChat}
              onClickLink={() => {
                ymTarget(YmTargetEnum.TELEGRAM_CHAT)
              }}
              icon={<Icon path={acTelegramCircle} title={'Телеграмм'} />}
              iconPosition={'left'}
            />
            <DropdownItem
              text={'Письмо в поддержку'}
              onClick={() => {
                feedbackDispatch(
                  feedbackActions.setState({
                    isOpenModal: true,
                  }),
                )
              }}
              icon={<Icon path={acMail} title={undefined} />}
              iconPosition={'left'}
            />
          </DropdownMenu>
        </Dropdown>
      )
    } else {
      return <></>
    }
  }

  return (
    <div className={'d-flex align-items-center'}>
      <nav className={'main-menu'}>
        <ul>{menuItems}</ul>
        <div className={'d-flex align-items-center'}>
          {renderSupport()}
          {renderCoin()}
          <UserInfo isTaskHeader={isTaskHeader} />
        </div>
      </nav>
      {!(user && !user.isAnonymous) ? (
        <Dropdown id={'main-menu'} direction={'bottom-end'}>
          <DropdownToggle
            type={DropdownToggleType.buttonIcon}
            buttonIcon={{
              title: 'Меню',
              iconPath: acDotsHorizontal,
              color: isTaskHeader ? 'white' : undefined,
              className: 'main-menu-dropdown-hidden',
            }}
          />
          <DropdownMenu className={'main-menu-dropdown-hidden header-dropdown'}>
            <DropdownItem
              text={'Курсы'}
              link={URLS.themes}
              icon={<Icon path={acCrystal} title={undefined} />}
              iconPosition={'left'}
            />
            <DropdownItem
              text={'Учебник'}
              link={URLS.articles}
              icon={<Icon path={acOpenBook} title={undefined} />}
              iconPosition={'left'}
            />
            <DropdownItem
              text={'Цены'}
              link={URLS.payment}
              icon={<Icon path={acRubCircleOutline} title={undefined} />}
              iconPosition={'left'}
            />
            <DropdownItem
              text={<CoinsCounter />}
              link={isAuth ? URLS.profile + URLS.coins : URLS.coins}
              borderBottom
              icon={<CoinSvg id={'dropdownItemCoins'} className={'mr-x2'} />}
              iconPosition={'left'}
            />
            <DropdownItem
              text={'Письмо в поддержку'}
              onClick={() => {
                feedbackDispatch(
                  feedbackActions.setState({
                    isOpenModal: true,
                  }),
                )
              }}
              borderBottom
              icon={<Icon path={acMail} title={undefined} />}
              iconPosition={'left'}
            />

            <DropdownItem
              text={'Регистрация'}
              link={URLS.signup}
              icon={<Icon path={acAccountPlusCenter} title={undefined} />}
              iconPosition={'left'}
            />
            <DropdownItem
              text={'Войти'}
              link={URLS.signin}
              icon={<Icon path={acLogin} title={undefined} />}
              iconPosition={'left'}
            />
          </DropdownMenu>
        </Dropdown>
      ) : (
        <></>
      )}
    </div>
  )
}

export {MainMenu}
