import {constants} from '../../../redux/constants'
import {getErrorActionType, getSuccessActionType} from '../../../redux/utils'
import {ResponseError, SubscriptionHistoryDto, SubscriptionPriceMapDto, YandexPaymentDto} from 'learndb-share'

export interface IPaymentState {
  payment?: YandexPaymentDto
  paymentInfo?: YandexPaymentDto
  paymentError?: ResponseError
  subscriptionPriceMap?: SubscriptionPriceMapDto
  subscriptionHistories?: SubscriptionHistoryDto[] | undefined
}

const INITIAL_STATE: IPaymentState = {
  payment: null,
  paymentInfo: null,
  subscriptionPriceMap: null,
  subscriptionHistories: undefined,
}

function paymentReducers(state: IPaymentState = INITIAL_STATE, action): IPaymentState {
  switch (action.type) {
    case constants.SET_PAYMENT_STATE: {
      return {...state, ...action.state}
    }

    case getSuccessActionType(constants.POST_PAYMENT): {
      return {...state, payment: action.data, paymentError: undefined}
    }

    case getErrorActionType(constants.POST_PAYMENT): {
      return {...state, payment: undefined, paymentError: action.error}
    }

    case getSuccessActionType(constants.GET_PAYMENT_REFRESH): {
      return {...state, paymentInfo: action.data}
    }

    case getSuccessActionType(constants.GET_SUBSCRIPTION_PRICE_MAP): {
      return {...state, subscriptionPriceMap: action.data}
    }

    case getSuccessActionType(constants.GET_SUBSCRIPTION_HISTORIES): {
      return {...state, subscriptionHistories: action.data}
    }

    default:
      return state
  }
}

export {paymentReducers}
