import React from 'react'
import {Col, COLORS, Container, Icon, Row} from 'ac_ui'
import {acDownload, acLinkVariant, acTreeView, acWindow} from 'ac-icon'

const Certificate: React.FC = () => {
  return (
    <section className={'certificate-landing'}>
      <Container className={'certificate-landing-container'}>
        <h2 className={'landing-h2 certificate-landing-h2'}>Сертификат</h2>
        <Row>
          <Col xs={12} sm={12} md={6} lg={7} xl={7} className={'certificate-landing-list'}>
            <div className={'certificate-landing-card'}>
              <div className={'d-flex mr-x4'}>
                <Icon path={acWindow} title={undefined} color={COLORS.blue800} size={'32px'} />
              </div>
              <p className={'certificate-landing-p'}>Доступен онлайн с&nbsp;любого устройства</p>
            </div>
            <div className={'certificate-landing-card'}>
              <div className={'d-flex mr-x4'}>
                <Icon path={acTreeView} title={undefined} color={COLORS.blue800} size={'32px'} />
              </div>
              <p className={'certificate-landing-p'}>Темы добавляются в&nbsp;сертификат по&nbsp;мере прохождения</p>
            </div>
            <div className={'certificate-landing-card'}>
              <div className={'d-flex mr-x4'}>
                <Icon path={acLinkVariant} title={undefined} color={COLORS.blue800} size={'32px'} />
              </div>
              <p className={'certificate-landing-p'}>Делитесь ссылкой на&nbsp;сертификат</p>
            </div>
            <div className={'certificate-landing-card'}>
              <div className={'d-flex mr-x4'}>
                <Icon path={acDownload} title={undefined} color={COLORS.blue800} size={'32px'} />
              </div>
              <p className={'certificate-landing-p'}>Скачивайте в&nbsp;PDF</p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={5} className={'certificate-landing-img'}>
            <img
              src={'/img/landing/certificate-phone-example.svg'}
              className={'phone'}
              alt={'Телефоне с сертификатом'}
            />
            <img src={'/img/landing/certificate-example.png'} className={'img'} alt={'Страница с сертификатом'} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export {Certificate}
