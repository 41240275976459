import React, {useState} from 'react'
import {Button, Card, CardBody, Checkbox, createSuccessToast, DatePicker, Input, Select} from 'ac_ui'
import {useDispatch} from 'react-redux'
import {UserDto, UserInfoDto} from 'learndb-share'
import {authActions} from '../../auth/redux/actions'

interface IUserInfoFormProps {
  user: UserDto
}

const UserInfoForm: React.FC<IUserInfoFormProps> = ({user}) => {
  const authDispatch = useDispatch()
  const [newInfo, setNewInfo] = useState<UserInfoDto | undefined>({
    ...user.info,
    sex: user.info?.sex,
  })

  const [firstName, setFirstName] = useState<string>(user.firstName)
  const [lastName, setLastName] = useState<string>(user.lastName)
  const [isReceiveEmail, setIsReceiveEmail] = useState<boolean>(user.isReceiveEmail)

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)

  const handleChange = (evt): void => {
    newInfo[evt.name] = evt.value
    setNewInfo({
      ...newInfo,
    })
  }

  const enableSubmit = (): void => {
    setIsSubmitDisabled(false)
  }

  const handleSubmit = (evt): void => {
    evt.preventDefault()
    setIsSubmitDisabled(true)
    authDispatch(
      authActions.saveUserInfo(
        {
          ...user,
          firstName: firstName,
          lastName: lastName,
          isReceiveEmail: isReceiveEmail,
          info: {...newInfo},
        },
        () => {
          createSuccessToast('Сохранено')
          enableSubmit()
        },
        enableSubmit,
      ),
    )
  }

  if (newInfo) {
    const {sex, birthDate, countryName, cityName} = newInfo
    return (
      <form className={'mb-x6'} onSubmit={handleSubmit}>
        <Card className={'profile-edit-card'}>
          <CardBody className={'profile-edit-card-body'}>
            <h2 className={'header'}>Личные данные</h2>
            <Input
              id={'userInfoFirstName'}
              name={'firstName'}
              type={'text'}
              label={'Имя'}
              required
              value={firstName}
              onChange={(value) => {
                setFirstName(value)
              }}
              className={'mb-x6'}
            />
            <Input
              id={'userInfoLastName'}
              name={'lastName'}
              type={'text'}
              label={'Фамилия'}
              required
              value={lastName}
              onChange={(value) => {
                setLastName(value)
              }}
              className={'mb-x6'}
            />
            <Select
              id={'userInfoSex'}
              name={'sex'}
              label={'Пол'}
              value={sex || '-'}
              options={[
                {value: '-', label: 'Не указан'},
                {value: 'men', label: 'Мужской'},
                {value: 'women', label: 'Женский'},
              ]}
              onChange={(value) => {
                handleChange({name: 'sex', value: value === '-' ? null : value})
              }}
              className={'mb-x6'}
            />
            <DatePicker
              id={'userInfoBirthDate'}
              name={'birthDate'}
              label={'Дата рождения'}
              value={birthDate}
              withIcon
              onChange={(value) => {
                handleChange({name: 'birthDate', value: value})
              }}
              className={'mb-x6'}
            />
            <Input
              id={'userInfoCountryName'}
              name={'countryName'}
              type={'text'}
              label={'Страна'}
              value={countryName}
              onChange={(value) => {
                handleChange({name: 'countryName', value: value})
              }}
              className={'mb-x6'}
            />
            <Input
              id={'userInfoCityName'}
              name={'cityName'}
              type={'text'}
              label={'Город'}
              value={cityName}
              onChange={(value) => {
                handleChange({name: 'cityName', value: value})
              }}
              className={'mb-x6'}
            />
            <Checkbox
              id={'receive-email'}
              color={'primary'}
              name={'receive-email'}
              type={'checkbox'}
              checked={!!isReceiveEmail}
              onChange={(checked) => {
                setIsReceiveEmail(checked)
              }}
              label={'Хочу получать новости на почту'}
              helpText={'Обновление заданий, акции, марафоны'}
              className={'mb-x8'}
            />
            <Button type={'submit'} disabled={isSubmitDisabled} className={'w-100'}>
              Сохранить
            </Button>
          </CardBody>
        </Card>
      </form>
    )
  } else {
    return <></>
  }
}

export {UserInfoForm}
