import React from 'react'
import {COLORS, Container, Icon, MultiLink} from 'ac_ui'
import {acDigitFive, acDigitFour, acDigitOne, acDigitThree, acDigitTwo} from 'ac-icon'
import {LandingDto} from 'learndb-share'
import {ymTarget} from '../../../metrics/ym/ymTarget'
import {YmTargetEnum} from '../../../metrics/ym/YmTargetEnum'

interface IHowTrainingTakesPlaceProps {
  landing: LandingDto
}

const HowTrainingTakesPlace: React.FC<IHowTrainingTakesPlaceProps> = ({landing}) => {
  let tLink: string
  let vkLink: string
  if (landing) {
    tLink = landing.socialLinks.telegramChat
    vkLink = landing.socialLinks.vk
  }
  const list: {
    digit: React.ReactElement
    src: string
    name: string
    alt: string
    description: React.ReactElement
  }[] = [
    {
      digit: <Icon title={undefined} path={acDigitOne} color={COLORS.blue800} />,
      src: 'robotThink.svg',
      name: 'Изучаете теорию',
      alt: 'Робот',
      description: <>Авторская теория, написанная простым языком.</>,
    },
    {
      digit: <Icon title={undefined} path={acDigitTwo} color={COLORS.blue800} />,
      src: 'pcSql.svg',
      name: 'Выполняете практические задания в онлайн тренажере',
      alt: 'Монитор с клавиатурой',
      description: (
        <>
          Редактор кода приближен к&nbsp;реальным инструментам. Все в одном окне: теория, диаграмма схемы данных,
          задание, редактор кода.
        </>
      ),
    },
    {
      digit: <Icon title={undefined} path={acDigitThree} color={COLORS.blue800} />,
      src: 'achievement.svg',
      name: 'Получаете достижения и монеты за прогресс',
      alt: 'Кубок',
      description: (
        <>Вы&nbsp;будете получать монеты за&nbsp;достижения. Монеты можно потратить на&nbsp;оплату подписки.</>
      ),
    },
    {
      digit: <Icon title={undefined} path={acDigitFour} color={COLORS.blue800} />,
      src: 'coolRobot.svg',
      name: 'Если возникают вопросы – помогаем в чате',
      alt: 'Крутой робот',
      description: (
        <>
          Ответим на&nbsp;вопросы в&nbsp;общем чате в&nbsp;
          {tLink ? (
            <MultiLink
              href={tLink}
              onClick={() => {
                ymTarget(YmTargetEnum.TELEGRAM_CHAT)
              }}
              type={'href'}>
              телеграмме
            </MultiLink>
          ) : (
            <>телеграмме</>
          )}{' '}
          или&nbsp;
          {vkLink ? (
            <MultiLink
              href={vkLink}
              onClick={() => {
                ymTarget(YmTargetEnum.VK_CHANNEL)
              }}
              type={'href'}>
              вконтакте
            </MultiLink>
          ) : (
            <>вконтакте</>
          )}
          .
        </>
      ),
    },
    {
      digit: <Icon title={undefined} path={acDigitFive} color={COLORS.blue800} />,
      src: 'certificate.svg',
      name: 'Получаете сертификат',
      alt: 'Сертификат',
      description: (
        <>Доступен с&nbsp;любого устройства, так&nbsp;же&nbsp;можно поделиться по&nbsp;ссылке или&nbsp;скачать.</>
      ),
    },
  ]

  const items: React.ReactElement[] = []

  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    items.push(
      <li key={item.src} className={'how-training-card'}>
        <div className={'how-training-number-wrapper'}>
          <div className={'how-training-number'}>{item.digit}</div>
          <img src={'/img/landing/' + item.src} className={'how-training-image'} alt={item.alt} />
        </div>
        <div className={'d-flex flex-column w-100'}>
          <p className={'how-training-name'}>{item.name}</p>
          <p className={'how-training-description'}>{item.description}</p>
        </div>
      </li>,
    )
  }
  return (
    <section className={'landing-how-training'}>
      <Container className={'landing-how-training-container'}>
        <h2 className={'landing-h2 how-training-h2'}>Как проходит обучение</h2>
        <ol className={'how-training-list'}>{items}</ol>
      </Container>
    </section>
  )
}

export {HowTrainingTakesPlace}
