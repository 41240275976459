import React from 'react'
import {URLS} from '../../../../../constants/urls'
import {BreadcrumbDTO, Breadcrumbs} from 'ac_ui'
import {ArticleDto} from 'learndb-share'

interface IArticleHeaderBlockProps {
  article: ArticleDto
  headerBlockRef: any
}

const ArticleHeaderBlock: React.FC<IArticleHeaderBlockProps> = ({article, headerBlockRef}) => {
  const renderBreadcrumbs = (): React.ReactNode => {
    const links: BreadcrumbDTO[] = []
    links.push({
      breadcrumbId: 'tableOfContents',
      name: 'Оглавление',
      link: URLS.articles,
    })
    if (article) {
      links.push({
        breadcrumbId: 'section',
        name: article.sectionName,
        link: `${URLS.articles}?sectionId=${article.sectionId}`,
      })
    }
    if (links?.length > 0) {
      return <Breadcrumbs links={links} className={'mb-x4'} />
    } else {
      return <></>
    }
  }

  const renderName = (): React.ReactNode => {
    return <h1 className={'article-view-name mb-0'}>{article.name}</h1>
  }

  return (
    <div ref={headerBlockRef} className={'d-flex flex-column mb-x4'}>
      {renderBreadcrumbs()}
      {renderName()}
    </div>
  )
}

export {ArticleHeaderBlock}
