import {isUseCounters} from '../utils'
import {SEO_COUNTERS} from '../constants'

function hitYandex(url: string): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ym(SEO_COUNTERS.YANDEX, 'hit', url)
}

function hitMailRu(url: string): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  _tmr.pageView({id: SEO_COUNTERS.MAIL_RU, url})
}

function metricsHitPage(url: string): void {
  try {
    if (isUseCounters()) {
      hitYandex(url)
      hitMailRu(url)
      // Google Analytics отслеживает изменения в строке адреса автоматически
    }
  } catch (e: any) {
    console.error('Error while hit page for metrics', e)
  }
}

export {metricsHitPage}
