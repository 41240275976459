import React, {ReactElement, ReactNode} from 'react'
import {Controller} from 'react-hook-form'
import {ControllerCommonProps} from './ControllerInput'
import {getErrorsControllerInput} from '../utils/getErrorsControllerInput'
import {TextArea} from 'ac_ui'

export interface IControllerTextAreaProps {
  name: string
  placeholder?: string
  label?: string
  maxRows?: number
  required?: boolean
  disabled?: boolean
  common: ControllerCommonProps<any> | any
  className?: string
  classNameTextarea?: string
}

const ControllerTextArea: React.FC<IControllerTextAreaProps> = ({
  name,
  placeholder,
  label,
  maxRows,
  required,
  disabled,
  common,
  className,
  classNameTextarea,
}): React.ReactElement => {
  const {control, setValue} = common
  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field, formState}) => {
        const onChangeValue = (newValue: string): void => {
          const newDescription = newValue
          field.onChange(newDescription)
          setValue(field.name, newDescription)
        }

        const errorItems: ReactNode[] = getErrorsControllerInput<any>(formState, field.name, true)

        const feedBack: ReactElement | undefined = errorItems.length ? (
          <div className={'d-flex flex-column'}>{errorItems}</div>
        ) : undefined

        return (
          <TextArea
            id={field.name}
            name={field.name}
            value={field.value}
            label={label}
            maxRows={maxRows || 5}
            classNameTextarea={classNameTextarea}
            valid={feedBack ? false : undefined}
            feedback={feedBack}
            isShowRequired={required}
            placeholder={placeholder}
            className={className ? className : ''}
            onChange={onChangeValue}
            disabled={disabled}
          />
        )
      }}
    />
  )
}

export {ControllerTextArea}
