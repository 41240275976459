import React from 'react'
import {SubscriptionPeriod, UserDto} from 'learndb-share'
import {PaymentTypes} from '../../payment/PaymentTypes'
import {QuestionsPaymentAndSubscription} from '../../payment/QuestionsPaymentAndSubscription'
import {Container} from 'ac_ui'
import {SubscriptionCard} from './components/SubscriptionCard'
import {useLoadPriceMap} from '../../payment/hooks/useLoadPriceMap'

interface ISubscriptionViewProps {
  user: UserDto
}

const SubscriptionView: React.FC<ISubscriptionViewProps> = ({user}): React.ReactElement => {
  const priceMap = useLoadPriceMap()

  let priceMonth
  if (priceMap && !priceMonth) {
    priceMonth = priceMap[SubscriptionPeriod.MONTH]
  }

  const {dateEndSubscription, isProAccount} = user

  return (
    <div className={'subscription-view'}>
      <section className={'profile-view-header'}>
        <Container>
          <h1 className={'profile-view-header-h1'}>Подписка</h1>
        </Container>
      </section>
      <Container>
        <SubscriptionCard
          isAuth={!!user}
          className={'mb-x8'}
          dateEndSubscription={dateEndSubscription}
          priceMonth={priceMonth}
          isProAccount={isProAccount}
        />
      </Container>
      <PaymentTypes />
      <QuestionsPaymentAndSubscription />
    </div>
  )
}

export {SubscriptionView}
