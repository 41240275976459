import React from 'react'
import {DiamondBlueSvg, DiamondPurpleSvg, DiamondYellowSvg} from 'ac_ui'

const getDiamondByLevel = (levelTask: string, id: string): React.ReactNode => {
  switch (levelTask) {
    case 'BASE': {
      return <DiamondBlueSvg id={id} />
    }
    case 'MEDIUM': {
      return <DiamondYellowSvg id={id} />
    }
    case 'ADVANCED': {
      return <DiamondPurpleSvg id={id} />
    }
  }
}

export {getDiamondByLevel}
