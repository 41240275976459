import React, {ReactNode} from 'react'
import {URLS} from '../../constants/urls'
import {
  COLORS,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  DropdownToggleType,
  CoinSvg,
  LearnDBPlusSvg,
} from 'ac_ui'
import {
  acLogin,
  acAccountCircle,
  acCrystal,
  acSettings,
  acLogout,
  acDotsHorizontal,
  acRubCircleOutline,
  acOpenBook,
  acAchievement,
  acCertificate,
  acMail,
} from 'ac-icon'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {authActions} from './redux/actions'
import classNames from 'classnames'
import {getSecureName} from 'ac-share'
import {UserDto} from 'learndb-share'
import {CONFIG} from '../../constants'
import {feedbackActions} from '../feedback/redux/actions'

interface IUserInfoProps {
  isTaskHeader: boolean
}

const UserInfo: React.FC<IUserInfoProps> = ({isTaskHeader}) => {
  const dispatch = useDispatch()
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)
  const userAnonymous = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.userAnonymous)

  let coins = 0
  if (user) {
    coins = user.wallet?.coins
  } else if (userAnonymous) {
    coins = userAnonymous.wallet?.coins
  }

  const handleLogout = (): void => {
    dispatch(
      authActions.logout(() => {
        window.location.replace('/')
      }),
    )
  }

  const renderDropdownMenu = (): ReactNode => {
    const isAuth = !!user
    return (
      <DropdownMenu className={'header-dropdown'}>
        <DropdownItem
          text={'Курсы'}
          link={URLS.themes}
          icon={<Icon path={acCrystal} title={undefined} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Учебник'}
          link={URLS.articles}
          icon={<Icon path={acOpenBook} title={undefined} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Профиль'}
          link={URLS.profile + URLS.profileI}
          icon={<Icon path={acAccountCircle} title={undefined} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={(coins || 0).toString()}
          link={isAuth ? URLS.profile + URLS.coins : URLS.coins}
          icon={<CoinSvg id={'dropdownItemCoins'} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Настройки'}
          link={URLS.profile + URLS.profilePreferences}
          icon={<Icon path={acSettings} title={undefined} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Подписка'}
          link={URLS.profile + URLS.profileSubscription}
          icon={<LearnDBPlusSvg id={'auth-plus'} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Сертификат'}
          link={URLS.profile + URLS.profileCertificate}
          icon={<Icon path={acCertificate} title={undefined} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Достижения'}
          link={URLS.profile + URLS.profileAchievement}
          borderBottom
          icon={<Icon path={acAchievement} title={undefined} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Письмо в поддержку'}
          onClick={() => {
            dispatch(
              feedbackActions.setState({
                isOpenModal: true,
              }),
            )
          }}
          borderBottom
          icon={<Icon path={acMail} title={undefined} />}
          iconPosition={'left'}
        />
        <DropdownItem
          text={'Выход'}
          onClick={() => {
            handleLogout()
          }}
          icon={<Icon path={acLogout} title={undefined} />}
          iconPosition={'left'}
        />
      </DropdownMenu>
    )
  }

  if (user && !user.isAnonymous) {
    return (
      <div className={'menu-user-info-container'}>
        <div className={'d-flex align-items-center'}>
          <Dropdown id={'profile-menu-avatar'} direction={'bottom-end'}>
            <DropdownToggle
              type={DropdownToggleType.buttonAvatar}
              buttonAvatar={{
                children: getSecureName(user),
                isTaskHeader: isTaskHeader,
                imageUrl: user?.avatarFileName ? CONFIG.cdnWeb + '/' + user?.avatarFileName : undefined,
                className: 'profile-menu-avatar',
              }}
            />
            {renderDropdownMenu()}
          </Dropdown>
          <Dropdown id={'profile-menu'} direction={'bottom-end'}>
            <DropdownToggle
              type={DropdownToggleType.buttonIcon}
              buttonIcon={{
                title: 'Меню',
                className: 'menu-user-info-dropdown-toggle-mobile d-sm-none ml-0',
                iconPath: acDotsHorizontal,
                color: isTaskHeader ? 'white' : undefined,
              }}
            />
            {renderDropdownMenu()}
          </Dropdown>
        </div>
      </div>
    )
  } else {
    const isAuth = !!user
    return (
      <div className={'main-menu login-button'}>
        <ul>
          <li
            className={classNames('main-menu-item', {
              'is-task-header': isTaskHeader,
              'un-auth': !isAuth,
            })}>
            <a
              href={isAuth ? URLS.profile + URLS.profileSubscription : URLS.payment}
              className={'d-flex flex-row align-items-center'}>
              <div className={'icon-wrapper d-flex'}>
                <Icon
                  path={acRubCircleOutline}
                  size={'24px'}
                  color={isTaskHeader ? COLORS.white : undefined}
                  title={undefined}
                />
              </div>
              <span>Цены</span>
            </a>
          </li>
          <li
            className={classNames('main-menu-item', {
              'is-task-header': isTaskHeader,
            })}>
            <a href={URLS.signin} className={'d-flex flex-row align-items-center'}>
              <div className={'icon-wrapper d-flex'}>
                <Icon path={acLogin} size={'24px'} color={isTaskHeader ? COLORS.white : undefined} title={undefined} />
              </div>
              <span>Войти</span>
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

export {UserInfo}
