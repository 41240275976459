import React from 'react'
import {MenuItem} from './MenuItem'
import {URLS} from '../../../constants/urls'
import {CoinSvg, COLORS, Container, Icon, LearnDBPlusSvg} from 'ac_ui'
import {acAccountCircle, acAchievement, acCertificate, acSettings} from 'ac-icon'

interface IMenuProps {
  currentUrl: string
}

const Menu: React.FC<IMenuProps> = ({currentUrl}) => {
  return (
    <div className={'profile-menu'}>
      <Container className={'profile-menu-content'}>
        <ul>
          <MenuItem
            id={'profile'}
            url={URLS.profile + URLS.profileI}
            isActive={currentUrl === URLS.profile + URLS.profileI}>
            <div className={'icon-wrapper'}>
              <Icon path={acAccountCircle} color={COLORS.white} title={undefined} />
            </div>
            Профиль
          </MenuItem>
          <MenuItem
            id={'achievement'}
            url={URLS.profile + URLS.profileAchievement}
            isActive={currentUrl === URLS.profile + URLS.profileAchievement}>
            <div className={'icon-wrapper'}>
              <Icon path={acAchievement} color={COLORS.white} title={undefined} />
            </div>
            Достижения
          </MenuItem>
          <MenuItem
            id={'certificate'}
            url={URLS.profile + URLS.profileCertificate}
            isActive={currentUrl === URLS.profile + URLS.profileCertificate}>
            <div className={'icon-wrapper'}>
              <Icon path={acCertificate} color={COLORS.white} title={undefined} />
            </div>
            Сертификат
          </MenuItem>
          <MenuItem id={'coins'} url={URLS.profile + URLS.coins} isActive={currentUrl === URLS.profile + URLS.coins}>
            <div className={'icon-wrapper'}>
              <CoinSvg id={'profile-nav-coin'} />
            </div>
            Монеты
          </MenuItem>
          <MenuItem
            id={'subscription'}
            url={URLS.profile + URLS.profileSubscription}
            isActive={currentUrl === URLS.profile + URLS.profileSubscription}>
            <div className={'icon-wrapper'}>
              <LearnDBPlusSvg id={'profile-nav-plus'} />
            </div>
            Подписка
          </MenuItem>
          <MenuItem
            id={'preferences'}
            url={URLS.profile + URLS.profilePreferences}
            isActive={currentUrl === URLS.profile + URLS.profilePreferences}>
            <div className={'icon-wrapper'}>
              <Icon path={acSettings} color={COLORS.white} title={undefined} />
            </div>
            Настройки
          </MenuItem>
        </ul>
      </Container>
    </div>
  )
}

export {Menu}
