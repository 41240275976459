import {useState} from 'react'
import {RestConfig} from '../api/RestConfig'
import {apiGet, apiPost, apiUploadToCdn} from '../api'

export enum UseRequestTypeEnum {
  API_GET = 'API_GET',
  API_POST = 'API_POST',
  API_UPLOAD_TO_CDN = 'API_UPLOAD_TO_CDN',
}

export interface UseRequest {
  url?: string
  data?: any
  config?: RestConfig
}

export interface IUseRequest extends UseRequest {
  type?: UseRequestTypeEnum
  onDispatch?: (res) => void
}

function useRequest<T>(
  props: IUseRequest,
): [request: (args?: UseRequest) => Promise<T>, isSending: boolean, resData: T] {
  const [resData, setResData] = useState<T | undefined>(undefined)
  const [isSending, setIsSending] = useState<boolean>(false)

  const request = async (args?: UseRequest): Promise<T> => {
    const {url = props.url, config = props.config, data = props.data} = args || {}
    setIsSending(true)
    let res: T
    try {
      switch (props.type) {
        case UseRequestTypeEnum.API_GET:
          {
            res = await apiGet(url, config)
          }
          break
        case UseRequestTypeEnum.API_POST:
          {
            res = await apiPost(url, data, config)
          }
          break
        case UseRequestTypeEnum.API_UPLOAD_TO_CDN:
          {
            res = await apiUploadToCdn(data, config)
          }
          break
      }
      if (props.onDispatch) {
        props.onDispatch(res)
      }
      setResData(res)
    } catch (e) {
      console.error('useRequest error: ', e)
      throw e
    } finally {
      setIsSending(false)
    }
    return res
  }

  return [request, isSending, resData]
}

export {useRequest}
