import React, {useState} from 'react'
import * as yup from 'yup'
import {Button, Checkbox, Col, FormFeedback, Row} from 'ac_ui'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {ResponseError, SubscriptionPeriod, SubscriptionPriceMapDto} from 'learndb-share'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {regExp} from 'ac-share'
import {FormController} from '../../common/formController/FormController'
import {ControllerCommonProps} from '../../common/formController/components/ControllerInput'
import {usePay} from '../hooks/usePay'

interface IPaymentFormValues {
  email: string
}

const PaymentOtherUserForm: React.FC = () => {
  const {pay} = usePay()
  const [isSubmitRequest, setIsSubmitRequest] = useState<boolean>(false)
  const [selectedPeriod, setSelectedPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.MONTH)
  const subscriptionPriceMap = useSelector<IReduxState, SubscriptionPriceMapDto | undefined>(
    (state) => state.payment?.subscriptionPriceMap,
  )
  const paymentError = useSelector<IReduxState, ResponseError | undefined>((state) => state.payment?.paymentError)
  const yupSchemaConfig = {
    email: yup
      .string()
      .nullable()
      .required('Необходимо заполнить email')
      .matches(regExp.checkMail, 'Неверный формат email')
      .max(320, 'Email превышает допустимую длину 320 символов'),
  }

  const methods = useForm<IPaymentFormValues>({
    resolver: yupResolver(yup.object(yupSchemaConfig), {abortEarly: false}),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      email: '',
    },
  })

  const common: ControllerCommonProps<IPaymentFormValues> = {
    control: methods.control,
    setValue: methods.setValue,
  }

  const getPrice = (period: SubscriptionPeriod): number => {
    return subscriptionPriceMap[period]?.priceDiscount
  }

  const handleSubmit = async (): Promise<void> => {
    const {watch} = methods
    const {email} = watch()
    try {
      setIsSubmitRequest(true)
      await pay(getPrice(selectedPeriod), selectedPeriod, null, email)
    } finally {
      setIsSubmitRequest(false)
    }
  }

  if (!subscriptionPriceMap) {
    return null
  }

  return (
    <Row>
      <Col xs={12} md={6}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <FormController.Input name={'email'} common={common} label={'Почта'} required className={'mb-x6'} />
            <FormFeedback
              message={paymentError?.errorMessage}
              isShow={!!paymentError}
              type={'danger'}
              className={'mb-x4'}
            />
            <Checkbox
              id={'payment-other-user-month'}
              name={'month'}
              label={`На месяц за ${getPrice(SubscriptionPeriod.MONTH)} рублей`}
              checked={selectedPeriod === SubscriptionPeriod.MONTH}
              onChange={() => {
                setSelectedPeriod(SubscriptionPeriod.MONTH)
              }}
              color={'primary'}
              className={'mb-x4'}
              type={'radio'}
            />
            <Checkbox
              id={'payment-other-user-year'}
              name={'year'}
              label={`На год за ${getPrice(SubscriptionPeriod.YEAR)} рублей`}
              checked={selectedPeriod === SubscriptionPeriod.YEAR}
              onChange={() => {
                setSelectedPeriod(SubscriptionPeriod.YEAR)
              }}
              color={'primary'}
              className={'mb-x6'}
              type={'radio'}
            />
            <Button type={'submit'} color={'lime'}>
              Оформить подписку
            </Button>
          </form>
        </FormProvider>
      </Col>
    </Row>
  )
}

export {PaymentOtherUserForm}
