import React from 'react'
import {Button, COLORS, Divider, Icon} from 'ac_ui'
import {acAccount, acCertificate, acCheck, acClock, acOpenBook} from 'ac-icon'
import {CourseDto} from 'learndb-share'
import {getCountString} from 'ac-share'

interface ICourseInfoCardProps {
  course: CourseDto
  isProAccount: boolean
  scrollToThemes: () => void
}

const CourseInfoCard: React.FC<ICourseInfoCardProps> = ({course, isProAccount, scrollToThemes}): React.ReactElement => {
  return (
    <div className={'course-info-card'}>
      <p className={'course-info-card-header'}>Курс: {course.name}</p>
      <Divider className={'mb-x3'} />
      <div className={'card-info-card-list-wrapper'}>
        <div className={'card-info-card-list mr'}>
          <div className={'card-info-card-item'}>
            <div className={'d-flex mr-x2'}>
              <Icon path={acOpenBook} color={COLORS.gray700} title={undefined} />
            </div>
            <span>
              {course.courseTaskStatistic.countTotalTheme}{' '}
              {getCountString(course.courseTaskStatistic.countTotalTheme, 'тема', 'темы', 'тем')} и{' '}
              {course.courseTaskStatistic.countTotalTask}{' '}
              {getCountString(course.courseTaskStatistic.countTotalTask, 'урок', 'урока', 'уроков')}
            </span>
          </div>
          <div className={'card-info-card-item'}>
            <div className={'d-flex mr-x2'}>
              <Icon path={acClock} color={COLORS.gray700} title={undefined} />
            </div>
            <span>
              ≈ {course.courseTaskStatistic.avgCompletionTimeOfAllTaskHour}{' '}
              {getCountString(course.courseTaskStatistic.avgCompletionTimeOfAllTaskHour, 'час', 'часа', 'часов')}
            </span>
          </div>
        </div>
        <div className={'card-info-card-list'}>
          <div className={'card-info-card-item'}>
            <div className={'d-flex mr-x2'}>
              <Icon path={acCertificate} color={COLORS.gray700} title={undefined} />
            </div>
            <span>{course.courseTaskStatistic.certificateWithLink ? 'Сертификат со ссылкой' : 'Без сертификата'}</span>
          </div>
          <div className={'card-info-card-item'}>
            <div className={'d-flex mr-x2'}>
              <Icon path={acAccount} color={COLORS.gray700} title={undefined} />
            </div>
            <span>{course.courseTaskStatistic.countUsersTriedOutCourse} опробовали</span>
          </div>
        </div>
      </div>
      {isProAccount ? (
        <Button
          onClick={scrollToThemes}
          className={'w-100'}
          size={'sm'}
          style={'ghost'}
          iconPath={acCheck}
          iconPosition={'left'}>
          Курс уже ваш
        </Button>
      ) : (
        <Button onClick={scrollToThemes} className={'w-100'} size={'sm'} style={'primary'} color={'lime'}>
          Первые 3 темы бесплатно
        </Button>
      )}
    </div>
  )
}

export {CourseInfoCard}
