import React from 'react'
import {CompletionIndicator} from './CompletionIndicator'
import {LevelEnum, ThemeDto} from 'learndb-share'
import {StatsCard} from '../../profile/components/StatsCard'

interface IThemesStatsProps {
  themes: ThemeDto[]
  isPreviousExists: boolean
  isPreviousCompleted: boolean
  isShowIndicator: boolean
}

interface IStatsItem {
  itemsTotal: number
  itemsDone: number
}

interface IStats {
  [statName: string]: IStatsItem
}

const ThemesStats: React.FC<IThemesStatsProps> = ({themes, isPreviousExists, isPreviousCompleted, isShowIndicator}) => {
  const getStats = (newTheme: ThemeDto[]): IStats => {
    const stats: IStats = {
      tasks: {itemsDone: 0, itemsTotal: 0},
      themes: {itemsDone: 0, itemsTotal: 0},
      courses: {itemsDone: 0, itemsTotal: 0},
    }

    const courses = {}

    newTheme.forEach((theme) => {
      let tasksDone = 0
      let tasksTotal = 0
      if (theme.tasks && theme.tasks.length) {
        for (const task of theme.tasks) {
          tasksTotal++
          if (task.isSolved) {
            tasksDone++
          }
        }
      }
      stats.tasks.itemsDone += tasksDone
      stats.tasks.itemsTotal += tasksTotal

      stats.themes.itemsTotal++
      if (tasksDone === tasksTotal) {
        stats.themes.itemsDone++
      }

      if (courses[theme.courseName] === undefined) {
        courses[theme.courseName] = true
      }
      if (tasksDone !== tasksTotal) {
        courses[theme.courseName] = false
      }
    })

    let coursesDone = 0
    let coursesTotal = 0
    for (const courseName in courses) {
      coursesTotal++
      if (courses[courseName]) {
        coursesDone++
      }
    }
    stats.courses.itemsDone = coursesDone
    stats.courses.itemsTotal = coursesTotal

    return stats
  }

  const stats = getStats(themes)
  return (
    <section className={'course-theme'}>
      <h2 className={'visually-hidden'}>Статистика прохождения SQL</h2>
      {isShowIndicator && (
        <CompletionIndicator
          image={'/img/themes/finishIndicator.svg'}
          isBottomLine={false}
          countSolvedTasks={stats.tasks.itemsDone}
          countTotalTasks={stats.tasks.itemsTotal}
          levelId={LevelEnum.BASE}
        />
      )}
      <div className={'course-theme-content-and-tasks'}>
        <div className={'course-theme-content course-theme-result'}>
          <StatsCard
            current={stats.themes.itemsDone}
            max={stats.themes.itemsTotal}
            type={'themes'}
            className={'course-theme-content-stats'}
          />
          <StatsCard
            current={stats.tasks.itemsDone}
            max={stats.tasks.itemsTotal}
            type={'tasks'}
            className={'course-theme-content-stats'}
          />
        </div>
      </div>
    </section>
  )
}

export {ThemesStats}
