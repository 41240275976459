import React from 'react'
import {ButtonLink, Container} from 'ac_ui'
import {URLS} from '../../../constants/urls'

const PaymentErrorPage: React.FC = () => {
  return (
    <Container>
      <section className={'payment-status-page'}>
        <img
          src={'/img/emptyList/alerts.svg'}
          width={288}
          height={288}
          className={'image'}
          alt={'Таблички: Внимание!'}
        />
        <div className={'payment-status-wrapper'}>
          <h1 className={'header'}>Что-то пошло не так, попробуйте еще раз</h1>
          <ButtonLink href={URLS.profile + URLS.profileI}>В профиль</ButtonLink>
        </div>
      </section>
    </Container>
  )
}

export {PaymentErrorPage}
