import {CertificateDto, CertificateThemeDto} from 'learndb-share'
import React from 'react'
import {Button, ButtonLink, Loading} from 'ac_ui'
import {acDownload} from 'ac-icon'
import {EmptyList} from '../../common/EmptyList'
import {useRequest, UseRequestTypeEnum} from '../../../hooks/useRequest'
import {ymTarget} from '../../../metrics/ym/ymTarget'
import {YmTargetEnum} from '../../../metrics/ym/YmTargetEnum'
import {formatDate} from 'ac-share'

interface ICertificateProps {
  certificate: CertificateDto
}

const Certificate: React.FC<ICertificateProps> = ({certificate}) => {
  const [request, isSendingRequest] = useRequest<any>({
    url: '/certificate/file/' + certificate?.certificateId,
    type: UseRequestTypeEnum.API_GET,
  })

  const onDownload = async (): Promise<void> => {
    const buffer = await request()
    const blob = new Blob([new Uint8Array(buffer.data)], {type: 'application/pdf'})
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'certificate-learnDB.pdf'
    link.click()
    ymTarget(YmTargetEnum.DOWNLOAD_CERTIFICATE)
  }

  if (!certificate) {
    return (
      <div className={'d-flex flex-column align-items-center w-100 pt-x4'}>
        <Loading />
      </div>
    )
  }
  if (!certificate.themes || certificate.themes.length === 0) {
    return (
      <EmptyList
        title={'Здесь будет сертификат'}
        type={'NOT_FOUND_CERTIFICATE'}
        description={'После изучения одной из тем'}
        className={'no-certificate-container'}
        button={
          <div className={'d-flex flex-column align-items-center mt-x6'}>
            <ButtonLink href={'/courses'}>Начать</ButtonLink>
          </div>
        }
      />
    )
  }

  const checkUrl = `/check-cert/${certificate.certificateId}`

  let firstList: CertificateThemeDto[]
  let secondList: CertificateThemeDto[] = []
  if (certificate.themes.length > 6) {
    firstList = certificate.themes.slice(0, Math.round(certificate.themes.length / 2))
    secondList = certificate.themes.slice(Math.round(certificate.themes.length / 2))
  } else {
    firstList = certificate.themes
  }
  const {firstName, lastName, sex, date} = certificate

  return (
    <div className={'certificate-container'}>
      <div className={'certificate-buttons'}>
        <Button
          type={'button'}
          iconPath={acDownload}
          iconPosition={'left'}
          size={'sm'}
          color={'primary'}
          isLoading={isSendingRequest}
          disabled={isSendingRequest}
          onClick={onDownload}>
          Скачать
        </Button>
      </div>
      <section className={'certificate'} id={'certificate-pdf'}>
        <div className={'flex-grow-1'}>
          <div className={'logo-wrapper'}>
            <img src={'/img/certificate/logo.svg'} width={344} height={44} alt={'Логотип LearnDB'} className={'logo'} />
          </div>
          <div id={'pdf'}>
            <h1 className={'header'}>Сертификат</h1>
          </div>
          <p className={'confirm'}>Этот сертификат подтверждает, что</p>
          <p className={'confirm-fio'}>{firstName + ' ' + lastName}</p>
          <p className={'course-name'}>В курсе: SQL запросы в СУБД PostgreSQL</p>
          <p className={'theme-header'}>{sex === 'men' ? 'Прошел' : sex === 'women' ? 'Прошла' : 'Прошел(а)'} темы:</p>
          <div className={'theme-list-wrapper'}>
            <ul className={'theme-list-first'}>
              {firstList.map((t) => (
                <li key={t.themeId} className={'theme'}>
                  <h3 className={'theme-name'}>{t.name}</h3>
                  <p className={t.countSeeAnswers ? 'see-answers' : 'solve-himself'}>
                    {t.countSeeAnswers ? t.countTotalTasks - t.countSeeAnswers + '/' + t.countTotalTasks : 'Все'}
                    {sex === 'men' ? ' решил сам' : sex === 'women' ? ' решила сама' : ' решил(а) сам(а)'}
                  </p>
                </li>
              ))}
            </ul>
            <ul className={'theme-list-second'}>
              {secondList.map((t) => (
                <li key={t.themeId} className={'theme'}>
                  <h3 className={'theme-name'}>{t.name}</h3>
                  <p className={t.countSeeAnswers ? 'see-answers' : 'solve-himself'}>
                    {t.countSeeAnswers ? t.countTotalTasks - t.countSeeAnswers + '/' + t.countTotalTasks : 'Все'}
                    {sex === 'men' ? ' решил сам' : sex === 'women' ? ' решила сама' : ' решил(а) сам(а)'}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <p className={'check-url'}>
          Действительность сертификата можно проверить по адресу:
          <br />
        </p>
        <a className={'check-url-a'} href={checkUrl} target={'_blank'} rel={'noreferrer'}>
          https://learndb.ru{checkUrl}
        </a>
        {date ? <p className={'date'}>{formatDate(date, 'LL')}</p> : null}
      </section>
    </div>
  )
}

export {Certificate}
