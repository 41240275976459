import React, {useEffect, useState} from 'react'
import {Button, ButtonLink, Card, CardBody, Col, COLORS, Container, FormFeedback, Icon, Input, Row} from 'ac_ui'
import {URLS} from '../../constants/urls'
import {acLogin} from 'ac-icon'
import {useLocation, useParams} from 'react-router-dom'
import {IReduxState} from '../../redux/types/IReduxState'
import {useDispatch, useSelector} from 'react-redux'
import {IIsSending} from './domain/IIsSending'
import {UNKNOWN_ERROR_MESSAGE} from '../../../api/constants'
import {ResponseError} from 'ac-share'
import {authActions} from './redux/actions'

const SignInPage: React.FC = () => {
  const authDispatch = useDispatch()
  const location = useLocation()
  const {taskId} = useParams()
  const newTaskId: number | undefined = taskId ? parseInt(taskId, 10) : undefined

  const emailConfirmed = useSelector<IReduxState, string | undefined>((state) => state.auth?.emailConfirmed)
  const emailConfirmedError = useSelector<IReduxState, string | undefined>((state) => state.auth?.emailConfirmedError)
  const [isSending, setIsSending] = useState<IIsSending>({
    isSendingRequest: false,
    isSendingSuccess: false,
  })
  const [email, setEmail] = useState<string | undefined>('')
  const [password, setPassword] = useState<string | undefined>('')
  const [error, setError] = useState<ResponseError | undefined>(undefined)
  const [isSendingAnonymousUserRequest, setIsSendingAnonymousUserRequest] = useState<boolean>(false)
  const [errorMessageCreateAnonymousUser, setErrorMessageCreateAnonymousUser] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!email && emailConfirmed) {
      setEmail(emailConfirmed)
    }
  }, [email, emailConfirmed])

  const isSubmitDisabled = (): boolean => {
    return !(!isSending.isSendingRequest && email && email !== '' && password && password !== '')
  }

  const handleSubmit = (evt): void => {
    evt.preventDefault()
    if (!isSubmitDisabled()) {
      setIsSending({
        isSendingSuccess: false,
        isSendingRequest: true,
      })
      authDispatch(
        authActions.login(
          email,
          password,
          () => {
            setIsSending({
              isSendingSuccess: false,
              isSendingRequest: false,
            })
            const currentUrl = new URL(window.location.href)
            const redirectUrl = currentUrl.searchParams.get('redirectUrl') || '/profile/i'
            window.location.replace(redirectUrl)
          },
          (err: ResponseError) => {
            setIsSending({
              isSendingSuccess: false,
              isSendingRequest: false,
            })
            setError({
              status: err.status || 500,
              errorMessage: err.errorMessage || UNKNOWN_ERROR_MESSAGE,
            })
          },
        ),
      )
    }
  }

  const createAnonymousUser = (): void => {
    setIsSendingAnonymousUserRequest(true)
    authDispatch(
      authActions.createAnonymousUser(
        () => {
          window.location.replace(`${URLS.tasks}/${newTaskId}`)
        },
        (err) => {
          setIsSendingAnonymousUserRequest(false)
          setErrorMessageCreateAnonymousUser(err.errorMessage || UNKNOWN_ERROR_MESSAGE)
        },
      ),
    )
  }

  const selectLandingPage = (): React.ReactElement => {
    if (location.pathname.indexOf(URLS.tasks) === 0) {
      return (
        <div>
          <FormFeedback
            message={errorMessageCreateAnonymousUser || UNKNOWN_ERROR_MESSAGE}
            type={'danger'}
            className={'my-0 mb-x8'}
            isShow={!!errorMessageCreateAnonymousUser}
          />
          <Button onClick={createAnonymousUser}>Начать</Button>
        </div>
      )
    } else {
      return <ButtonLink href={URLS.themes}>Начать</ButtonLink>
    }
  }

  const renderTryUnauthorized = (): React.ReactNode => {
    return (
      <>
        <p className={'caption-pass-unregistration'}>Попробовать без регистрации</p>
        <p className={'caption-save-progress '}>
          Вы можете зарегистрироваться в любой момент <br />и при этом ваш прогресс сохранится
        </p>
        {selectLandingPage()}
      </>
    )
  }

  const isSuccess = !error?.errorMessage && !emailConfirmedError && !!emailConfirmed
  return (
    <Container>
      <div className={'background-image-auth'}>
        <Row>
          <Col lg={{size: 4, offset: 2}} md={{size: 5, offset: 1}} sm={12}>
            <form className={'sign-in-form'} onSubmit={handleSubmit}>
              <Card className={'sign-in-card'}>
                <CardBody className={'px-xs-0 py-xs-x10 p-sm-x10'}>
                  <div className={'caption-authorization'}>
                    <Icon path={acLogin} color={COLORS.blue} size={1.5} className={'mr-x2'} title={undefined} />
                    <h1 className={'caption-authorization-h1'}>Вход</h1>
                  </div>
                  <div className={'mb-x6'}>
                    <Input
                      id={'signInEmail'}
                      name={'email'}
                      type={'text'}
                      label={'Email'}
                      value={email || emailConfirmed || ''}
                      required
                      onChange={(value) => {
                        setEmail(value)
                      }}
                    />
                    <Input
                      id={'signInPassword'}
                      name={'password'}
                      type={'password'}
                      label={'Пароль'}
                      value={password}
                      required
                      className={'mb-x10'}
                      onChange={(value) => {
                        setPassword(value)
                      }}
                    />
                    <FormFeedback
                      isShow={!!error?.errorMessage || !!emailConfirmedError || !!emailConfirmed}
                      className={'mt-n-x4'}
                      message={
                        error?.errorMessage ||
                        emailConfirmedError ||
                        (emailConfirmed ? 'Ваш email подтвержден, теперь можете войти в свою учетную запись' : null)
                      }
                      type={isSuccess ? 'success' : 'danger'}
                    />
                  </div>
                  <Button
                    type={'submit'}
                    color={'primary'}
                    size={'md'}
                    className={'w-100 mb-x8'}
                    disabled={isSubmitDisabled()}>
                    Войти
                  </Button>
                  <p className={'link-password-recovery'}>
                    <a href={URLS.recoverPasswordEmail}>Забыли пароль?</a>
                  </p>
                  <ButtonLink href={URLS.signup} className={'text-center w-100'} style={'outline'}>
                    Регистрация
                  </ButtonLink>
                  <div className={'try-authorized-mobile'}>{renderTryUnauthorized()}</div>
                </CardBody>
              </Card>
            </form>
          </Col>
          <Col lg={{size: 5, offset: 1}} md={{size: 4, offset: 1}} sm={0}>
            <div className={'mobile-hidden-auth'}>{renderTryUnauthorized()}</div>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export {SignInPage}
