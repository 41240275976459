import React from 'react'
import {Container} from 'ac_ui'

const CompaniesStudentsWork: React.FC = () => {
  return (
    <section className={'students-work'}>
      <Container className={'students-work-container'}>
        <h2 className={'landing-h2 students-work-h2'}>Компании, в которых работают наши студенты</h2>
        <div className={'students-work-list'}>
          <img
            src={'/img/landing/company/tinkoff.svg'}
            className={'students-work-card'}
            width={170}
            height={54}
            alt={'логотип Тинькофф'}
          />
          <img
            src={'/img/landing/company/cft.svg'}
            className={'students-work-card'}
            width={164}
            height={54}
            alt={'логотип ЦФТ'}
          />
          <img
            src={'/img/landing/company/sber.svg'}
            className={'students-work-card'}
            width={241}
            height={54}
            alt={'логотип Sber Tech'}
          />
          <img
            src={'/img/landing/company/moex.svg'}
            className={'students-work-card'}
            alt={'логотип Московская биржа'}
            width={140}
            height={54}
          />
          <img
            src={'/img/landing/company/avito.svg'}
            className={'students-work-card'}
            width={221}
            height={54}
            alt={'логотип avito.tech'}
          />
        </div>
      </Container>
    </section>
  )
}

export {CompaniesStudentsWork}
