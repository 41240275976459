import React, {useEffect, useState} from 'react'
import {CountUp} from 'use-count-up'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {UserDto} from 'learndb-share'

const CoinsCounter: React.FC = (): React.ReactElement => {
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)
  const userAnonymous = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.userAnonymous)

  let coinsUser = 0
  if (user) {
    coinsUser = user.wallet?.coins || 0
  } else if (userAnonymous) {
    coinsUser = userAnonymous.wallet?.coins || 0
  }
  const [startCoins, setStartCoins] = useState<number>(coinsUser)
  const [endCoins, setEndCoins] = useState<number>(coinsUser)

  useEffect(() => {
    setEndCoins(coinsUser)
  }, [coinsUser])

  return (
    <CountUp
      key={endCoins}
      isCounting={true}
      start={startCoins}
      end={endCoins}
      duration={2}
      onComplete={() => {
        setStartCoins(endCoins)
      }}
    />
  )
}

export {CoinsCounter}
