export enum YmTargetEnum {
  DOWNLOAD_CERTIFICATE = 'DOWNLOAD_CERTIFICATE',
  OPEN_ACHIEVEMENT_PAGE = 'OPEN_ACHIEVEMENT_PAGE',
  PAY_COINS_MONTH = 'PAY_COINS_MONTH',
  PAY_COINS_YEARS = 'PAY_COINS_YEARS',
  PAY_MONEY_MONTH = 'PAY_MONEY_MONTH',
  PAY_MONEY_YEARS = 'PAY_MONEY_YEARS',
  OPEN_FEEDBACK = 'OPEN_FEEDBACK',
  TELEGRAM_CHAT = 'TELEGRAM_CHAT',
  TELEGRAM_CHANNEL = 'TELEGRAM_CHANNEL',
  VK_CHANNEL = 'VK_CHANNEL',
  // OPEN_CERTIFICATE_PAGE = 'OPEN_CERTIFICATE_PAGE', //TODO:: добавить
  // OPEN_CERTIFICATE_CHECK_PAGE = 'OPEN_CERTIFICATE_CHECK_PAGE', //TODO:: добавить
}
