import React, {useEffect} from 'react'
import {Certificate} from './Certificate'
import {Container, MultiLink} from 'ac_ui'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {IReduxState} from '../../../redux/types/IReduxState'
import {profileActions} from '../../profile/redux/actions'
import {QuestionAnswer} from '../../../types/question/QuestionAnswer'
import {URLS} from '../../../constants/urls'
import {FeedbackButton} from '../../feedback/components/FeedbackButton'
import {QuestionBlock} from '../../common/questionBlock/QuestionBlock'

interface ICertificateContainerProps {
  certificateId: string
  isShowQuestions?: boolean
}

const CertificateContainer: React.FC<ICertificateContainerProps> = ({certificateId, isShowQuestions}) => {
  const authDispatch = useDispatch()

  const {certificateId: certificateIdParam} = useParams()
  const newCertificateId = certificateId || certificateIdParam
  const certificate = useSelector<IReduxState, any | undefined>((state) => state.profile?.certificate)

  useEffect(() => {
    authDispatch(profileActions.getCertificate(newCertificateId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const questions: QuestionAnswer[] = [
    {
      question: 'Что будет написано в сертификате если все задания темы решить самостоятельно?',
      answer: (
        <span>
          Под каждой темой будет написано: Все решил(а) сам(а). Если вы в{' '}
          <MultiLink href={URLS.profile + URLS.profilePreferences} type={'href'}>
            настройках
          </MultiLink>{' '}
          укажите свой пол, то в сертификате будет точное склонение. Например: «Решил сам» или «Решила сама».
        </span>
      ),
    },
    {
      question: 'Что будет написано в сертификате если посмотреть ответ до решения задания?',
      answer: (
        <span>
          Под каждой темой будет указано сколько заданий в теме решено самостоятельно. Например: 9/15 решил(а) сам(а).
        </span>
      ),
    },
    {
      question: 'Если посмотреть ответы после самостоятельного решения задания, то это отразится в сертификате?',
      answer: <span>Нет. В сертификате будет написано что вы решили тему самостоятельно.</span>,
    },
    {
      question: 'Сертификат останется по окончанию подписки?',
      answer: (
        <span>Да. Так же сертификат доступен по ссылке с любого устройства не зависимо от наличия подписки.</span>
      ),
    },
    {
      question: 'Остались вопросы?',
      answer: (
        <div className={'w-100 d-flex flex-column align-items-start '}>
          <span>
            Воспользуйтесь кнопкой ниже или напишите нам на <a href={'mailto:support@learndb.ru'}>support@learndb.ru</a>
          </span>
          <FeedbackButton className={'mt-x4'} />
        </div>
      ),
      isOpenInitial: true,
    },
  ]

  return (
    <>
      <section className={'profile-view-header'}>
        <Container>
          <h1 className={'profile-view-header-h1'}>Сертификат</h1>
        </Container>
      </section>
      <section className={'certificate-container-wrapper'}>
        <Container className={'certificate-page-container'}>
          <Certificate certificate={certificate} />
        </Container>
        {isShowQuestions && certificate && (
          <QuestionBlock
            questions={questions}
            header={'Частые вопросы'}
            css={{
              wrapper: 'certificate-question-wrapper',
              container: 'py-0',
              h2: 'certificate-question-header',
            }}
          />
        )}
      </section>
    </>
  )
}

export {CertificateContainer}
