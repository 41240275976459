import React, {useState} from 'react'
import {Button, Card, CardBody, createSuccessToast, Input} from 'ac_ui'
import {useDispatch} from 'react-redux'
import {UserDto, UserSocialDto} from 'learndb-share'
import {authActions} from '../../auth/redux/actions'

interface ISocialFormProps {
  user: UserDto
}

const SocialForm: React.FC<ISocialFormProps> = ({user}) => {
  const authDispatch = useDispatch()
  const [newSocial, setNewSocial] = useState<UserSocialDto | undefined>({...user.social})
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)

  const handleChange = (evt): void => {
    newSocial[evt.name] = evt.value
    setNewSocial({
      ...newSocial,
    })
  }

  const enableSubmit = (): void => {
    setIsSubmitDisabled(false)
  }

  const handleSubmit = (evt): void => {
    evt.preventDefault()
    setIsSubmitDisabled(true)
    authDispatch(
      authActions.saveUserSocial(
        {...user, social: newSocial},
        () => {
          createSuccessToast('Сохранено')
          enableSubmit()
        },
        enableSubmit,
      ),
    )
  }

  if (newSocial) {
    const {vk, github, telegram, discord, skype} = newSocial
    return (
      <form onSubmit={handleSubmit}>
        <Card className={'profile-edit-card'}>
          <CardBody className={'profile-edit-card-body'}>
            <h2 className={'header'}>Социальные сети</h2>
            <Input
              id={'socialVk'}
              name={'vk'}
              type={'text'}
              label={'Вконтакте'}
              required={false}
              value={vk}
              onChange={(value) => {
                handleChange({name: 'vk', value: value})
              }}
              className={'mb-x6'}
            />
            <Input
              id={'socialGithub'}
              name={'github'}
              type={'text'}
              label={'GitHub'}
              required={false}
              value={github}
              onChange={(value) => {
                handleChange({name: 'github', value: value})
              }}
              className={'mb-x6'}
            />
            <Input
              id={'socialTelegram'}
              name={'telegram'}
              type={'text'}
              label={'Telegram'}
              required={false}
              value={telegram}
              onChange={(value) => {
                handleChange({name: 'telegram', value: value})
              }}
              className={'mb-x6'}
            />
            <Input
              id={'socialDiscord'}
              name={'discord'}
              type={'text'}
              label={'Discord'}
              required={false}
              value={discord}
              onChange={(value) => {
                handleChange({name: 'discord', value: value})
              }}
              className={'mb-x6'}
            />
            <Input
              id={'socialSkype'}
              name={'skype'}
              type={'text'}
              label={'Skype'}
              required={false}
              value={skype}
              onChange={(value) => {
                handleChange({name: 'skype', value: value})
              }}
              className={'mb-x8'}
            />
            {/*<Input*/}
            {/*  id={'socialFacebook'}*/}
            {/*  name={'facebook'}*/}
            {/*  type={'text'}*/}
            {/*  label={'Facebook'}*/}
            {/*  required={false}*/}
            {/*  value={facebook}*/}
            {/*  onChange={(value) => {*/}
            {/*    handleChange({name: 'facebook', value: value})*/}
            {/*  }}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  id={'socialInstagram'}*/}
            {/*  name={'instagram'}*/}
            {/*  type={'text'}*/}
            {/*  label={'Instagram'}*/}
            {/*  required={false}*/}
            {/*  value={instagram}*/}
            {/*  onChange={(value) => {*/}
            {/*    handleChange({name: 'instagram', value: value})*/}
            {/*  }}*/}
            {/*/>*/}
            <Button type={'submit'} disabled={isSubmitDisabled} className={'w-100'}>
              Сохранить
            </Button>
          </CardBody>
        </Card>
      </form>
    )
  } else {
    return <></>
  }
}

export {SocialForm}
