import React from 'react'
import {MultiLink} from 'ac_ui'
import {ArticleTaskDto, TaskDto, TaskInfoDto} from 'learndb-share'
import {getDiamondByLevel} from './utils'
import {URLS} from '../../../constants/urls'

interface IButtonTaskProps {
  id: string
  task: ArticleTaskDto | TaskInfoDto | TaskDto
  name?: string
  className?: string
}

const ButtonTask: React.FC<IButtonTaskProps> = ({id, task, name, className}) => {
  const classNameResult = 'button-task' + ' ' + (className ? className : '')

  return (
    <MultiLink
      id={id}
      href={URLS.tasks + '/' + task.taskId}
      type={'href'}
      key={task.taskId}
      className={'text-decoration-none-i'}>
      <li className={classNameResult}>
        <div className={'d-flex mr-x2'}>{getDiamondByLevel(task.levelId, id)}</div>
        <div className={'button-task-text-wrapper'}>
          <p className={'button-task-text-name'}>{name || 'Практика'}</p>
          <p className={'button-task-text-description'}> {task.name}</p>
        </div>
      </li>
    </MultiLink>
  )
}

export {ButtonTask}
