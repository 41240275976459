import React from 'react'
import {Col, Container, Row} from 'ac_ui'

const ArticleListHeader: React.FC = () => {
  return (
    <Container size={'big'}>
      <section className={'article-list-header'}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={{size: 8, offset: 2}} xl={{size: 6, offset: 3}}>
            <div className={'d-flex flex-column align-items-center text-center'}>
              <img
                src={'/img/article/articleListHeader.svg'}
                className={'article-list-header-image mb-x4'}
                alt={'Учебник по SQL'}
              />
              <h1 className={'article-list-header-title'}>
                Учебник по SQL
                <br />
                <span className={'text-primary'}>с практическими заданиями</span>
              </h1>
              <p className={'article-list-header-description'}>
                Интерактивный учебник по SQL даст нужный минимум знаний для работы с базами данных. Большинство тем
                содержат задания, которые позволят закрепить полученный материал на практике.
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  )
}

export {ArticleListHeader}
