import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../../redux/types/IReduxState'
import {WalletHistoryDto} from 'learndb-share'
import {profileActions} from '../../../profile/redux/actions'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'ac_ui'
import {acHistory} from 'ac-icon'
import {formatDateTime} from 'ac-share'
import {EmptyList} from '../../../common/EmptyList'
import moment from 'moment'

moment.locale('ru')

const CoinsHistoryModal: React.FC = (): React.ReactElement => {
  const profileDispatch = useDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const walletHistories = useSelector<IReduxState, WalletHistoryDto[] | undefined>(
    (state) => state.profile?.walletHistories,
  )

  useEffect(() => {
    profileDispatch(profileActions.getWalletHistories())
  }, [profileDispatch])

  const renderButton = (): React.ReactElement => {
    return (
      <Button
        style={'link'}
        className={'mt-x4'}
        size={'sm'}
        iconPosition={'left'}
        iconPath={acHistory}
        onClick={() => {
          setIsOpen(true)
        }}>
        История операций
      </Button>
    )
  }

  const renderModalContent = (): React.ReactElement => {
    const items: React.ReactElement[] = []
    if (walletHistories) {
      for (const walletHistory of walletHistories) {
        items.push(
          <div key={walletHistory.walletHistoryId} className={'coins-history-item'}>
            <p className={'coins-history-item-coins ' + (walletHistory.operationType.sign === 1 ? 'add' : 'subtract')}>
              {(walletHistory.operationType.sign === 1 ? '+' : '-') + walletHistory.coins.toString()}
            </p>
            <p className={'coins-history-item-description'}>{walletHistory.description}</p>
            <p className={'coins-history-item-time'}>
              {formatDateTime(walletHistory.createDate, 'DD MMMM YYYY, HH:mm')}
            </p>
            <p className={'coins-history-item-coins-before'}>
              Остаток до операции: {walletHistory.walletCoinsBefore.toString()}
            </p>
          </div>,
        )
      }
    }
    if (items.length > 0) {
      return <div className={'coins-history'}>{items}</div>
    } else {
      return (
        <div className={'coins-history'}>
          <EmptyList type={'NOT_FOUND_COINS'} title={'Пусто'} description={'Монеты не тратили'} isMobileImg />
        </div>
      )
    }
  }

  return (
    <>
      {renderButton()}
      {isOpen && (
        <Modal
          size={'sm'}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
          isUpdateBodyScroll={false}
          closeOnEscape={true}>
          <ModalHeader isBorder className={'coin-history-modal-header'}>
            История операций
          </ModalHeader>
          <ModalBody isScroll>{renderModalContent()}</ModalBody>
          <ModalFooter isBorder className={'pt-x4 pb-x6 w-100'}>
            <Button
              onClick={() => {
                setIsOpen(false)
              }}
              className={'w-100'}>
              Закрыть
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export {CoinsHistoryModal}
