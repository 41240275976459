import {SubscriptionPeriod, UserDto} from 'learndb-share'
import {redirectToSignInPage} from '../../auth/utils'
import {URLS} from '../../../constants/urls'
import {paymentActions} from '../redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'

type PayFunction = (
  summa: number,
  subscriptionPeriodId: SubscriptionPeriod,
  couponCode: string,
  forUserEmail: string,
) => Promise<void>

const usePay = (): any => {
  const userAuth = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)
  const paymentDispatch = useDispatch()

  const pay: PayFunction = async (
    summa: number,
    subscriptionPeriodId: SubscriptionPeriod,
    couponCode: string,
    forUserEmail: string,
  ): Promise<void> => {
    if (!userAuth) {
      redirectToSignInPage(URLS.payment)
    } else {
      paymentDispatch(paymentActions.createPayment({summa, subscriptionPeriodId, couponCode, forUserEmail}))
    }
  }

  return {pay}
}

export {usePay, PayFunction}
