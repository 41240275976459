import React, {useEffect, useState} from 'react'
import {ButtonLink, Col, Container, MultiLink, Row} from 'ac_ui'
import {PaymentTypeCard} from '../../payment/components/PaymentTypeCard'
import {SubscriptionPeriod, SubscriptionPriceMapDto, YandexPaymentDto} from 'learndb-share'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {ResponseError} from 'ac-share'
import {URLS} from '../../../constants/urls'
import {PAYMENT_FORM_ID} from '../../payment/PaymentTypes'
import {PaymentModal} from '../../payment/components/PaymentModal'
import {usePay} from '../../payment/hooks/usePay'

interface IYouCanStartProps {
  priceMap: SubscriptionPriceMapDto | undefined
}

const YouCanStart: React.FC<IYouCanStartProps> = ({priceMap}) => {
  let checkout: any | undefined
  const payment = useSelector<IReduxState, YandexPaymentDto | undefined>((state) => state.payment?.payment)
  const [error, setError] = useState<ResponseError | undefined>(undefined)
  const {pay} = usePay()

  const [subscriptionPeriodIdProp, setSubscriptionPeriodIdProp] = useState<SubscriptionPeriod>(undefined)

  const priceMonth = priceMap ? priceMap[SubscriptionPeriod.MONTH] : undefined
  const priceYear = priceMap ? priceMap[SubscriptionPeriod.YEAR] : undefined

  useEffect(() => {
    if (payment) {
      if ((window as any).YooMoneyCheckoutWidget) {
        checkout = new (window as any).YooMoneyCheckoutWidget({
          confirmation_token: payment.confirmation.confirmation_token,
          return_url: window.location.origin + URLS.paymentFinish + '/' + payment.id,
          error_callback: (newError) => {
            //Обработка ошибок инициализации
            console.error(error)
            setError(newError)
          },
        })
        checkout.render(PAYMENT_FORM_ID)
      } else {
        console.error('Error loading YooMoney widget')
      }
    }
  }, [payment])

  if (!priceMap) {
    return <></>
  }

  return (
    <section className={'you-can'}>
      <Container className={'you-can-container'}>
        <h2 className={'landing-h2 you-can-one-h2'}>Начать можно бесплатно и без регистрации</h2>
        <Row className={'you-can-card-wrapper'}>
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <div className={'you-can-card'}>
              <p className={'you-can-card-name'}>Первые 3 темы бесплатно 🤩</p>
              <p className={'you-can-card-description'}>
                Этого хватит чтобы познакомиться с&nbsp;платформой и&nbsp;решить, подходит ли&nbsp;вам наш&nbsp;курс.
              </p>
              <ButtonLink href={URLS.themes} size={'lg'}>
                Начать обучение
              </ButtonLink>
            </div>
          </Col>
        </Row>
        <h2 className={'landing-h2 you-can-two-h2'}>а после оплатить и продолжить</h2>
        <p className={'you-can-two-p'}>
          Вы можете зарегистрироваться и&nbsp;оформить{' '}
          <MultiLink href={'/payment'} type={'href'}>
            подписку
          </MultiLink>{' '}
          в&nbsp;любой момент. Ваш&nbsp;прогресс сохранится.
        </p>
        <Row className={'you-can-payments'}>
          <Col xs={12} sm={8} md={6} lg={4} xl={4} className={'d-flex'}>
            <div id={'month-card'} />
            <PaymentTypeCard
              header={'Месяц'}
              description={<p className={'mb-0'}>Доступ ко&nbsp;всем темам на&nbsp;период действия подписки</p>}
              price={priceMonth}
              duration={'мес'}
              subscriptionPeriodId={SubscriptionPeriod.MONTH}
              setSubscriptionPeriodIdProp={setSubscriptionPeriodIdProp}
              pay={pay}
              userAuth={undefined}
            />
          </Col>
          <Col xs={12} sm={8} md={6} lg={4} xl={4} className={'d-flex'}>
            <PaymentTypeCard
              header={'Год'}
              description={
                <div>
                  <p className={'mb-0'}>Доступ ко&nbsp;всем темам на&nbsp;период действия подписки</p>
                </div>
              }
              price={priceYear}
              duration={'год'}
              subscriptionPeriodId={SubscriptionPeriod.YEAR}
              setSubscriptionPeriodIdProp={setSubscriptionPeriodIdProp}
              pay={pay}
              userAuth={undefined}
            />
          </Col>
        </Row>
        <ButtonLink style={'ghost'} size={'lg'} href={'/payment'}>
          Подробнее о подписке
        </ButtonLink>
      </Container>
      <PaymentModal payment={payment} error={error} />
    </section>
  )
}

export {YouCanStart}
