import {AchievementCategoryDto, AchievementDto} from 'learndb-share'
import {constants} from '../../../redux/constants'
import {IBaseAction} from '../../../redux/types/IBaseAction'

export interface IAchievementState {
  achievementCategories: AchievementCategoryDto[] | undefined
  isNeedUpdateAchievementCategories: boolean
  lastCompletedAchievements: AchievementDto[] | undefined
  isNeedUpdateLastCompletedAchievements: boolean

  isOpenAchievementPage?: boolean
}

const INITIAL_STATE: IAchievementState = {
  achievementCategories: undefined,
  isNeedUpdateAchievementCategories: false,
  lastCompletedAchievements: undefined,
  isNeedUpdateLastCompletedAchievements: false,
  isOpenAchievementPage: false,
}

function achievementReducers(state = INITIAL_STATE, action: IBaseAction): IAchievementState {
  switch (action.type) {
    case constants.SET_ACHIEVEMENT_CATEGORIES: {
      return {
        ...state,
        achievementCategories: action.payload,
        isNeedUpdateAchievementCategories: false,
      }
    }

    case constants.SET_LAST_COMPLETED_ACHIEVEMENTS: {
      return {
        ...state,
        lastCompletedAchievements: action.payload,
        isNeedUpdateLastCompletedAchievements: false,
      }
    }

    case constants.SET_IS_NEED_UPDATE_ACHIEVEMENT_CATEGORIES: {
      return {
        ...state,
        isNeedUpdateAchievementCategories: true,
      }
    }

    case constants.SET_IS_NEED_UPDATE_LAST_COMPLETED_ACHIEVEMENTS: {
      return {
        ...state,
        isNeedUpdateLastCompletedAchievements: true,
      }
    }

    case constants.SET_IS_OPEN_ACHIEVEMENT_PAGE: {
      return {
        ...state,
        isOpenAchievementPage: true,
      }
    }

    default: {
      return state
    }
  }
}

export {achievementReducers}
