import React from 'react'
import {Col, Container, Row} from 'ac_ui'

const FOR_WHOM_LIST: {
  src: string
  name: string
  alt: string
  description: React.ReactElement
}[] = [
  {
    src: 'student.svg',
    name: 'Студент',
    alt: 'Учебники',
    description: <>Курс отлично подходит для подготовки к&nbsp;экзаменам и старта карьеры в IT.</>,
  },
  {
    src: 'developer.svg',
    name: 'Разработчик',
    alt: 'Код',
    description: <>Пишите оптимальные запросы для&nbsp;своих сервисов.</>,
  },
  {
    src: 'analyst.svg',
    name: 'Аналитик',
    alt: 'Диаграмма',
    description: <>Получайте информацию напрямую из&nbsp;баз данных без&nbsp;помощи программистов.</>,
  },
  {
    src: 'tester.svg',
    name: 'Тестировщик',
    alt: 'A/B тест',
    description: <>Давайте разработчикам более понятную обратную связь, работая с&nbsp;базами данных.</>,
  },
  {
    src: 'administrator.svg',
    name: 'Администратор БД (DBA)',
    alt: 'База данных',
    description: <>Повысьте свою эффективность в&nbsp;работе с&nbsp;реляционными базами данных.</>,
  },
  {
    src: 'marketing.svg',
    name: 'Маркетолог',
    alt: 'Мегафон',
    description: (
      <>Самостоятельно анализируйте данные о&nbsp;клиентах для&nbsp;разработки эффективных маркетинговых стратегий.</>
    ),
  },
]

const ForWhom: React.FC = () => {
  const items: React.ReactElement[] = []

  for (const item of FOR_WHOM_LIST) {
    items.push(
      <Col key={item.src} tag={'li'} className={'for-whom-card'} xs={12} sm={12} md={6} lg={4} xl={4}>
        <div className={'for-whom-card-img-wrapper'}>
          <img src={'/img/landing/' + item.src} alt={item.alt} className={'img'} width={120} height={120} />
        </div>
        <p className={'for-whom-card-name'}>{item.name}</p>
        <p className={'for-whom-card-description'}>{item.description}</p>
      </Col>,
    )
  }
  return (
    <section className={'landing-for-whom'}>
      <Container className={'landing-for-whom-container'}>
        <h2 className={'landing-h2 for-whom-h2'}>Кому подойдет</h2>
        <Row tag={'ul'} className={'for-whom-list'}>
          {items}
        </Row>
      </Container>
    </section>
  )
}

export {ForWhom}
