import React from 'react'
import {CoinSvg, createToast} from 'ac_ui'
import {AchievementDto} from 'learndb-share'
import {URLS} from '../../../constants/urls'
import {CONFIG} from '../../../constants'

function createAchievementToast(achievement: AchievementDto, isLink: boolean): void {
  let content: React.ReactElement = (
    <div className={'d-flex flex-row align-items-center'}>
      <div className={'d-flex align-self-start'}>
        <img
          src={
            achievement.userAchievement
              ? CONFIG.cdnWeb + '/achievement/' + achievement.imageFileName
              : '/img/achievement/default.svg'
          }
          width={96}
          height={96}
          alt={achievement.name}
        />
      </div>
      <div className={'d-flex flex-column ml-x2'}>
        <p className={'achievement-toast-content-header mb-x2'}>Достижение</p>
        <p className={'achievement-toast-content-name mb-0'}>{achievement.name}</p>
        {achievement.coins > 0 && (
          <div className={'d-flex flex-row align-items-center py-x1 mt-x2'}>
            <div className={'d-flex mr-x1'} title={'Монеты'}>
              <CoinSvg id={'achievement-coin'} width={16} height={16} />
            </div>
            <p className={'achievement-toast-content-coin mb-0'}>{achievement.userAchievement.coins}</p>
          </div>
        )}
      </div>
    </div>
  )
  if (isLink) {
    content = (
      <a
        href={URLS.profile + URLS.profileAchievement + '?achievementId=' + achievement.achievementId}
        className={'text-decoration-none-i'}>
        {content}
      </a>
    )
  }

  createToast(content, {
    type: 'info',
    icon: false,
    closeOnClick: true,
  })
}

export {createAchievementToast}
