import {LevelEnum, TaskDto, ThemeDto} from 'learndb-share'
import {IFilters} from '../domain/IFilters'
import {findLayoutKeyboard, setByEnRu, setByRuEn} from 'ac-share'

function getFilterThemes(newThemes: ThemeDto[], newFilters: IFilters): ThemeDto[] {
  if (!newFilters) {
    return newThemes
  } else {
    const filteredThemes: ThemeDto[] = []
    newThemes.forEach((theme) => {
      const isLevelEnabled = newFilters.level === 'all' ? true : newFilters.level === theme.levelId
      if (isLevelEnabled) {
        const string = (newFilters.string || '').trim()
        if (!string) {
          filteredThemes.push(theme)
        } else {
          let is: boolean
          const layout = findLayoutKeyboard(string[0])
          if (layout === 'RU') {
            is = theme.name?.toLowerCase().includes(setByRuEn(string?.toLowerCase()))
            if (!is) {
              is = theme.description?.toLowerCase().includes(setByRuEn(string?.toLowerCase()))
            }
          } else {
            is = theme.name?.toLowerCase().includes(setByEnRu(string?.toLowerCase()))
            if (!is) {
              is = theme.description?.toLowerCase().includes(setByEnRu(string?.toLowerCase()))
            }
          }
          if (
            is ||
            theme.name.match(new RegExp(string.replace(' ', '.+'), 'i')) ||
            theme.name.toLowerCase().includes((string || '').toLowerCase()) ||
            theme.description.toLowerCase().includes((string || '').toLowerCase())
          ) {
            filteredThemes.push(theme)
          } else {
            //проверка внутри темы по названию таски
            for (let i = 0; i < (theme.tasks || []).length; i++) {
              let isTask = false
              if (layout === 'RU') {
                isTask = theme.tasks[i].name?.toLowerCase().includes(setByRuEn(string?.toLowerCase()))
              } else {
                isTask = theme.tasks[i].name?.toLowerCase().includes(setByEnRu(string?.toLowerCase()))
              }
              if (isTask || theme.tasks[i].name.toLowerCase().includes((string || '').toLowerCase())) {
                filteredThemes.push(theme)
                break
              }
            }
          }
        }
      }
    })
    return filteredThemes
  }
}

function isThemeCompleted(theme: ThemeDto): boolean {
  if (theme.tasks) {
    for (const task of theme.tasks) {
      if (!task.isSolved) {
        return false
      }
    }
  }
  return true
}

function getContinueTaskId(tasks: TaskDto[]): number {
  for (const task of tasks) {
    if (!task.isSolved) {
      return task.taskId
    }
  }
  if (tasks.length) {
    return tasks[0].taskId
  } else {
    return null
  }
}

function getBorderLineAndIndicatorImgSrc(
  newCountSolvedTasks: number,
  newCountTotalTasks: number,
  levelId: LevelEnum,
): {
  borderLineSrc: string
  indicatorImgSrc: string
} {
  const levelStr = (levelId || '').toLowerCase()
  if (newCountSolvedTasks === 0 && newCountTotalTasks === 0) {
    return {
      borderLineSrc: '/img/themes/borderLine6.svg',
      indicatorImgSrc: `/img/themes/indicator/indicator${levelStr}6.svg`,
    }
  } else if (newCountSolvedTasks === newCountTotalTasks) {
    return {
      borderLineSrc: '/img/themes/borderLine6.svg',
      indicatorImgSrc: `/img/themes/indicator/indicator${levelStr}6.svg`,
    }
  } else {
    const rate = newCountSolvedTasks / newCountTotalTasks
    if (rate < 1 / 6) {
      return {
        borderLineSrc: '/img/themes/borderLine0.svg',
        indicatorImgSrc: `/img/themes/indicator/indicator.svg`,
      }
    } else if (rate < 2 / 6) {
      return {
        borderLineSrc: '/img/themes/borderLine1.svg',
        indicatorImgSrc: `/img/themes/indicator/indicator${levelStr}1.svg`,
      }
    } else if (rate < 3 / 6) {
      return {
        borderLineSrc: '/img/themes/borderLine2.svg',
        indicatorImgSrc: `/img/themes/indicator/indicator${levelStr}2.svg`,
      }
    } else if (rate < 4 / 6) {
      return {
        borderLineSrc: '/img/themes/borderLine3.svg',
        indicatorImgSrc: `/img/themes/indicator/indicator${levelStr}3.svg`,
      }
    } else if (rate < 5 / 6) {
      return {
        borderLineSrc: '/img/themes/borderLine4.svg',
        indicatorImgSrc: `/img/themes/indicator/indicator${levelStr}4.svg`,
      }
    } else {
      return {
        borderLineSrc: '/img/themes/borderLine5.svg',
        indicatorImgSrc: `/img/themes/indicator/indicator${levelStr}5.svg`,
      }
    }
  }
}

export {isThemeCompleted, getFilterThemes, getContinueTaskId, getBorderLineAndIndicatorImgSrc}
