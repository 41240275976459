import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {SubscriptionPriceMapDto} from 'learndb-share'
import {useEffect} from 'react'
import {paymentActions} from '../redux/actions'

function useLoadPriceMap(): SubscriptionPriceMapDto {
  const paymentDispatch = useDispatch()
  const priceMap = useSelector<IReduxState, SubscriptionPriceMapDto | undefined>(
    (state) => state.payment?.subscriptionPriceMap,
  )

  // params: couponCode
  //   ? {
  //     code: couponCode,
  //   }
  // : undefined,

  useEffect(() => {
    if (!priceMap) {
      // console.log('load priceMap')
      paymentDispatch(paymentActions.getSubscriptionPrice())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return priceMap
}

export {useLoadPriceMap}
