import {getErrorActionType, getSuccessActionType} from '../../../redux/utils'
import {constants} from '../../../redux/constants'
import {getStorageData, setStorageData} from '../../../../storage/methods'
import {UserDto} from 'learndb-share'
import {LSKeysEnum} from '../../../../storage/LSKeysEnum'

export interface IAuthState {
  token?: any | undefined // Какой тип ??
  user?: UserDto | undefined
  emailConfirmed?: string | undefined
  emailConfirmedError?: string | undefined
  userAnonymous?: any // Какой тип ??
  tokenChangePassword?: any | undefined // Какой тип ??
  isConfirmCookie?: boolean
}

const INITIAL_STATE: IAuthState = {
  token: undefined,
  user: getStorageData(LSKeysEnum.USER),
  emailConfirmed: undefined,
  emailConfirmedError: undefined,
  userAnonymous: getStorageData(LSKeysEnum.ANONYMOUS),
  tokenChangePassword: undefined,
  isConfirmCookie: getStorageData(LSKeysEnum.IS_CONFIRM_COOKIE),
}

function authReducers(state = INITIAL_STATE, action): IAuthState {
  switch (action.type) {
    case constants.SET_AUTH_STATE: {
      return {
        ...state,
        ...action.state,
      }
    }

    case getSuccessActionType(constants.POST_LOGIN): {
      const token = action.data
      // setStorageData(LSKeysEnum.TOKEN, token)
      setStorageData(LSKeysEnum.USER, token?.user)
      setStorageData(LSKeysEnum.ANONYMOUS, null)
      return {
        ...state,
        token,
        user: token?.user,
        userAnonymous: null,
      }
    }

    case getSuccessActionType(constants.POST_SAVE_USER_INFO): {
      const user = action.data
      setStorageData(LSKeysEnum.USER, user)
      return {
        ...state,
        user: user,
      }
    }

    case getSuccessActionType(constants.POST_USER_SOCIAL): {
      const user = action.data
      setStorageData(LSKeysEnum.USER, user)
      return {
        ...state,
        user: user,
      }
    }

    case getSuccessActionType(constants.POST_USER_SETTING): {
      const user = action.data
      setStorageData(LSKeysEnum.USER, user)
      return {
        ...state,
        user: user,
      }
    }

    case getSuccessActionType(constants.POST_USER_AVATAR): {
      const user = action.data
      setStorageData(LSKeysEnum.USER, user)
      return {
        ...state,
        user: user,
      }
    }

    case getSuccessActionType(constants.POST_ANONYMOUS_USER): {
      const user = action.data
      const userAnonymous = {userId: user.userId}
      setStorageData(LSKeysEnum.ANONYMOUS, userAnonymous)
      return {
        ...state,
        userAnonymous,
        token: null,
      }
    }

    case getSuccessActionType(constants.GET_CONFIRM_EMAIL): {
      const {email} = action.data
      setStorageData(LSKeysEnum.ANONYMOUS, null)
      return {
        ...state,
        emailConfirmed: email,
        userAnonymous: null,
      }
    }

    case getErrorActionType(constants.GET_CONFIRM_EMAIL): {
      return {
        ...state,
        emailConfirmedError: action.error.errorMessage,
      }
    }

    case constants.CONFIRMATION_COOKIE: {
      setStorageData(LSKeysEnum.IS_CONFIRM_COOKIE, true)
      return {
        ...state,
        isConfirmCookie: true,
      }
    }

    case constants.GET_LOGOUT: {
      // setStorageData(LSKeysEnum.TOKEN, null)
      setStorageData(LSKeysEnum.USER, null)
      return {
        ...state,
        token: null,
        user: null,
      }
    }

    case getSuccessActionType(constants.GET_CHECK): {
      setStorageData(LSKeysEnum.USER, action.data)
      return {
        ...state,
        user: action.data,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

export {authReducers}
