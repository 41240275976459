import React from 'react'
import {ButtonLink, Col, Container, Loading, Row} from 'ac_ui'
import {AchievementCard} from './AchievementCard'
import {EmptyList} from '../../../common/EmptyList'
import {URLS} from '../../../../constants/urls'
import {acArrowRight} from 'ac-icon'
import {UserDto} from 'learndb-share'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../../../redux/types/IReduxState'
import classNames from 'classnames'
import {useLoadLastCompletedAchievements} from '../../hooks/useLoadLastCompletedAchievements'

interface IAchievementBlockProps {
  isPaddingBottom: boolean
  isBackgroundWhite: boolean
}

const AchievementBlock: React.FC<IAchievementBlockProps> = ({
  isPaddingBottom,
  isBackgroundWhite,
}): React.ReactElement => {
  const lastCompletedAchievements = useLoadLastCompletedAchievements()
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  const items: React.ReactElement[] = []
  if (lastCompletedAchievements) {
    if (lastCompletedAchievements.length > 0) {
      for (let i = 0; i < lastCompletedAchievements.length; i++) {
        const achievement = lastCompletedAchievements[i]
        items.push(
          <Col key={achievement.achievementId} xs={12} sm={12} md={6} lg={4} xl={4} className={'mb-x4'}>
            <AchievementCard achievement={achievement} className={'h-100'} />
          </Col>,
        )
      }
    } else {
      items.push(
        <Col key={'not-found-achievement'} xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={'d-flex flex-row justify-content-center w-100'}>
            <EmptyList
              type={'NOT_FOUND_ACHIEVEMENTS'}
              title={'Пока нет достижений'}
              description={'Выполняйте задания чтобы получить достижения'}
            />
          </div>
        </Col>,
      )
    }
  } else {
    return (
      <Col key={'loading-achievement'} xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className={'d-flex flex-column align-items-center w-100 pt-x8'}>
          <Loading />
        </div>
      </Col>
    )
  }

  const css = classNames('achievement-block', {
    'padding-bottom': isPaddingBottom,
    'background-white': isBackgroundWhite,
  })

  return (
    <section className={css}>
      <Container>
        <h2 className={'achievement-block-h2'}>Последние достижения</h2>
        <Row>{items}</Row>
        {!!user && lastCompletedAchievements.length > 0 && (
          <div className={'d-flex flex-row justify-content-end pt-x2 w-100'}>
            <ButtonLink
              size={'md'}
              style={'ghost'}
              href={URLS.profile + URLS.profileAchievement}
              iconPath={acArrowRight}
              iconPosition={'right'}
              className={'mb-0'}>
              Все достижения
            </ButtonLink>
          </div>
        )}
      </Container>
    </section>
  )
}

export {AchievementBlock}
