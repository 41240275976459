import React, {useEffect, useRef, useState} from 'react'
import {Col, Row, Container, useIsomorphicLayoutEffect} from 'ac_ui'
import {ArticlePagination} from './components/ArticlePagination'
import {ArticleTaskList} from './components/ArticleTaskList'
import {ArticleHeaderBlock} from './components/ArticleHeaderBlock'
import {ArticleContent} from './components/ArticleContent'
// import {ArticleNav} from './components/ArticleNav'
import {ArticleDto, SectionArticlesDto} from 'learndb-share'
import {ArticleSectionNav} from './components/articleSections/ArticleSectionNav'
import {ArticleSectionNavMobile} from './components/articleSectionsMobile/ArticleSectionNavMobile'

interface IArticlePageProps {
  article: ArticleDto
  articleSections: SectionArticlesDto[]
  handleChangeSearch: (value: string) => void
  searchString: string
}

const ArticleView: React.FC<IArticlePageProps> = ({article, articleSections, handleChangeSearch, searchString}) => {
  const [headerBlockHeight, setHeaderBlockHeight] = useState<number>(undefined)
  const [navBlockWidth, setNavBlockWidth] = useState<number>(undefined)
  const [navBlockHeight, setNavBlockHeight] = useState<number>(undefined)

  const headerBlockRef = useRef<HTMLDivElement>()
  const contentBlockRef = useRef<HTMLDivElement>()
  const navBlockRef = useRef<HTMLDivElement>()

  useEffect(() => {
    setTimeout(() => {
      if (headerBlockRef.current?.scrollHeight) {
        setHeaderBlockHeight(headerBlockRef.current?.scrollHeight)
      }
    }, 100)
  }, [])

  const resizeNavBlockHandler = (): void => {
    const {clientWidth} = navBlockRef?.current || {}
    setNavBlockWidth(clientWidth - 24)
  }

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('resize', resizeNavBlockHandler)
    resizeNavBlockHandler()
    return () => {
      window.removeEventListener('resize', resizeNavBlockHandler)
    }
  }, [])

  const visibleFooterHandler = (target): void => {
    const targetPosition = {
        top: window.pageYOffset + target.getBoundingClientRect().top,
        left: window.pageXOffset + target.getBoundingClientRect().left,
        right: window.pageXOffset + target.getBoundingClientRect().right,
        bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
      },
      windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight,
      }
    if (
      targetPosition.bottom > windowPosition.top &&
      targetPosition.top < windowPosition.bottom &&
      targetPosition.right > windowPosition.left &&
      targetPosition.left < windowPosition.right
    ) {
      const height = windowPosition.bottom - targetPosition.top
      if (height > 0) {
        setNavBlockHeight(height)
      }
    } else {
      setNavBlockHeight(undefined)
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (contentBlockRef?.current?.scrollHeight >= 0) {
      const element = document.getElementById(`page-footer`)

      window.addEventListener('scroll', function () {
        visibleFooterHandler(element)
      })
      visibleFooterHandler(element)
      return () => {
        window.removeEventListener('scroll', function () {
          visibleFooterHandler(element)
        })
      }
    }
  }, [contentBlockRef?.current?.scrollHeight])

  return (
    <Container size={'big'}>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={3}
          xl={3}
          attribute={{
            ref: navBlockRef,
          }}>
          {navBlockWidth > 0 && (
            <div
              className={'article-view-first-col'}
              style={{
                height: `calc(100vh - 57px - ${navBlockHeight || 0}px)`,
                width: navBlockWidth + 'px',
              }}>
              <ArticleSectionNav
                article={article}
                articleSections={articleSections}
                handleChangeSearch={handleChangeSearch}
                searchValue={searchString}
              />
            </div>
          )}
          <ArticleSectionNavMobile
            article={article}
            articleSections={articleSections}
            handleChangeSearch={handleChangeSearch}
            searchValue={searchString}
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <div ref={contentBlockRef} className={'pt-x8 pb-x10'}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <ArticleHeaderBlock article={article} headerBlockRef={headerBlockRef} />
                {/*<ArticleNav article={article} isMobile />*/}
                <ArticleContent article={article} />
                <ArticleTaskList article={article} isMobile id={'task-list-mobile'} className={'mb-x8'} />
                <ArticlePagination article={article} />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={3} xl={3} className={'article-view-third-col'}>
          <div
            className={'article-view-nav'}
            style={{
              marginTop: `${headerBlockHeight + 32 + 16}px`,
            }}>
            {headerBlockHeight && (
              <>
                {/*<ArticleNav article={article} />*/}
                <ArticleTaskList article={article} id={'task-list-pc'} />
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export {ArticleView}
