import React, {useState} from 'react'
import {UserInfoForm} from './edit/UserInfoForm'
import {AuthForm} from './edit/AuthForm'
import {SocialForm} from './edit/SocialForm'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {AvatarEditor, Col, Container, createSuccessToast, createToast, Row} from 'ac_ui'
import {UserDto} from 'learndb-share'
import {CONFIG} from '../../constants'
import {authActions} from '../auth/redux/actions'
import {useRequest, UseRequestTypeEnum} from '../../hooks/useRequest'

const Preferences: React.FC = () => {
  const authDispatch = useDispatch()
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  const [request, isSendingRequest] = useRequest<{avatar: string}>({
    type: UseRequestTypeEnum.API_UPLOAD_TO_CDN,
  })

  const [isChangedImage, setIsChangedImage] = useState<boolean>(false)
  const [avatarFile, setAvatarFile] = useState<Blob | undefined>(undefined)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)

  const enableSubmit = (): void => {
    setIsSubmitDisabled(false)
  }

  const onSave = async (onSuccess: () => void): Promise<void> => {
    setIsSubmitDisabled(true)
    let newAvatarFileName

    try {
      if (isChangedImage) {
        if (avatarFile) {
          const fD = new FormData()
          fD.append('avatar', avatarFile, 'avatar.jpg')
          const fileMatch = await request({data: fD})
          newAvatarFileName = fileMatch?.avatar
        } else {
          newAvatarFileName = undefined
        }
      }
      if (newAvatarFileName) {
        authDispatch(
          authActions.saveUserAvatar(
            {
              ...user,
              avatarFileName: newAvatarFileName,
            },
            () => {
              createSuccessToast('Изменено')
            },
            enableSubmit,
          ),
        )
      }
      onSuccess()
    } catch (e) {
      createToast('Не удалось сохранить', {
        type: 'error',
      })
      enableSubmit()
    }
  }

  const onDelete = (onSuccess: () => void): void => {
    setIsSubmitDisabled(true)
    authDispatch(
      authActions.saveUserAvatar(
        {
          ...user,
          avatarFileName: null,
        },
        () => {
          createSuccessToast('Удалено')
          onSuccess()
          enableSubmit()
        },
        (error) => {
          createToast('Не удалось удалить', {
            type: 'error',
          })
          enableSubmit()
        },
      ),
    )
  }

  if (user) {
    return (
      <div className={'profile-body'}>
        <section className={'profile-view-header'}>
          <Container>
            <h1 className={'profile-view-header-h1'}>Настройки</h1>
          </Container>
        </section>
        <Container className={'profile-content'}>
          <div className={'profile-edit'}>
            <section className={'avatar-wrapper'}>
              <AvatarEditor
                onImageChange={(file) => {
                  setAvatarFile(file)
                  setIsChangedImage(true)
                }}
                imageUrl={user?.avatarFileName ? CONFIG.cdnWeb + '/' + user?.avatarFileName : undefined}
                className={'mb-x6'}
                onDelete={(onSuccess: () => void): void => {
                  onDelete(onSuccess)
                }}
                onSave={async (onSuccess: () => void) => {
                  await onSave(onSuccess)
                }}
                disabled={isSubmitDisabled}
              />
            </section>
            <Row className={'user-forms-wrapper'}>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <UserInfoForm user={user} />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <AuthForm email={user.email} />
                <SocialForm user={user} />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  } else {
    return null
  }
}

export {Preferences}
