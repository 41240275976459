import React, {useState} from 'react'
import {Button, Checkbox, createToast, FormFeedback, Modal, ModalBody, ModalFooter, ModalHeader} from 'ac_ui'
import {ResponseError} from 'ac-share'
import {paymentActions} from '../../../payment/redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import {useLoadPriceMap} from '../../../payment/hooks/useLoadPriceMap'
import {SubscriptionPeriod, SubscriptionPriceMapDto, UserDto} from 'learndb-share'
import {getCountStringCoins} from '../../utils'
import {authActions} from '../../../auth/redux/actions'
import {IReduxState} from '../../../../redux/types/IReduxState'
import {YmTargetEnum} from '../../../../metrics/ym/YmTargetEnum'
import {ymTarget} from '../../../../metrics/ym/ymTarget'

interface IPayCoinsModalProps {
  subscriptionPeriodIdProp?: SubscriptionPeriod
  onClose: () => void
}

const PayCoinsModal: React.FC<IPayCoinsModalProps> = ({subscriptionPeriodIdProp, onClose}): React.ReactElement => {
  const authDispatch = useDispatch()
  const paymentDispatch = useDispatch()
  const priceMap: SubscriptionPriceMapDto = useLoadPriceMap()

  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  if (priceMap) {
    if (!subscriptionPeriodIdProp && user.wallet.coins < priceMap[SubscriptionPeriod.YEAR].priceDiscount) {
      subscriptionPeriodIdProp = SubscriptionPeriod.MONTH
    }
  }

  const [isMonth, setIsMonth] = useState<boolean>(
    subscriptionPeriodIdProp ? subscriptionPeriodIdProp === SubscriptionPeriod.MONTH : true,
  )
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false)

  const subscriptionPeriodId: SubscriptionPeriod = isMonth ? SubscriptionPeriod.MONTH : SubscriptionPeriod.YEAR

  const [error, setError] = useState<ResponseError | undefined>(undefined)

  const onPayCoins = async (): Promise<void> => {
    setError(undefined)
    setIsSendingRequest(true)
    try {
      const summa = priceMap[subscriptionPeriodId].priceDiscount
      paymentDispatch(
        paymentActions.createPaymentCoins({summa, subscriptionPeriodId}, () => {
          authDispatch(authActions.check())
          createToast(
            <div className={'d-flex flex-row align-items-center'}>
              <div className={'d-flex align-self-start'}>
                <img src={`/img/emptyList/coolRobot.svg`} width={96} height={96} alt={'Крутой робот'} />
              </div>
              <div className={'d-flex flex-column justify-content-center ml-x2'}>
                <p className={'subscription-toast-content-name mb-0'}>Поздравляем, вы&nbsp;оформили подписку!</p>
              </div>
            </div>,
            {
              type: 'info',
              icon: false,
              closeOnClick: true,
            },
          )
          if (subscriptionPeriodId) {
            if (subscriptionPeriodId === SubscriptionPeriod.YEAR) {
              ymTarget(YmTargetEnum.PAY_COINS_YEARS)
            } else if (subscriptionPeriodId === SubscriptionPeriod.MONTH) {
              ymTarget(YmTargetEnum.PAY_COINS_MONTH)
            }
          }
          onClose()
        }),
      )
    } catch (e) {
      setError(e)
    } finally {
      setIsSendingRequest(false)
    }
  }

  if (!priceMap) {
    return <></>
  }

  return (
    <Modal isOpen={true} isUpdateBodyScroll={false} classNameContent={'w-100'} onClose={onClose} size={'sm'}>
      <ModalHeader isBorder>Оформление подписки</ModalHeader>
      <ModalBody>
        <div className={'d-flex flex-column align-items-start'}>
          <div className={'d-flex flex-column align-items-center w-100'}>
            <img
              src={'/img/subscription/subscription.svg'}
              className={'pay-coins-modal-img'}
              width={200}
              height={200}
              alt={'Картинка оформления подписки за монеты'}
            />
          </div>
          {subscriptionPeriodIdProp ? (
            <p className={'mb-0 text-center'}>
              Оформить подписку на {subscriptionPeriodIdProp === SubscriptionPeriod.MONTH ? 'месяц' : 'год'} за{' '}
              {priceMap[subscriptionPeriodIdProp].priceDiscount}{' '}
              {getCountStringCoins(priceMap[subscriptionPeriodIdProp].priceDiscount)}
            </p>
          ) : (
            <>
              <Checkbox
                id={'month'}
                name={'month'}
                label={`На месяц за ${priceMap[SubscriptionPeriod.MONTH].priceDiscount} ${getCountStringCoins(
                  priceMap[SubscriptionPeriod.MONTH].priceDiscount,
                )}`}
                checked={!!isMonth}
                type={'radio'}
                onChange={(checked) => {
                  setError(undefined)
                  setIsMonth(checked)
                }}
                color={'primary'}
                className={'mb-x4'}
              />
              <Checkbox
                disabled={user.wallet.coins < priceMap[SubscriptionPeriod.YEAR].priceDiscount}
                id={'year'}
                name={'year'}
                label={`На год за ${priceMap[SubscriptionPeriod.YEAR].priceDiscount} ${getCountStringCoins(
                  priceMap[SubscriptionPeriod.YEAR].priceDiscount,
                )}`}
                helpText={
                  user.wallet.coins < priceMap[SubscriptionPeriod.YEAR].priceDiscount
                    ? `Не хватает еще ${priceMap[SubscriptionPeriod.YEAR].priceDiscount - user.wallet.coins} монет`
                    : undefined
                }
                checked={!isMonth}
                type={'radio'}
                onChange={(checked) => {
                  setError(undefined)
                  setIsMonth(!checked)
                }}
                color={'primary'}
                className={'mb-0'}
              />
            </>
          )}
          <FormFeedback message={error?.errorMessage} isShow={!!error} type={'danger'} className={'mt-x4'} />
        </div>
      </ModalBody>
      <ModalFooter isBorder className={'w-100'}>
        <div className={'pay-coins-modal-footer'}>
          <Button style={'ghost'} onClick={onClose} disabled={isSendingRequest}>
            Отменить
          </Button>
          <Button
            onClick={async () => {
              await onPayCoins()
            }}
            disabled={isSendingRequest}>
            Оформить
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export {PayCoinsModal}
