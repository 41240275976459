import {constants} from '../../../redux/constants'
import {IAuthState} from './reducers'
import {UserDto} from 'learndb-share'

export const authActions = {
  confirmEmail: (token) => ({
    type: constants.GET_CONFIRM_EMAIL,
    uri: '/user/confirm/' + token,
  }),

  login: (email, password, onSuccess, onError) => ({
    type: constants.POST_LOGIN,
    uri: '/auth/token',
    data: {email, password},
    onSuccess,
    onError,
  }),

  logout: (onSuccess) => ({
    type: constants.GET_LOGOUT,
    uri: '/auth/logout',
    onSuccess,
  }),

  createUser: (user, onSuccess, onError) => ({
    type: constants.POST_USER,
    uri: '/user',
    data: user,
    onSuccess,
    onError,
  }),

  createAnonymousUser: (onSuccess, onError) => ({
    type: constants.POST_ANONYMOUS_USER,
    uri: '/user/anonymous',
    onSuccess,
    onError,
  }),

  saveUserInfo: (user: UserDto, onSuccess: () => void, onError: () => void) => ({
    type: constants.POST_SAVE_USER_INFO,
    uri: '/user/info',
    data: user,
    onSuccess,
    onError,
  }),

  saveUserSocial: (user: UserDto, onSuccess: () => void, onError: () => void) => ({
    type: constants.POST_USER_SOCIAL,
    uri: '/user/social',
    data: user,
    onSuccess,
    onError,
  }),

  saveUserSetting: (user: UserDto) => ({
    type: constants.POST_USER_SETTING,
    uri: '/user/setting',
    data: user,
  }),

  saveUserAvatar: (user: UserDto, onSuccess: () => void, onError: (error) => void) => ({
    type: constants.POST_USER_AVATAR,
    uri: '/user/avatar',
    data: user,
    onSuccess,
    onError,
  }),

  check: () => ({
    type: constants.GET_CHECK,
    uri: '/user/check',
  }),

  confirmCookie: () => ({
    type: constants.CONFIRMATION_COOKIE,
  }),

  setState: (state: IAuthState) => ({
    type: constants.SET_AUTH_STATE,
    state,
  }),
}
