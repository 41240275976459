import React, {useEffect, useState} from 'react'
import {Banner, COLORS} from 'ac_ui'
import {URLS} from '../../constants/urls'
import {useDispatch} from 'react-redux'
import {authActions} from '../auth/redux/actions'
import {getStorageData} from '../../../storage/methods'
import {LSKeysEnum} from '../../../storage/LSKeysEnum'
import {cookieSvg} from './utils'
import {MainInfo} from './components/MainInfo'
import {WhyStudy} from './components/WhyStudy'
import {ForWhom} from './components/ForWhom'
import {HowTrainingTakesPlace} from './components/HowTrainingTakesPlace'
import {WhatYouWillLearn} from './components/WhatYouWillLearn'
import {Certificate} from './components/Certificate'
import {Statistics} from './components/Statistics'
import {CompaniesStudentsWork} from './components/CompaniesStudentsWork'
import {Author} from './components/Author'
import {YouCanStart} from './components/YouCanStart'
import {QuestionsAndAnswers} from './components/QuestionsAndAnswers'
import {useLoadLanding} from './hooks/useLoadLanding'
import {useLoadPriceMap} from '../payment/hooks/useLoadPriceMap'

const Landing: React.FC = () => {
  const authDispatch = useDispatch()

  const landing = useLoadLanding()
  const priceMap = useLoadPriceMap()

  const [isConfirmCookie, setIsConfirmCookie] = useState<boolean>(true)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const item = getStorageData(LSKeysEnum.IS_CONFIRM_COOKIE)
      setIsConfirmCookie(item)
      window.scrollTo(0, 0)
    }
  }, [])

  const confirmCookie = (): void => {
    authDispatch(authActions.confirmCookie())
    setIsConfirmCookie(true)
  }

  return (
    <div>
      <MainInfo landing={landing} />
      <WhyStudy />
      <ForWhom />
      <HowTrainingTakesPlace landing={landing} />
      <WhatYouWillLearn landing={landing} />
      <Certificate />
      <Statistics landing={landing} />
      <CompaniesStudentsWork />
      <Author />
      <YouCanStart priceMap={priceMap} />
      <QuestionsAndAnswers landing={landing} priceMap={priceMap} />
      {!isConfirmCookie && (
        <div className={'landing-banner'}>
          <Banner
            buttonText={'Понятно'}
            color={'purple'}
            iconPath={cookieSvg}
            iconColor={COLORS.white}
            onClick={confirmCookie}>
            <p className={'landing-text-banner-header mb-x2'}>Файлы cookie</p>
            <span className={'landing-text-banner'}>
              Просматривая этот сайт, вы соглашаетесь с нашей
              <a href={URLS.legal + URLS.privacy} className={'landing-link-banner'}>
                {' '}
                политикой конфиденциальности
              </a>
            </span>
          </Banner>
        </div>
      )}
    </div>
  )
}

export {Landing}
