import React from 'react'
import {ButtonLink, Container} from 'ac_ui'

const WorkInProgressPage: React.FC = () => {
  return (
    <Container>
      <section className={'work-in-progress-page'}>
        <img src={'/img/emptyList/hourGlass.svg'} width={300} height={300} className={'image'} alt={'Песочные часы'} />
        <h1 className={'header'}>Страница в&nbsp;разработке…</h1>
        <ButtonLink href={'/'}>На главную</ButtonLink>
      </section>
    </Container>
  )
}

export {WorkInProgressPage}
