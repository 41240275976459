import React from 'react'
import classNames from 'classnames'
import {CircleProgressBar} from 'ac_ui'
import {getCountString} from 'ac-share'

interface IStatsCardProps {
  current: number
  max: number
  className?: string
  type: 'themes' | 'tasks' | 'achievements'
}

const StatsCard: React.FC<IStatsCardProps> = ({current, max, type, className}): React.ReactElement => {
  const css = classNames('stats-card', className)

  const getCenterContent = (): React.ReactElement => {
    let alt
    let src
    switch (type) {
      case 'tasks':
        {
          alt = 'Уроки'
          src = '/img/themes/statTasks.svg'
        }
        break
      case 'themes':
        {
          alt = 'Темы'
          src = '/img/themes/statThemes.svg'
        }
        break
      case 'achievements':
        {
          alt = 'Достижения'
          src = '/img/themes/statAchievements.svg'
        }
        break
    }
    return <img src={src} width={52} height={52} alt={alt} />
  }

  const getMax = (): string => {
    switch (type) {
      case 'tasks': {
        return `из ${max} ${getCountString(max, 'урок', 'урока', 'уроков')}`
      }
      case 'themes': {
        return `из ${max} ${getCountString(max, 'темы', 'тем', 'тем')}`
      }
      case 'achievements': {
        return `из ${max} ${getCountString(max, 'достижения', 'достижений', 'достижений')}`
      }
    }
  }

  return (
    <div className={css}>
      <CircleProgressBar current={current} max={max} centerContent={getCenterContent()} />
      <div className={'stats-card-info'}>
        <p className={'stats-card-current'}>{current}</p>
        <p className={'stats-card-max'}>{getMax()}</p>
      </div>
    </div>
  )
}

export {StatsCard}
