import {constants} from '../../../redux/constants'

export interface ILegalState {
  license?: string | undefined
  privacy?: string | undefined
  offer?: string | undefined
  offerLegal?: string | undefined
}

const INITIAL_STATE: ILegalState = {
  license: undefined,
  privacy: undefined,
  offer: undefined,
  offerLegal: undefined,
}

function legalReducers(state: ILegalState = INITIAL_STATE, action): ILegalState {
  switch (action.type) {
    case constants.SET_LEGAL_STATE: {
      return {...state, ...action.state}
    }

    default:
      return state
  }
}

export {legalReducers}
