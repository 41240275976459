import React from 'react'
import {Container} from 'ac_ui'

const WhyStudy: React.FC = () => {
  return (
    <section className={'landing-why-study'}>
      <Container className={'landing-why-study-container'}>
        <h2 className={'landing-h2 why-study-h2'}>Зачем изучать SQL</h2>
        <p className={'why-study-p'}>
          Понимание языка запросов SQL необходимо всем, кто&nbsp;работает с&nbsp;большими объемами данных. <br />
          Каждое серьезное собеседование у&nbsp;IT-специалистов содержит вопросы о&nbsp;базах данных и&nbsp;SQL.
        </p>
      </Container>
    </section>
  )
}

export {WhyStudy}
