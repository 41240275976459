import React, {useEffect, useState} from 'react'
import {ArticleDto, SectionArticlesDto} from 'learndb-share'
import {StringBooleanMap} from 'ac-share'
import {addBodyScroll, removeBodyScroll, useIsomorphicLayoutEffect} from 'ac_ui'
import {ArticleSectionNavMobileModal} from './components/ArticleSectionNavMobileModal'
import {SectionNavOpenButton} from './components/SectionNavOpenButton'

interface IArticleSectionNavMobileProps {
  article: ArticleDto
  articleSections: SectionArticlesDto[]
  handleChangeSearch: (value: string) => void
  searchValue: string
}

const ArticleSectionNavMobile: React.FC<IArticleSectionNavMobileProps> = ({
  article,
  articleSections,
  handleChangeSearch,
  searchValue,
}): React.ReactElement => {
  const idPrefix = 'mobile'
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [collapseMap, setCollapseMap] = useState<StringBooleanMap>({})

  useEffect(() => {
    collapseMap[article.sectionId] = true
    setCollapseMap({...collapseMap})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openModal = (): void => {
    setIsOpen(true)
    removeBodyScroll()
  }

  const closeModal = (): void => {
    addBodyScroll()
    setIsOpen(false)
  }

  useIsomorphicLayoutEffect(() => {
    if (isOpen && article.articleId) {
      const element = document.getElementById(`list-item-${idPrefix}-${article.articleId}`)
      element.scrollIntoView({behavior: 'smooth'})
    }
  }, [isOpen])

  return (
    <div>
      <SectionNavOpenButton
        isCloseButton={isOpen}
        onClick={() => {
          if (isOpen) {
            closeModal()
          } else {
            openModal()
          }
        }}
      />
      {isOpen && (
        <ArticleSectionNavMobileModal
          idPrefix={idPrefix}
          isOpen={isOpen}
          closeModal={closeModal}
          collapseMap={collapseMap}
          setCollapseMap={setCollapseMap}
          article={article}
          articleSections={articleSections}
          handleChangeSearch={handleChangeSearch}
          searchValue={searchValue}
        />
      )}
    </div>
  )
}

export {ArticleSectionNavMobile}
