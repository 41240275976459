import React from 'react'
import {
  Button,
  ButtonIcon,
  Col,
  Divider,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiLink,
  Row,
} from 'ac_ui'
import {EmptyList} from '../../common/EmptyList'
import {acTelegramCircle, acVkCircle} from 'ac-icon'
import {URLS} from '../../../constants/urls'
import {FormController} from '../../common/formController/FormController'
import {ControllerCommonProps} from '../../common/formController/components/ControllerInput'
import {IFeedbackFormValues} from '../FeedbackFormModal'
import {FeedbackThemeDto, ResponseError} from 'learndb-share'
import {ymTarget} from '../../../metrics/ym/ymTarget'
import {YmTargetEnum} from '../../../metrics/ym/YmTargetEnum'

interface IFeedbackFormProps {
  submitError: ResponseError | undefined
  feedbackThemes: FeedbackThemeDto[]
  common: ControllerCommonProps<IFeedbackFormValues>
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onClose: () => void
  isSubmitRequest: boolean
  isSubmitSuccess: boolean
}

const FeedbackForm: React.FC<IFeedbackFormProps> = ({
  submitError,
  feedbackThemes,
  common,
  onSubmit,
  onClose,
  isSubmitRequest,
  isSubmitSuccess,
}): React.ReactElement => {
  return (
    <Modal
      isOpen={true}
      onClose={() => {
        if (!isSubmitRequest && onClose) {
          onClose()
        }
      }}
      isUpdateBodyScroll={false}
      closeOnEscape={false}
      size={'md'}
      onSubmit={onSubmit}>
      {!isSubmitSuccess && <ModalHeader isBorder>Обращение в поддержку</ModalHeader>}
      <ModalBody>
        {isSubmitSuccess ? (
          <div className={'d-flex flex-column align-items-center w-100 min-width-100'}>
            <EmptyList
              type={'CAT_MAIL'}
              title={
                <>
                  Спасибо за обратную связь!
                  <br />
                  Ожидайте ответ на почте.
                </>
              }
            />
          </div>
        ) : (
          <div className={'d-flex flex-column w-100'}>
            <p className={'feedback-description'}>
              Если у вас вопросы по заданиям, то лучше обратитесь в чат в телеграмме или в сообщество вконтакте. Так вы
              быстрее получите ответы.
            </p>
            <div className={'d-flex flex-row'}>
              <ButtonIcon
                iconPath={acTelegramCircle}
                color={'primary'}
                title={'Телеграм'}
                href={URLS.socialTelegramChat}
                target={'_blank'}
                className={'mr-x2'}
                onClickLink={() => {
                  ymTarget(YmTargetEnum.TELEGRAM_CHAT)
                }}
              />
              <ButtonIcon
                iconPath={acVkCircle}
                color={'primary'}
                title={'ВКонтакте'}
                href={URLS.socialVk}
                target={'_blank'}
                className={'mr-0'}
                onClickLink={() => {
                  ymTarget(YmTargetEnum.VK_CHANNEL)
                }}
              />
            </div>
            <Divider className={'my-x6'} />
            <h4 className={'feedback-title'}>Письмо на support@learndb.ru</h4>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                attribute={{
                  id: 'feedback-name',
                }}>
                <FormController.Input name={'name'} common={common} label={'Имя'} required className={'mb-x6'} />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                attribute={{
                  id: 'feedback-email',
                }}>
                <FormController.Input name={'email'} common={common} label={'Email'} required className={'mb-x6'} />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                attribute={{
                  id: 'feedback-feedbackThemeId',
                }}>
                <FormController.Select
                  name={'feedbackThemeId'}
                  common={common}
                  label={'Тема'}
                  options={feedbackThemes.map((f) => {
                    return {
                      label: f.name,
                      value: f.feedbackThemeId,
                    }
                  })}
                  menuPosition={'fixed'}
                  required
                  className={'mb-x6'}
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                attribute={{
                  id: 'feedback-message',
                }}>
                <FormController.TextArea
                  name={'message'}
                  common={common}
                  label={'Сообщение'}
                  required
                  className={'mb-x6'}
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                attribute={{
                  id: 'feedback-isAgreement',
                }}>
                <FormController.Checkbox
                  name={'isAgreement'}
                  common={common}
                  label={
                    <span>
                      Соглашаюсь с{' '}
                      <MultiLink href={URLS.legal + URLS.license} type={'href'}>
                        пользовательским соглашением
                      </MultiLink>{' '}
                      и{' '}
                      <MultiLink href={URLS.legal + URLS.privacy} type={'href'}>
                        политикой конфиденциальности
                      </MultiLink>
                    </span>
                  }
                  required
                  className={'mb-0'}
                />
              </Col>
            </Row>
            {submitError && (
              <FormFeedback
                key={'submitError'}
                message={submitError.errorMessage}
                type={'danger'}
                isShow={true}
                className={'mt-x6 w-100'}
              />
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter isBorder={!isSubmitSuccess} className={'feedback-buttons'}>
        {isSubmitSuccess ? (
          <Button
            type={'button'}
            className={'feedback-buttons-width'}
            onClick={() => {
              onClose()
            }}>
            Хорошо
          </Button>
        ) : (
          <>
            <Button
              style={'ghost'}
              type={'button'}
              disabled={isSubmitRequest}
              className={'feedback-buttons-width mr-x4'}
              onClick={() => {
                onClose()
              }}>
              Отменить
            </Button>
            <Button
              className={'feedback-buttons-width f-mb'}
              type={'submit'}
              disabled={isSubmitRequest}
              isLoading={isSubmitRequest}>
              Отправить
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  )
}

export {FeedbackForm}
