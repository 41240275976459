import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {COUPON_ASSIGN_SEARCH_PARAM} from '../../../constants'
import {LSKeysEnum} from '../../../../storage/LSKeysEnum'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {AddCouponDto, UserDto} from 'learndb-share'
import {apiPost} from '../../../api'

function getCouponSyncArray(): string[] {
  const lsSyncArray: string = localStorage.getItem(LSKeysEnum.COUPONS_SYNC_QUEUE)
  try {
    return lsSyncArray ? JSON.parse(lsSyncArray) ?? [] : []
  } catch (e) {
    console.error('Error parse COUPONS_SYNC_QUEUE', e)
    return []
  }
}

function setCouponSyncArray(couponSyncArray: string[]): void {
  localStorage.setItem(LSKeysEnum.COUPONS_SYNC_QUEUE, JSON.stringify(couponSyncArray))
}

async function syncCouponArray(): Promise<void> {
  const coupons: string[] = getCouponSyncArray()
  for (const coupon of coupons) {
    console.log('sync coupon', coupon)
    try {
      const payload: AddCouponDto = {couponCode: coupon}
      await apiPost('/coupon/add', payload)
    } catch (e) {
      console.error('Error sync coupon', coupon, e)
    }
  }
  setCouponSyncArray([])
}

/**
 * Отслеживает появление купона в адресе (переходы по маркетинговым скидкам)
 */
const useCouponWatcher = (): void => {
  const location = useLocation()
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  useEffect(() => {
    const coupon = new URLSearchParams(location.search).get(COUPON_ASSIGN_SEARCH_PARAM)
    if (coupon) {
      let couponSyncArray: string[] = getCouponSyncArray()
      if (!couponSyncArray.includes(coupon)) {
        couponSyncArray.push(coupon)
        setCouponSyncArray(couponSyncArray)
      }
    }
    if (user) {
      syncCouponArray()
    }
  }, [location, user])
}

export {useCouponWatcher}
