import React, {useState} from 'react'
import {ThreeCoinsSvg} from './ThreeCoinsSvg'
import {Button, ButtonLink, CoinSvg, Divider, MultiLink} from 'ac_ui'
import {getCountString} from 'ac-share'
import {URLS} from '../../../../constants/urls'
import classNames from 'classnames'
import {useLoadPriceMap} from '../../../payment/hooks/useLoadPriceMap'
import {SubscriptionPeriod, UserDto} from 'learndb-share'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../../../redux/types/IReduxState'
import {CoinsHistoryModal} from './CoinsHistoryModal'
import {PayCoinsModal} from './PayCoinsModal'
import {CoinsCounter} from '../../../header/CoinsCounter'
import {useLocation} from 'react-router-dom'

interface ICoinsCardProps {
  className?: string
  classNameCard?: string
}

const CoinsCard: React.FC<ICoinsCardProps> = ({className, classNameCard}): React.ReactElement => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const location = useLocation()

  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)
  const userAnonymous = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.userAnonymous)
  const isAuth = !!user
  const priceMap = useLoadPriceMap()
  let priceMonth

  if (priceMap) {
    priceMonth = priceMap[SubscriptionPeriod.MONTH]
  }
  let coins = 0
  if (isAuth) {
    coins = user ? user.wallet?.coins || 0 : 0
  } else if (userAnonymous) {
    coins = userAnonymous ? userAnonymous.wallet?.coins || 0 : 0
  }
  let missingCoins
  if (priceMonth && priceMonth.priceDiscount > coins) {
    missingCoins = priceMonth.priceDiscount - coins
  }

  const css = classNames('coins-card-wrapper', className)
  const cssCard = classNames('coins-card', classNameCard)

  const isMissingCoins = missingCoins > 0
  const cardContent: React.ReactElement = (
    <>
      <ThreeCoinsSvg className={'coins-card-bg'} />
      <p className={'coins-card-header mb-x1'}>Монеты</p>
      <p className={'coins-card-conversion mb-x3'}>1 монета = 1 &#8381;</p>
      <div className={'d-flex flex-row align-items-center'}>
        <div className={'d-flex'} title={'Монеты'}>
          <CoinSvg id={'coinsCard'} height={32} width={32} />
        </div>
        <p className={'coins-card-coins mb-0'}>
          <CoinsCounter />
        </p>
      </div>
      {missingCoins > 0 ? (
        <div>
          <Divider className={'mb-x2 mt-x2'} />
          <p className={'coins-card-missing mb-0'}>
            Для оплаты подписки не&nbsp;хватает {missingCoins}{' '}
            {getCountString(missingCoins, 'монеты', 'монет', 'монет')}
          </p>
        </div>
      ) : isAuth ? (
        <Button
          color={'gradient'}
          size={'sm'}
          className={'w-100 mt-x3'}
          onClick={() => {
            if (!isOpenModal) {
              setIsOpenModal(true)
            }
          }}>
          Использовать
        </Button>
      ) : (
        <ButtonLink color={'gradient'} size={'sm'} className={'w-100 mt-x3'} href={URLS.signin}>
          Использовать
        </ButtonLink>
      )}
    </>
  )
  let content: React.ReactElement
  const href = isAuth ? URLS.profile + URLS.coins : URLS.coins
  if (isMissingCoins && location.pathname !== href) {
    content = (
      <MultiLink href={href} type={'href'} className={classNames(cssCard, 'text-decoration-none-i')}>
        {cardContent}
      </MultiLink>
    )
  } else {
    content = <div className={cssCard}>{cardContent}</div>
  }

  return (
    <div className={css}>
      {content}
      {isAuth && <CoinsHistoryModal />}
      {isAuth && isOpenModal && (
        <PayCoinsModal
          onClose={() => {
            setIsOpenModal(false)
          }}
        />
      )}
    </div>
  )
}

export {CoinsCard}
