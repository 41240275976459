import React from 'react'
import {SubscriptionPeriod, SubscriptionPriceMapDto} from 'learndb-share'
import {getYearEconomy} from './utils'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {FeedbackButton} from '../feedback/components/FeedbackButton'
import {PaymentOtherUserForm} from './components/PaymentOtherUserForm'
import {QuestionBlock} from '../common/questionBlock/QuestionBlock'
import {QuestionAnswer} from '../../types/question/QuestionAnswer'

const QuestionsPaymentAndSubscription: React.FC = () => {
  const subscriptionPriceMap = useSelector<IReduxState, SubscriptionPriceMapDto | undefined>(
    (state) => state.payment?.subscriptionPriceMap,
  )

  if (!subscriptionPriceMap) {
    // Идет загрузка данных
    return null
  }
  const priceMonth = subscriptionPriceMap[SubscriptionPeriod.MONTH]
  const priceYear = subscriptionPriceMap[SubscriptionPeriod.YEAR]
  const questions: QuestionAnswer[] = [
    {
      question: 'В чем отличие тарифных планов?',
      answer: (
        <span>
          Разница в периодах оплаты: ежемесячный и ежегодный, так же при оплате один раз за год получается дешевле на{' '}
          {getYearEconomy(priceMonth, priceYear)} рублей, нежели оплачивать ежемесячно в течении 1 года.
        </span>
      ),
    },
    {
      question: 'Какие есть способы оплаты подписки?',
      answer: (
        <span>
          Оплачивать можно банковскими картами Visa, Mastercard, Maestro, Мир. Так же доступна оплата через ЮMoney.
        </span>
      ),
    },
    {
      question: 'Проблемы с оплатой?',
      answer: (
        <span>
          Если ваша карта оформлена не в России, вы можете оплатить:
          <br />
          с карт МИР, которые выпущены в следующих странах: Беларусь, Южная Осетия, Узбекистан, Таджикистан и Армения.
          <br />
          Так же кошельком ЮMoney, его можно пополнить в большинстве стран СНГ— выбрать удобный вариант пополнения вы
          можете в разделе Помощь (
          <a href={'https://yoomoney.ru/page?id=535675#card'}>https://yoomoney.ru/page?id=535675#card</a>).
        </span>
      ),
    },
    {
      question: 'Есть ли автопродление подписки?',
      answer: (
        <span>
          Автопродления нет, вы сами решаете когда снова приступить к обучению. Подписку нужно продлевать по окончанию
          оплаченного периода.
        </span>
      ),
    },
    {
      question: 'Когда откроется доступ к платным курсам?',
      answer: (
        <span>
          После того как произойдет зачисления денежных средств, это может занять от нескольких минут до нескольких
          часов. Обычно это занимает пару минут.
        </span>
      ),
    },
    {
      question: 'Что будет с моим профилем если я решу сделать перерыв в обучении и не заплачу за подписку?',
      answer: (
        <span>
          Всё будет в порядке. Весь прогресс сохранится и вы сможете вернуться к обучению сразу после оформления
          подписки.
        </span>
      ),
    },
    {
      question:
        'Если я решил прервать подписку, но средства уже списались и доступ уже предоставили, ' +
        'могу я отказаться от подписки и вернуть деньги?',
      answer: (
        <span>
          Если подписка уже активирована, то прервать её нет возможности. Подписка будет активна до конца оплаченного
          периода.
        </span>
      ),
    },
    {
      question: 'Будут ли доступны пройденные курсы после окончания подписки?',
      answer: (
        <span>
          Нет, не будут. Но ваш прогресс прохождения и статистика сохранятся, и вы сможете снова приступить к обучению
          только после оформления подписки.
        </span>
      ),
    },
    {
      question: 'Как оплатить от организации?',
      answer: (
        <span>
          Напишите нам на <a href={'mailto:support@learndb.ru'}>support@learndb.ru</a>. В ответном письме вы получите
          всю необходимую информацию.
        </span>
      ),
    },
    {
      question: 'Я могу купить подписку другому человеку?',
      answer: (
        <div>
          <p className={'mb-x4'}>
            Да. Пользователь должен быть зарегистрирован на платформе. В форму ниже укажите почту, которую он указал при
            регистрации, выберите период и нажмите на кнопку «Оформить подписку».
          </p>
          <PaymentOtherUserForm />
        </div>
      ),
    },
    {
      question: 'Остались вопросы?',
      answer: (
        <div className={'w-100 d-flex flex-column align-items-start '}>
          <span>
            Воспользуйтесь кнопкой ниже или напишите нам на <a href={'mailto:support@learndb.ru'}>support@learndb.ru</a>
          </span>
          <FeedbackButton className={'mt-x4'} />
        </div>
      ),
      isOpenInitial: true,
    },
  ]

  return (
    <QuestionBlock
      questions={questions}
      header={'Частые вопросы'}
      css={{
        wrapper: 'payment-question-section',
        container: 'py-0',
        h2: 'payment-question-header',
      }}
    />
  )
}

export {QuestionsPaymentAndSubscription}
