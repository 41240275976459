import React from 'react'
import {Provider} from 'react-redux'
import {CommonLayout} from './component/CommonLayout'
import {createAppStore} from './redux/store'
import {createSocket} from './socket'
import {ToastContainer} from 'ac_ui'
import {SocketObserver} from './component/socket/SocketObserve'
import {FeedbackFormModalWrapper} from './component/feedback/FeedbackFormModalWrapper'

declare let __STORE__: any

const store = createAppStore(__STORE__)

global.socket = createSocket()

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <CommonLayout />
      <SocketObserver />
      <ToastContainer />
      <FeedbackFormModalWrapper />
    </Provider>
  )
}

export default App
