import React from 'react'
import {FeedbackFormModal} from './FeedbackFormModal'
import {feedbackActions} from './redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'

const FeedbackFormModalWrapper: React.FC = (): React.ReactElement => {
  const feedbackDispatch = useDispatch()
  const isOpenFeedback = useSelector<IReduxState, boolean>((state) => state.feedback?.isOpenModal)

  if (isOpenFeedback) {
    return (
      <FeedbackFormModal
        onClose={() => {
          feedbackDispatch(
            feedbackActions.setState({
              isOpenModal: false,
            }),
          )
        }}
      />
    )
  } else {
    return <></>
  }
}

export {FeedbackFormModalWrapper}
