import React from 'react'
import {SearchPanel} from '../search/SearchPanel'
import {ThemeItem} from '../components/ThemeItem'
import {IFilters} from '../domain/IFilters'
import {ThemesStats} from '../components/ThemesStats'
import {Container, findElementPositionY} from 'ac_ui'
import {CourseDto, TaskInfoDto, UserDto} from 'learndb-share'
import {CourseInfo} from '../components/CourseInfo'
import {getFilterThemes, isThemeCompleted} from '../utils'
import {ButtonTask} from '../../common/buttonTask/ButtonTask'

interface IThemesListProps {
  course: CourseDto
  filters: IFilters
  nextTask: TaskInfoDto
  user: UserDto
}

const ThemesList: React.FC<IThemesListProps> = ({course, filters, nextTask, user}) => {
  const themes = course.themes

  if (themes) {
    const filteredThemes = getFilterThemes(themes, filters)
    const items = []
    for (let i = 0; i < filteredThemes.length; i++) {
      let isPreviousComplete = false
      let isPreviousExists = false
      if (i > 0) {
        isPreviousExists = true
        isPreviousComplete = isThemeCompleted(filteredThemes[i - 1])
      }
      items.push(
        <li key={filteredThemes[i].themeId}>
          <ThemeItem theme={filteredThemes[i]} isPreviousCompleted={isPreviousComplete} />
        </li>,
      )
    }
    let lastTheme = null
    if (filteredThemes.length) {
      lastTheme = filteredThemes[filteredThemes.length - 1]
    }
    items.push(
      <li key={'stats'}>
        <ThemesStats
          themes={themes}
          isPreviousCompleted={lastTheme ? isThemeCompleted(lastTheme) : false}
          isPreviousExists={false}
          isShowIndicator={true}
        />
      </li>,
    )

    const scrollToThemes = (): void => {
      const element = document.getElementById('courseProgram')
      const positionY: number = findElementPositionY(element)
      const headerHeight = 56
      window.scrollTo({top: positionY - headerHeight, left: 0, behavior: 'smooth'})
    }

    return (
      <div className={'course-list-wrapper'}>
        <section className={'course-list'}>
          <Container className={'course-list-container'}>
            <div className={'d-flex flex-column'}>
              <h1 className={'course-h1'}>{course.name}</h1>
              <p className={'course-description mb-x6'}>Курс с практическими заданиями по SQL в СУБД PostgreSQL</p>
            </div>
            {nextTask && <ButtonTask id={'current-task'} task={nextTask} name={'Текущий урок'} className={'mb-x4'} />}
          </Container>
        </section>
        <Container>
          <CourseInfo isProAccount={user && user.isProAccount} course={course} scrollToThemes={scrollToThemes} />
          <h2 className={'course-h2 mb-0'} id={'courseProgram'}>
            Программа курса
          </h2>
        </Container>
        <SearchPanel />
        <Container>
          <ul className={'course-themes'}>{items}</ul>
        </Container>
      </div>
    )
  } else {
    return null
  }
}

export {ThemesList}
