import React, {ReactNode} from 'react'
import {UseFormStateReturn} from 'react-hook-form'
import delve from 'dlv'
import {isEmptyObject} from 'ac-share'
import {FormFeedback} from 'ac_ui'

function getErrorsControllerInput<T>(
  formState: UseFormStateReturn<T>,
  fieldName: string,
  isFormFeedBack: boolean,
): ReactNode[] | string[] {
  const errorItems: ReactNode[] = []
  const errorObject = delve(formState.errors, fieldName)

  if (!!errorObject && !isEmptyObject(errorObject)) {
    for (const key in errorObject.types) {
      const message: string = errorObject.types[key] as any
      if (isFormFeedBack) {
        errorItems.push(
          <FormFeedback key={message} message={message} type={'danger'} isShow={true} className={'mt-x2 w-100'} />,
        )
      } else {
        errorItems.push(<p className={'mt-x2 text-danger'}>{message}</p>)
      }
    }
  }
  return errorItems
}

export {getErrorsControllerInput}
