import React from 'react'
import {Modal, ModalBody, ModalHeader} from 'ac_ui'
import {SearchPanelCommon} from '../../../../../../common/SearchPanelCommon'
import {ArticleDto, SectionArticlesDto} from 'learndb-share'
import {ArticleSection} from '../../../../list/components/ArticleSection'
import {StringBooleanMap} from 'ac-share'
import {EmptyList} from '../../../../../../common/EmptyList'

interface IArticleSectionNavMobileModalProps {
  idPrefix: string
  isOpen: boolean
  closeModal: () => void
  collapseMap: StringBooleanMap
  setCollapseMap: (map: StringBooleanMap) => void
  article: ArticleDto
  articleSections: SectionArticlesDto[]
  handleChangeSearch: (value: string) => void
  searchValue: string
}

const ArticleSectionNavMobileModal: React.FC<IArticleSectionNavMobileModalProps> = ({
  idPrefix,
  isOpen,
  closeModal,
  collapseMap,
  setCollapseMap,
  article,
  articleSections,
  handleChangeSearch,
  searchValue,
}): React.ReactElement => {
  const renderList = (): React.ReactNode => {
    if (articleSections && articleSections.length > 0) {
      const items: React.ReactNode = articleSections.map((articleSection) => (
        <li
          key={articleSection.sectionId + articleSection.sectionName + idPrefix}
          className={'article-section-nav-mobile-list-item'}>
          <ArticleSection
            idPrefix={idPrefix}
            articleSection={articleSection}
            sectionNumber={articleSection.number}
            collapseMap={collapseMap}
            setCollapseMap={setCollapseMap}
            activeArticleId={article.articleId}
            withBorderBottom
            onClickHref={closeModal}
            isInnerArticle={true}
          />
        </li>
      ))
      return <ul className={'article-section-nav-mobile-list'}>{items}</ul>
    } else {
      return (
        <EmptyList
          type={'NOT_FOUND'}
          title={'Ничего не найдено'}
          description={'Попробуйте поискать по другим ключевым словам'}
          isMobileImg
          className={'pt-x6'}
        />
      )
    }
  }

  const renderSearchBlock = (): React.ReactElement => {
    return (
      <div className={'w-100 d-flex justify-content-start'}>
        <SearchPanelCommon // TODO:: Ещё будет доработан +
          handleChangeString={handleChangeSearch}
          searchId={'article-mobile-search'}
          value={searchValue}
          className={'article-section-nav-mobile-search mb-0'}
          classNameInput={'w-100'}
        />
      </div>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      closeOnEscape={false}
      className={'p-0 ac-modal-mobile'}
      isUpdateBodyScroll
      classNameContent={'article-section-nav-mobile-modal'}>
      <div className={'article-section-nav-mobile-modal-wrapper'}>
        <ModalHeader isBorder={false} className={'article-section-nav-mobile-modal-header'}>
          {renderSearchBlock()}
        </ModalHeader>
        <ModalBody
          isScroll={true}
          isBorder={false}
          isPadding={false}
          className={'article-section-nav-mobile-modal-body'}>
          <div className={'article-section-nav-mobile-modal-content'}>{renderList()}</div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export {ArticleSectionNavMobileModal}
