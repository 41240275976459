import React, {useEffect} from 'react'
import {isServer} from 'ac-share'
import {useLoadLegal} from './hooks/useLoadLegal'
import {HtmlView, Loading} from 'ac_ui'

const Privacy: React.FC = () => {
  const privacy = useLoadLegal('privacy')

  useEffect(() => {
    if (!isServer) {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <div className={'legal-content'}>
      {privacy ? (
        <HtmlView html={privacy} />
      ) : (
        <div className={'d-flex flex-column align-items-center w-100 pt-x4'}>
          <Loading />
        </div>
      )}
    </div>
  )
}

export {Privacy}
