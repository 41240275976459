import React from 'react'
import {Link, Route, Routes} from 'react-router-dom'
import {URLS} from '../../constants/urls'
import {License} from './License'
import {Privacy} from './Privacy'
import {OfferPhysical} from './OfferPhysical'
import {OfferLegal} from './OfferLegal'
import {NotFoundNavigator} from '../notFound/NotFoundNavigator'
import {ILayoutProps} from '../Layout'

const LegalLayout: React.FC<ILayoutProps> = ({staticContext}) => {
  return (
    <div className={'legal-container'}>
      <div className={'legal-layout'}>
        <div className={'legal-menu'}>
          <Link to={URLS.legal + URLS.license}>Пользовательское соглашение</Link>
          <Link to={URLS.legal + URLS.privacy}>Политика конфиденциальности</Link>
          <Link to={URLS.legal + URLS.offer}>Оферта для физических лиц</Link>
          <Link to={URLS.legal + URLS.offerLegal}>Оферта для юридических лиц и ИП</Link>
        </div>
        <div className={'legal-content-container'}>
          <Routes>
            <Route path={URLS.license} element={<License />} />
            <Route path={URLS.privacy} element={<Privacy />} />
            <Route path={URLS.offer} element={<OfferPhysical />} />
            <Route path={URLS.offerLegal} element={<OfferLegal />} />
            <Route path={'*'} element={<NotFoundNavigator staticContext={staticContext} />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export {LegalLayout}
