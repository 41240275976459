import React from 'react'
import classNames from 'classnames'
import {AchievementDto} from 'learndb-share'
import {CoinSvg} from 'ac_ui'
import {CONFIG} from '../../../../constants'

interface IAchievementCardProps {
  achievement: AchievementDto
  className?: string
}

const AchievementCard: React.FC<IAchievementCardProps> = ({achievement, className}): React.ReactElement => {
  const css = classNames(
    'achievement-card',
    {
      completed: !!achievement.userAchievement,
    },
    className,
  )
  return (
    <div className={css}>
      <img
        src={
          achievement.userAchievement
            ? CONFIG.cdnWeb + '/achievement/' + achievement.imageFileName
            : '/img/achievement/default.svg'
        }
        className={'achievement-card-img'}
        width={120}
        height={120}
        alt={achievement.name}
      />
      <div className={'achievement-card-info'}>
        <p className={'achievement-card-info-header'}>{achievement.name}</p>
        <p className={'achievement-card-info-description'}>{achievement.description}</p>
        <div className={'achievement-card-coins'}>
          {achievement.coins > 0 && (
            <div className={'achievement-card-coins-default'}>
              <span title={'Монеты'}>
                <CoinSvg id={'coin' + achievement.achievementId} />
              </span>
              {achievement.userAchievement ? (
                <p className={'mb-0 ml-x1'}>{achievement.userAchievement.coins}</p>
              ) : (
                <p className={'mb-0 ml-x1'}>{achievement.coins}</p>
              )}
            </div>
          )}
          {achievement.additionalCoins > 0 && !achievement.userAchievement && (
            <div className={'achievement-card-coins-additional ml-x1'}>
              {achievement.coins > 0 && (
                <p className={'d-flex flex-row align-items-center mb-0'}>
                  +{achievement.additionalCoins}{' '}
                  <span className={'achievement-card-coins-additional-signature '}>с подпиской</span>
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {AchievementCard}
