import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {ArticleViewContainer} from '../view/ArticleViewContainer'
import {ArticleListContainer} from '../list/ArticleListContainer'
import {ILayoutProps} from '../../../Layout'
import {NotFoundNavigator} from '../../../notFound/NotFoundNavigator'

const ArticlesLayout: React.FC<ILayoutProps> = ({staticContext}) => {
  return (
    <Routes>
      <Route path={'/'} element={<ArticleListContainer />} />
      <Route path={'/article/:articleId'} element={<ArticleViewContainer />} />
      <Route path={'*'} element={<NotFoundNavigator staticContext={staticContext} />} />
    </Routes>
  )
}

export {ArticlesLayout}
