import React from 'react'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {UserDto} from 'learndb-share'
import {CertificateContainer} from '../certificate/view/CertificateContainer'

const CertificatePage: React.FC = () => {
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)
  if (!user) {
    return null
  } else {
    return <CertificateContainer certificateId={user.certificateId} isShowQuestions />
  }
}

export {CertificatePage}
