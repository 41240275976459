import React from 'react'
import {Col, Container, Row} from 'ac_ui'
import {CoinsCard} from '../components/CoinsCard'
import {AchievementBlock} from '../../../achievement/components/components/AchievementBlock'
import {useLoadPriceMap} from '../../../payment/hooks/useLoadPriceMap'
import {SubscriptionPeriod} from 'learndb-share'
import {QuestionAnswer} from '../../../../types/question/QuestionAnswer'
import {FeedbackButton} from '../../../feedback/components/FeedbackButton'
import {QuestionBlock} from '../../../common/questionBlock/QuestionBlock'

const CoinsView: React.FC = () => {
  const priceMap = useLoadPriceMap()
  const priceMonth = priceMap ? priceMap[SubscriptionPeriod.MONTH] : undefined
  const priceYear = priceMap ? priceMap[SubscriptionPeriod.YEAR] : undefined
  const questions: QuestionAnswer[] = [
    {
      question: 'На что тратить монеты?',
      answer: (
        <span>
          Сейчас монеты можно потратить только на оплату подписки, в дальнейшем мы планируем расширять список услуг. Для
          использования монет выберите тарифный план на странице подписки и нажмите «Оформить».
        </span>
      ),
    },
    {
      question: 'Могу я оплатить часть подписки монетами, а часть за рубли?',
      answer: (
        <span>
          Нет. Вам нужно заработать количество монет равное стоимости подписки. Например, чтобы оплатить месяц
          необходимо минимум {priceMonth?.priceDiscount || '-'} монет, а для годовой подписки{' '}
          {priceYear?.priceDiscount || '-'} монет.
        </span>
      ),
    },
    {
      question: 'Монеты сгорают?',
      answer: <span>Нет. Монеты действуют бессрочно. Их можно потратить в любой момент.</span>,
    },
    {
      question: 'Остались вопросы?',
      answer: (
        <div className={'w-100 d-flex flex-column align-items-start '}>
          <span>
            Воспользуйтесь кнопкой ниже или напишите нам на <a href={'mailto:support@learndb.ru'}>support@learndb.ru</a>
          </span>
          <FeedbackButton className={'mt-x4'} />
        </div>
      ),
      isOpenInitial: true,
    },
  ]

  return (
    <div className={'coins-view'}>
      <section className={'profile-view-header'}>
        <Container>
          <h1 className={'profile-view-header-h1'}>Монеты</h1>
        </Container>
      </section>
      <section className={'coins-view-wallet'}>
        <Container>
          <div className={'d-flex flex-column align-items-start'}>
            <CoinsCard className={'mb-x4'} />
          </div>
        </Container>
      </section>
      <section className={'coins-view-how-get'}>
        <Container>
          <h2 className={'coins-view-how-get-h2'}>Как получить монеты</h2>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className={'coins-view-info-block'}>
                <img src={`/img/coins/coinsAchievement.svg`} width={180} height={180} alt={'Кубок'} />
                <p className={'coins-view-how-get-p mb-0'}>Вы будете получать монеты за выполнение достижений.</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className={'coins-view-info-block'}>
                <img src={`/img/coins/coinsSubscription.svg`} width={180} height={180} alt={'Подписка'} />
                <p className={'coins-view-how-get-p mb-0'}>
                  За некоторые достижения можно получить больше монет если оформлена подписка.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={'coins-view-how-use'}>
        <Container>
          <h2 className={'coins-view-how-use-h2'}>На что потратить</h2>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className={'coins-view-info-block'}>
                <img src={`/img/coins/coinsHowUse.svg`} width={180} height={180} alt={'Монеты'} />
                <p className={'coins-view-how-use-p'}>
                  Монеты можно потратить на оплату подписки. Для этого в карточке монет нажмите на кнопку
                  «Использовать».
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className={'coins-view-info-block'}>
                <img src={`/img/coins/coinsHowUsePay.svg`} width={180} height={180} alt={'монеты=рубли'} />
                <p className={'coins-view-how-use-p mb-0'}>
                  Чтобы оплатить подписку вам необходимо заработать количество монет, равное или превышающее стоимость
                  подписки.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <AchievementBlock isPaddingBottom={true} isBackgroundWhite={true} />
      <QuestionBlock
        questions={questions}
        css={{
          wrapper: 'coins-view-questions',
          container: 'py-0',
          h2: 'coins-view-questions-header',
        }}
        header={'Частые вопросы'}
      />
    </div>
  )
}

export {CoinsView}
