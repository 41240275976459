import React, {useState} from 'react'
import {Button, Card, CardBody, FormFeedback, Input, Collapse} from 'ac_ui'
import {useDispatch} from 'react-redux'
import {profileActions} from '../redux/actions'

interface IAuthFormProps {
  email: string
}

const AuthForm: React.FC<IAuthFormProps> = ({email}) => {
  const profileDispatch = useDispatch()
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false)
  const [passwordOld, setPasswordOld] = useState<string | undefined>('')
  const [passwordNew, setPasswordNew] = useState<string | undefined>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string | undefined>('')
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const toggleChangePassword = (): void => {
    setIsChangePassword(!isChangePassword)
    setPasswordOld('')
    setPasswordNew('')
    setPasswordRepeat('')
  }

  const isSubmitEnabled = (): boolean => {
    return !isSendingRequest && isChangePassword && passwordOld && passwordNew && passwordNew === passwordRepeat
  }

  const handleSubmit = (evt): void => {
    evt.preventDefault()
    if (isSubmitEnabled()) {
      setIsSendingRequest(true)
      profileDispatch(
        profileActions.changePassword(
          passwordOld,
          passwordNew,
          () => {
            setIsSendingRequest(false)
            setIsChangePassword(false)
            setErrorMessage(undefined)
          },
          (error) => {
            setIsSendingRequest(false)
            setErrorMessage(error.errorMessage)
          },
        ),
      )
    }
  }

  return (
    <form className={'mb-x6'} onSubmit={handleSubmit}>
      <Card className={'profile-edit-card'}>
        <CardBody className={'profile-edit-card-body'}>
          <h2 className={'header'}>Параметры входа</h2>
          <Input
            id={'authEmail'}
            name={'email'}
            type={'text'}
            label={'Email'}
            value={email}
            onChange={undefined}
            disabled={true}
          />
          <Input
            id={'authPasswordOld'}
            name={'passwordOld'}
            type={'password'}
            label={isChangePassword ? 'Текущий пароль' : 'Пароль'}
            value={isChangePassword ? passwordOld : '1234567890'}
            onChange={(value) => {
              setPasswordOld(value)
              setErrorMessage(undefined)
            }}
            disabled={!isChangePassword}
          />
          <Collapse isOpen={isChangePassword}>
            <Input
              id={'authPasswordNew'}
              name={'passwordNew'}
              type={'password'}
              label={'Новый пароль'}
              value={passwordNew}
              onChange={(value) => {
                setPasswordNew(value)
              }}
            />
            <Input
              id={'authPasswordRepeat'}
              name={'passwordRepeat'}
              type={'password'}
              label={'Повторите пароль'}
              value={passwordRepeat}
              onChange={(value) => {
                setPasswordRepeat(value)
              }}
              valid={passwordNew && passwordNew === passwordRepeat}
              feedback={passwordNew !== passwordRepeat ? 'Пароли не совпадают' : ''}
              className={'mb-0'}
            />
            {errorMessage && <FormFeedback message={errorMessage} isShow type={'danger'} className={'mt-x2'} />}
            <div className={'change-password-button-container mt-x8'}>
              <Button
                style={'ghost'}
                onClick={toggleChangePassword}
                className={'change-password-button-profile revoke'}>
                Отмена
              </Button>
              <Button disabled={!isSubmitEnabled()} type={'submit'} className={'change-password-button-profile change'}>
                Изменить пароль
              </Button>
            </div>
          </Collapse>
          {!isChangePassword && (
            <Button style={'outline'} className={'w-100 mt-x2'} onClick={toggleChangePassword}>
              Изменить пароль
            </Button>
          )}
        </CardBody>
      </Card>
    </form>
  )
}

export {AuthForm}
