import React from 'react'
import {URLS} from '../../constants/urls'
import {ButtonIcon, Col, COLORS, Container, Divider, Icon, MultiLink, Row} from 'ac_ui'
import {acSnowflake, acVkCircle, acTelegramCircle} from 'ac-icon'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {UserDto} from 'learndb-share'
import {FeedbackButton} from '../feedback/components/FeedbackButton'
import {ymTarget} from '../../metrics/ym/ymTarget'
import {YmTargetEnum} from '../../metrics/ym/YmTargetEnum'

const PageFooter: React.FC = () => {
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  const isAuth = !!user
  return (
    <footer id={'page-footer'} className={'page-footer z-1000'}>
      <Container size={'big'}>
        <Row>
          <Col xs={12} sm={12} md={3} lg={2} xl={3} className={'footer-item footer-logo-wrapper'}>
            <img src={'/img/logo-white.svg'} width={124} height={24} alt={'Логотип'} className={'footer-logo'} />
            <p className={'footer-description mb-0'}>Онлайн-курсы по SQL СУБД PostgreSQL</p>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2} xl={2} className={'footer-item'}>
            <p className={'footer-title mb-x4'}>Сервисы</p>
            <ul>
              <li className={'footer-description mb-x3'}>
                <MultiLink type={'href'} href={URLS.themes}>
                  Курсы
                </MultiLink>
              </li>
              <li className={'footer-description mb-0'}>
                <MultiLink type={'href'} href={URLS.articles}>
                  Учебник по SQL
                </MultiLink>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2} xl={2} className={'footer-item'}>
            <p className={'footer-title mb-x4'}>Документы</p>
            <ul>
              <li className={'footer-description mb-x3'}>
                <MultiLink type={'href'} href={URLS.legal + URLS.privacy}>
                  Конфиденциальность
                </MultiLink>
              </li>
              <li className={'footer-description mb-x3'}>
                <MultiLink type={'href'} href={URLS.legal + URLS.license}>
                  Соглашение
                </MultiLink>
              </li>
              <li className={'footer-description mb-0'}>
                <MultiLink type={'href'} href={URLS.legal + URLS.offer}>
                  Оферта
                </MultiLink>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4} md={3} lg={2} xl={2} className={'footer-item'}>
            <p className={'footer-title mb-x4'}>Оплата</p>
            <ul>
              <li className={'footer-description mb-x3'}>
                <MultiLink href={isAuth ? URLS.profile + URLS.profileSubscription : URLS.payment} type={'href'}>
                  Цены
                </MultiLink>
              </li>
              <li className={'footer-description mb-0'}>
                <MultiLink type={'href'} href={isAuth ? URLS.profile + URLS.coins : URLS.coins}>
                  Монеты
                </MultiLink>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={3} className={'footer-item footer-contacts'}>
            <p className={'footer-title mb-x4'}>Контакты</p>
            <FeedbackButton className={'mb-x2'} color={'white'} size={'sm'} style={'outline'} />
            <div className={'social-network'}>
              <ButtonIcon
                iconPath={acTelegramCircle}
                color={'white'}
                title={'Телеграм'}
                href={URLS.socialTelegram}
                target={'_blank'}
                className={'mr-x2'}
                onClickLink={() => {
                  ymTarget(YmTargetEnum.TELEGRAM_CHANNEL)
                }}
              />
              <ButtonIcon
                iconPath={acVkCircle}
                color={'white'}
                title={'ВКонтакте'}
                href={URLS.socialVk}
                target={'_blank'}
                className={'mr-0'}
                onClickLink={() => {
                  ymTarget(YmTargetEnum.VK_CHANNEL)
                }}
              />
            </div>
          </Col>
        </Row>
        <Divider className={'w-100'} />
        <div className={'footer-caption'}>
          <a
            href={'https://altaicoders.ru'}
            target={'_blank'}
            rel={'noreferrer'}
            className={'footer-caption-item d-flex align-items-start'}>
            Сделано ребятами из Сибири
            <Icon
              id={'icon-acSnowflake'}
              path={acSnowflake}
              color={COLORS.gray200}
              className={'ml-x2'}
              title={'Сибирь'}
            />
          </a>
          <p className={'footer-caption-item'}>&copy; {new Date().getFullYear()} LearnDB</p>
        </div>
      </Container>
    </footer>
  )
}

export {PageFooter}
