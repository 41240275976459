import React from 'react'
import {ButtonLink, Col, COLORS, Container, Icon, Row} from 'ac_ui'
import {acArrowRight, acCheck} from 'ac-icon'
import {URLS} from '../../../constants/urls'
import {LandingDto} from 'learndb-share'
import {getCountString} from 'ac-share'

interface IWhatYouWillLearnProps {
  landing: LandingDto
}

const LIST: React.ReactElement[] = [
  <>Писать простые SQL запросы для&nbsp;получения данных</>,
  <>Работать с&nbsp;данными из&nbsp;нескольких таблиц</>,
  <>Фильтровать и&nbsp;сортировать строки данных</>,
  <>Объединять, пересекать и&nbsp;исключать наборы строк</>,
  <>Использовать подзапросы</>,
  <>Выполнять математические операции</>,
  <>Работать с&nbsp;датой и&nbsp;временем</>,
  <>Использовать строковые функции</>,
  <>Нумеровать строки и&nbsp;делить их&nbsp;на&nbsp;группы, составлять рейтинги</>,
  <>Освоите мощный инструмент для&nbsp;проведения аналитики – оконные функции</>,
  <>Строить иерархии данных с&nbsp;помощью рекурсивных подзапросов</>,
  <>Узнаете особенности работы СУБД PostgreSQL</>,
]

const WhatYouWillLearn: React.FC<IWhatYouWillLearnProps> = ({landing}) => {
  let countTotalTask: number
  let countTotalTheme: number
  let avgCompletionTimeOfAllTaskHour: number
  if (landing) {
    avgCompletionTimeOfAllTaskHour = landing.courseTaskStatistic.avgCompletionTimeOfAllTaskHour
    countTotalTask = landing.courseTaskStatistic.countTotalTask
    countTotalTheme = landing.courseTaskStatistic.countTotalTheme
  }

  const items: React.ReactElement[] = []

  for (let i = 0; i < LIST.length; i++) {
    const item = LIST[i]
    items.push(
      <Col
        key={'what-you-will-learn-card-' + i}
        tag={'li'}
        className={'what-you-will-learn-card'}
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={4}>
        <div className={'check-wrapper'}>
          <Icon path={acCheck} title={undefined} color={COLORS.blue800} />
        </div>
        <p className={'what-you-will-learn-name'}>{item}</p>
      </Col>,
    )
  }

  return (
    <section className={'what-you-will-learn'}>
      <Container className={'what-you-will-learn-container'}>
        <h2 className={'landing-h2 what-you-will-learn-h2'}>Чему научитесь</h2>
        <Row className={'what-you-will-learn-list'} tag={'ul'}>
          {items}
        </Row>
        <div className={'what-you-will-learn-stat'}>
          <div className={'what-you-will-list'}>
            <div className={'what-you-will-card'}>
              <span className={'count'}>{countTotalTheme}</span>
              <span className={'name'}>{getCountString(countTotalTheme, 'тема', 'темы', 'тем')}</span>
            </div>
            <div className={'what-you-will-card'}>
              <span className={'count'}>{countTotalTask}</span>
              <span className={'name'}>{getCountString(countTotalTask, 'урок', 'урока', 'уроков')}</span>
            </div>
            <div className={'what-you-will-card'}>
              <span className={'count'}>{avgCompletionTimeOfAllTaskHour}</span>
              <span className={'name'}>
                {getCountString(avgCompletionTimeOfAllTaskHour, 'час', 'часа', 'часов')} ≈&nbsp;время <br />
                прохождения
              </span>
            </div>
          </div>

          <ButtonLink href={URLS.themes} size={'lg'} iconPosition={'right'} iconPath={acArrowRight}>
            Открыть программу
          </ButtonLink>
        </div>
      </Container>
    </section>
  )
}

export {WhatYouWillLearn}
