import {CONFIG} from '../constants'
import {isServer} from 'ac-share'

declare let io: any

function createSocket(timeout?: number): any {
  if (!isServer) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!window.socketConnection) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.socketConnection = io(CONFIG.socketWeb, {
          timeout: timeout || 10000,
        })

        window.onunload = () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.socketConnection.close()
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return window.socketConnection
    } catch (e) {
      console.error('Error create socket', e)
      return null
    }
  }
}

export {createSocket}
