import React, {useMemo} from 'react'
import {isEmpty} from 'ac-share'
import {Collapse, Icon, useCollapse} from 'ac_ui'
import {acClose, acPlus} from 'ac-icon'
import classNames from 'classnames'

interface CollapseQuestionWrapperProps {
  className?: string
  contentClassName?: string
  labelBlockId?: string
  label: React.ReactNode
  isOpenInitial?: boolean
  children?: any

  isOpenProp?: boolean
  toggleProp?: (value: boolean) => void
}

const CollapseQuestionWrapper: React.FC<CollapseQuestionWrapperProps> = ({
  label,
  className,
  contentClassName,
  labelBlockId,
  children,
  isOpenInitial,
  isOpenProp,
  toggleProp,
}): React.ReactElement => {
  const [isOpenLocal, toggleCollapse] = useCollapse(isEmpty(isOpenProp) ? isOpenInitial : isOpenProp)

  const isOpen = isEmpty(isOpenProp) ? isOpenLocal : isOpenProp

  const renderChevron: React.ReactElement = useMemo(() => {
    return (
      <div className={'d-flex'}>
        <Icon
          className={'cqw-icon-left'}
          path={isOpen ? acClose : acPlus}
          id={labelBlockId + (isOpen ? 'icon-open' : 'icon-close')}
          title={isOpen ? 'Свернуть' : 'Развернуть'}
          size={'24px'}
        />
      </div>
    )
  }, [isOpen, labelBlockId])

  return useMemo(() => {
    return (
      <div className={classNames('cqw', isOpen ? 'open' : 'is-h', className)}>
        <div
          id={labelBlockId}
          className={classNames('cqw-title-wrapper', isOpen ? 'open' : '')}
          onClick={() => {
            if (toggleProp) {
              toggleProp(!isOpen)
            } else {
              toggleCollapse()
            }
          }}>
          {renderChevron}
          <p className={'cqw-label'}>{label}</p>
        </div>
        <Collapse isOpen={isEmpty(isOpen) ? isOpen : isOpen} className={contentClassName ? contentClassName : ''}>
          <div aria-hidden={!isOpen}>{children}</div>
        </Collapse>
      </div>
    )
  }, [className, labelBlockId, renderChevron, label, isOpen, contentClassName, children, toggleProp, toggleCollapse])
}

export {CollapseQuestionWrapper}
