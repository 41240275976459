import {IProfileState} from './IProfileState'
import {getSuccessActionType} from '../../../redux/utils'
import {constants} from '../../../redux/constants'

const INITIAL_STATE: IProfileState = {
  certificate: undefined,
  walletHistories: undefined,
}

function profileReducers(state: IProfileState = INITIAL_STATE, action): IProfileState {
  switch (action.type) {
    case constants.SET_PROFILE_STATE: {
      return {...state, ...action.state}
    }

    case getSuccessActionType(constants.GET_API_PROFILE): {
      return {
        ...state,
        [action.propId]: action.data,
      }
    }

    case getSuccessActionType(constants.GET_API_CERTIFICATE): {
      return {
        ...state,
        [action.propId]: action.data,
      }
    }

    case getSuccessActionType(constants.GET_WALLET_HISTORIES): {
      return {
        ...state,
        [action.propId]: action.data,
      }
    }

    default:
      return state
  }
}

export {profileReducers}
