import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../../redux/types/IReduxState'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'ac_ui'
import {acHistory} from 'ac-icon'
import {formatDate, formatDateTime} from 'ac-share'
import {EmptyList} from '../../../common/EmptyList'
import moment from 'moment'
import {paymentActions} from '../../../payment/redux/actions'
import {SubscriptionHistoryDto, SubscriptionPeriod} from 'learndb-share'

moment.locale('ru')

const SubscriptionHistoryModal: React.FC = (): React.ReactElement => {
  const paymentDispatch = useDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const subscriptionHistories = useSelector<IReduxState, SubscriptionHistoryDto[] | undefined>(
    (state) => state.payment?.subscriptionHistories,
  )

  useEffect(() => {
    paymentDispatch(paymentActions.getSubscriptionHistories())
  }, [paymentDispatch])

  const renderButton = (): React.ReactElement => {
    return (
      <Button
        style={'link'}
        className={'mt-x4'}
        size={'sm'}
        iconPosition={'left'}
        iconPath={acHistory}
        onClick={() => {
          setIsOpen(true)
        }}>
        История
      </Button>
    )
  }

  const renderModalContent = (): React.ReactElement => {
    const items: React.ReactElement[] = []
    if (subscriptionHistories) {
      for (const subscriptionHistory of subscriptionHistories) {
        items.push(
          <div key={subscriptionHistory.subscriptionId} className={'subscription-history-item'}>
            <p className={'subscription-history-item-interval'}>
              {formatDate(subscriptionHistory.dateFrom)} – {formatDate(subscriptionHistory.dateTo)}
            </p>
            <p className={'subscription-history-item-description'}>
              {`Оплата ${subscriptionHistory.isCoins ? 'монетами' : 'деньгами'} (${
                subscriptionHistory.subscriptionPeriodId === SubscriptionPeriod.MONTH ? 'месяц' : 'год'
              })`}
            </p>
            <p className={'subscription-history-item-time'}>
              Время операции: {formatDateTime(subscriptionHistory.paymentDate, 'DD MMMM YYYY, HH:mm')}
            </p>
          </div>,
        )
      }
    }
    if (items.length > 0) {
      return <div className={'subscription-history'}>{items}</div>
    } else {
      return (
        <div className={'subscription-history'}>
          <EmptyList
            type={'NOT_FOUND_SUBSCRIPTION'}
            title={'Пусто'}
            description={'Подписку не оформляли'}
            isMobileImg
          />
        </div>
      )
    }
  }

  return (
    <>
      {renderButton()}
      {isOpen && (
        <Modal
          size={'sm'}
          isOpen={isOpen}
          isUpdateBodyScroll={false}
          onClose={() => {
            setIsOpen(false)
          }}
          closeOnEscape={true}>
          <ModalHeader isBorder className={'subscription-history-modal-header'}>
            Подписки
          </ModalHeader>
          <ModalBody isScroll>{renderModalContent()}</ModalBody>
          <ModalFooter isBorder className={'pt-x4 pb-x6 w-100'}>
            <Button
              onClick={() => {
                setIsOpen(false)
              }}
              className={'w-100'}>
              Закрыть
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export {SubscriptionHistoryModal}
