import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {ILayoutProps} from '../Layout'

const NotFoundNavigator: React.FC<ILayoutProps> = ({staticContext}) => {
  const location = useLocation()
  if (staticContext) {
    staticContext.statusCode = 404
    return undefined
  } else {
    return <Navigate to={'/not-found'} state={{from: location}} replace />
  }
}

export {NotFoundNavigator}
