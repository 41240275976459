import React, {useState} from 'react'
import {Button, ButtonLink, Card, CardBody, Col, Container, FormFeedback, Input, Row} from 'ac_ui'
import {ResponseError} from 'ac-share'
import {apiPost} from '../../api'
import {URLS} from '../../constants/urls'
import {useParams} from 'react-router-dom'
import {IIsSending} from './domain/IIsSending'
import {ErrorMessages} from '../../../api/constants'

const ChangePasswordPage: React.FC = () => {
  const {token} = useParams()
  const [isSending, setIsSending] = useState<IIsSending>({
    isSendingRequest: false,
    isSendingSuccess: false,
  })
  const [password, setPassword] = useState<string | undefined>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string | undefined>('')
  const [error, setError] = useState<ResponseError | undefined>(undefined)

  const isSubmitDisabled = (): boolean => {
    return isSending.isSendingRequest || (!password && password === '') || (!passwordRepeat && passwordRepeat === '')
  }

  const isValidPassword = (): boolean => {
    return password === passwordRepeat
  }

  const handleSubmit = async (evt): Promise<void> => {
    evt.preventDefault()
    if (!isValidPassword()) {
      setError({status: 409, errorMessage: ErrorMessages.PASSWORDS_IS_NOT_EQUAL})
    } else if (!isSubmitDisabled()) {
      setIsSending({
        isSendingRequest: true,
        isSendingSuccess: false,
      })
      try {
        await apiPost('/user/recover-password', {token, password})
        setError(undefined)
        setIsSending({
          isSendingSuccess: true,
          isSendingRequest: false,
        })
      } catch (err: any) {
        setError({
          status: err.status,
          errorMessage: err.errorMessage,
        })
        setIsSending({
          isSendingRequest: false,
          isSendingSuccess: false,
        })
      }
    }
  }

  return (
    <Container>
      <div className={'background-image-registration recovery-password'}>
        <Row>
          <Col lg={{size: 4, offset: 4}} md={{size: 5, offset: 4}}>
            <form className={'sign-up-form'} onSubmit={handleSubmit}>
              <Card className={'sign-in-card'}>
                <CardBody className={'px-xs-0 py-xs-x10 p-sm-x10'}>
                  <div className={'header-password-recovery'}>Изменение пароля</div>
                  <Input
                    id={'changePassword'}
                    name={'password'}
                    type={'password'}
                    label={'Новый пароль'}
                    value={password}
                    required
                    onChange={(value) => {
                      setPassword(value)
                    }}
                  />
                  <Input
                    id={'changeRepeatPassword-'}
                    name={'repeatPassword'}
                    type={'password'}
                    label={'Повторите пароль'}
                    value={passwordRepeat}
                    required
                    className={'mb-x10'}
                    onChange={(value) => {
                      setPasswordRepeat(value)
                    }}
                  />
                  <FormFeedback
                    type={'success'}
                    isShow={isSending.isSendingSuccess}
                    className={'mt-n-x4 mb-x6'}
                    message={'Пароль успешно изменен'}
                  />

                  {isSending.isSendingSuccess && (
                    <ButtonLink href={URLS.signin} className={'w-100 text-center'}>
                      Перейти на страницу входа
                    </ButtonLink>
                  )}
                  {error && (
                    <FormFeedback
                      type={'danger'}
                      isShow={!!error}
                      className={'mb-x6 mt-n-x4'}
                      message={error.errorMessage}
                    />
                  )}

                  {!isSending.isSendingSuccess && (
                    <Button
                      type={'submit'}
                      color={'primary'}
                      size={'md'}
                      className={'w-100'}
                      disabled={isSubmitDisabled()}>
                      Сохранить
                    </Button>
                  )}
                </CardBody>
              </Card>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export {ChangePasswordPage}
