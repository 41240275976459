function getAuthorizationHeader(): any {
  return {}
}

function redirectToSignInPage(redirectUrl: string): void {
  const url = new URL(window.location.origin + '/signin')
  url.searchParams.append('redirectUrl', window.location.origin + redirectUrl)
  window.location.replace(url.toString())
}

export {getAuthorizationHeader, redirectToSignInPage}
