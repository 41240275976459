import React from 'react'
import {Alert, Modal, ModalBody} from 'ac_ui'
import {paymentActions} from '../redux/actions'
import {YandexPaymentDto} from 'learndb-share'
import {useDispatch} from 'react-redux'
import {PAYMENT_FORM_ID} from '../PaymentTypes'
import {ResponseError} from 'ac-share'

interface IPaymentModalProps {
  payment: YandexPaymentDto
  error: ResponseError | undefined
}

const PaymentModal: React.FC<IPaymentModalProps> = ({payment, error}): React.ReactElement => {
  const paymentDispatch = useDispatch()

  if (payment) {
    return (
      <Modal
        isOpen={true}
        size={'lg'}
        className={'w-100'}
        classNameContent={'w-100'}
        isUpdateBodyScroll={false}
        onClose={() => {
          paymentDispatch(paymentActions.setState({payment: null}))
        }}>
        <ModalBody isScroll className={'w-100'}>
          <div id={PAYMENT_FORM_ID} className={'w-100'} />
          {error && (
            <Alert
              message={
                'Произошла ошибка при инициализации платежа. ' +
                'Пожалуйста, повторите попытку позже или свяжитесь с нами по адресу support@lerndb.ru'
              }
              color={'danger'}
            />
          )}
          {!(window as any).YooMoneyCheckoutWidget && (
            <Alert
              message={
                'Произошла ошибка загрузки виджета оплаты. ' +
                'Пожалуйста, проверьте доступность https://yookassa.ru или ' +
                'свяжитесь с нами по адресу support@lerndb.ru'
              }
              color={'danger'}
            />
          )}
        </ModalBody>
      </Modal>
    )
  } else {
    return null
  }
}

export {PaymentModal}
