import React, {useState} from 'react'
import TaskList from './TaskList'
import FeePayingBadge from './FeePayingBadge'
import {CompletionIndicator} from './CompletionIndicator'
import {Button, ButtonLink, Collapse} from 'ac_ui'
import {acChevronDown, acChevronTop} from 'ac-icon'
import {ThemeDto} from 'learndb-share'
import classNames from 'classnames'
import {getContinueTaskId} from '../utils'
import {getDiamondByLevel} from '../../common/buttonTask/utils'
import {URLS} from '../../../constants/urls'

interface IThemeItemProps {
  theme: ThemeDto
  isPreviousCompleted: boolean
}

const ThemeItem: React.FC<IThemeItemProps> = ({theme}) => {
  const [isTasksOpened, setIsTasksOpened] = useState<boolean>(false)

  const toggleTasksOpened = (): void => {
    setIsTasksOpened(!isTasksOpened)
  }

  let countSolvedTasks = 0
  let countTotalTasks = 0
  let continueTaskId = null
  let continueTaskText = 'Начать'
  if (theme.tasks && theme.tasks.length) {
    countTotalTasks = theme.tasks.length
    theme.tasks.forEach((task) => {
      if (task.isSolved) {
        countSolvedTasks++
      }
    })

    continueTaskId = getContinueTaskId(theme.tasks)
    if (countSolvedTasks === countTotalTasks) {
      continueTaskText = 'Повторить'
    } else if (countSolvedTasks !== 0) {
      continueTaskText = 'Продолжить'
    }
  }

  return (
    <section className={'course-theme'}>
      <h2 className={'visually-hidden'}>
        {theme.courseName}: {theme.name}
      </h2>
      <CompletionIndicator
        countSolvedTasks={countSolvedTasks}
        countTotalTasks={countTotalTasks}
        levelId={theme.levelId}
      />
      <div className={'course-theme-card'}>
        <div className={'course-theme-card-blocks'}>
          <div className={'block-info'}>
            <h3 className={'block-info-h3'}>
              <span className={'cursor-pointer'} onClick={toggleTasksOpened}>
                {theme.name}
              </span>
              <FeePayingBadge id={'course-' + theme.themeId} isFeePaying={theme.isFeePaying} className={'ml-x2'} />
            </h3>
            <p className={'block-info-description'}>{theme.description}</p>
            <div className={'course-level-fs'}>
              <div className={'d-flex mr-x2'}>{getDiamondByLevel(theme.levelId, 'theme-level')}</div>
              <span>{theme.levelName}</span>
            </div>
          </div>
          <div className={'block-stat'}>
            <div className={'block-time'}>
              <div className={'d-flex flex-column'}>
                <p className={'block-time-title'}>{theme.passageTime}</p>
                <p className={'block-time-description'}>&#8776; время прохождения</p>
              </div>
              {continueTaskId ? (
                <ButtonLink href={`${URLS.tasks}/${continueTaskId}`} className={'w-100'} size={'sm'}>
                  {continueTaskText}
                </ButtonLink>
              ) : null}
            </div>
            <div className={'block-count'}>
              <div className={'d-flex flex-column'}>
                <p className={classNames('block-count-title', {completed: countSolvedTasks === countTotalTasks})}>
                  {countSolvedTasks} из {countTotalTasks}
                </p>
                <p className={'block-count-description'}>уроков завершено</p>
              </div>
              <Button
                onClick={toggleTasksOpened}
                size={'sm'}
                style={'ghost'}
                className={'w-100'}
                iconPath={isTasksOpened ? acChevronTop : acChevronDown}
                iconPosition={'right'}>
                Список
              </Button>
            </div>
          </div>
        </div>
        <Collapse isOpen={isTasksOpened}>
          <TaskList tasks={theme.tasks} />
        </Collapse>
      </div>
    </section>
  )
}

export {ThemeItem}
