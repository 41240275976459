import {constants} from '../../../redux/constants'
import {getSuccessActionType} from '../../../redux/utils'
import {ArticleDto, SectionArticlesDto} from 'learndb-share'

export interface IArticleState {
  articleSections: SectionArticlesDto[] | undefined
  article: ArticleDto | undefined
  loaded: boolean
}

const INITIAL_STATE: IArticleState = {
  articleSections: undefined,
  article: undefined,
  loaded: false,
}

function articleReducers(state = INITIAL_STATE, action): IArticleState {
  switch (action.type) {
    case getSuccessActionType(constants.GET_ARTICLE): {
      return {
        ...state,
        [action.propId]: action.data,
      }
    }

    case getSuccessActionType(constants.GET_ARTICLE_BY_NAME): {
      return {
        ...state,
        [action.propId]: action.data,
      }
    }

    case getSuccessActionType(constants.GET_ARTICLE_SECTIONS): {
      return {
        ...state,
        [action.propId]: action.data,
      }
    }
    default: {
      return state
    }
  }
}

export {articleReducers}
