const URLS = {
  themes: '/courses',
  articles: '/articles',
  profile: '/profile',
  profileI: '/i',
  profilePreferences: '/preferences',
  profileSubscription: '/subscription',
  profileCertificate: '/certificate',
  profileAchievement: '/achievement',
  tasks: '/courses/task',
  legal: '/legal',
  license: '/license',
  privacy: '/privacy',
  offer: '/offer',
  offerLegal: '/offer-legal',
  payment: '/payment',
  paymentFinish: '/payment/finish',
  signup: '/signup',
  signin: '/signin',
  workInProgress: '/work-in-progress',
  recoverPasswordEmail: '/recover-password',
  changePassword: '/change-password',
  unauthorized: '/unauthorized',
  checkCertificate: '/check-cert',
  marathon: '/marathon',
  coins: '/coins',
  socialTelegram: 'https://t.me/LearnDB_official',
  socialTelegramChat: 'https://t.me/learndbchat',
  socialInstagram: 'https://www.instagram.com/learndb.ru',
  socialVk: 'https://vk.com/learndb',
  socialFacebook: 'https://www.facebook.com/learndbru',
}

const URLS_AUTHORIZED = [
  URLS.profile + URLS.profileI,
  URLS.profile + URLS.profilePreferences,
  URLS.profile + URLS.profileSubscription,
  URLS.profile + URLS.profileCertificate,
  URLS.profile + URLS.profileAchievement,
  URLS.profile + URLS.coins,
]
export {URLS, URLS_AUTHORIZED}
