import React from 'react'
import {Col, Row} from 'ac_ui'
import {CourseDto} from 'learndb-share'
import {CourseInfoCard} from './CourseInfoCard'

interface ICourseInfoProps {
  course: CourseDto
  isProAccount: boolean
  scrollToThemes: () => void
}

const CourseInfo: React.FC<ICourseInfoProps> = ({course, isProAccount, scrollToThemes}): React.ReactElement => {
  return (
    <Row className={'course-info'}>
      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
        <h2 className={'course-h2-medium'}>Чему научитесь</h2>
        <p className={'course-info-p'}>{course.description}</p>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} xl={6} className={'course-info-card-wrapper'}>
        <CourseInfoCard course={course} isProAccount={isProAccount} scrollToThemes={scrollToThemes} />
      </Col>
    </Row>
  )
}

export {CourseInfo}
