import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {CourseDto} from 'learndb-share'
import {courseActions} from '../redux/actions'
import {useEffect} from 'react'
import {useRequest, UseRequestTypeEnum} from '../../../hooks/useRequest'

function useLoadCourses(): CourseDto[] {
  const courseDispatch = useDispatch()
  const courses = useSelector<IReduxState, CourseDto[]>((state) => state.course?.courses)

  const [request] = useRequest<CourseDto[]>({
    type: UseRequestTypeEnum.API_GET,
    url: '/course',
    onDispatch: (res) => {
      courseDispatch(courseActions.setCourses(res))
    },
  })

  useEffect(() => {
    if (!courses) {
      request()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return courses
}

export {useLoadCourses}
