declare const SOCKET_WEB: string
declare const CDN_WEB: string

const CONFIG = {
  socketWeb: SOCKET_WEB,
  cdnWeb: CDN_WEB + '/cdn',
}

const COUPON_ASSIGN_SEARCH_PARAM = 'assign-coupon'

export {CONFIG, COUPON_ASSIGN_SEARCH_PARAM}
