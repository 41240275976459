import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {AchievementCategoryDto} from 'learndb-share'
import {achievementActions} from '../redux/actions'
import {useEffect} from 'react'
import {useRequest, UseRequestTypeEnum} from '../../../hooks/useRequest'

function useLoadAchievementCategories(): AchievementCategoryDto[] {
  const achievementDispatch = useDispatch()
  const achievementCategories = useSelector<IReduxState, AchievementCategoryDto[] | undefined>(
    (state) => state.achievement?.achievementCategories,
  )
  const isNeedUpdateAchievementCategories = useSelector<IReduxState, boolean>(
    (state) => state.achievement?.isNeedUpdateAchievementCategories,
  )

  const [request] = useRequest<AchievementCategoryDto[]>({
    type: UseRequestTypeEnum.API_GET,
    url: '/achievement/category/all',
    onDispatch: (res) => {
      achievementDispatch(achievementActions.setAchievementCategories(res))
    },
  })

  useEffect(() => {
    if (!achievementCategories || isNeedUpdateAchievementCategories) {
      // console.log('load achievementCategories')
      request()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedUpdateAchievementCategories])

  return achievementCategories
}

export {useLoadAchievementCategories}
