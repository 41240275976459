import React, {useCallback, useEffect} from 'react'
import {AchievementDto, SocketEventTypeEnum, UserDto} from 'learndb-share'
import {createAchievementToast} from '../achievement/utils/toast'
import {useDispatch, useSelector} from 'react-redux'
import {authActions} from '../auth/redux/actions'
import {achievementActions} from '../achievement/redux/actions'
import {IReduxState} from '../../redux/types/IReduxState'

declare const socket: any

const SocketObserver: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch()

  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  const completedAchievement = useCallback(
    (response: string): void => {
      const achievement: AchievementDto = JSON.parse(response)
      createAchievementToast(achievement, user && user.userId && !user.isAnonymous)
      dispatch(authActions.check())
      dispatch(achievementActions.setIsNeedUpdateAchievementCategories())
      dispatch(achievementActions.setIsNeedUpdateLastCompletedAchievements())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  )

  const updateUser = useCallback((): void => {
    dispatch(authActions.check())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on(SocketEventTypeEnum.COMPLETED_ACHIEVEMENT, completedAchievement)
      socket.on(SocketEventTypeEnum.UPDATE_USER, updateUser)

      return () => {
        socket.off(SocketEventTypeEnum.COMPLETED_ACHIEVEMENT)
        socket.off(SocketEventTypeEnum.UPDATE_USER)
      }
    }
  }, [completedAchievement, updateUser])

  return undefined
}

export {SocketObserver}
