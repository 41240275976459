import {getSuccessActionType} from '../../../redux/utils'
import {constants} from '../../../redux/constants'
import {IFilters} from '../domain/IFilters'
import {CourseDto, LevelDto, TaskInfoDto} from 'learndb-share'

export interface ICourseState {
  courses: CourseDto[]
  levels: LevelDto[]
  nextTask: TaskInfoDto
  filters: IFilters
}

const INITIAL_STATE: ICourseState = {
  courses: undefined,
  levels: undefined,
  nextTask: undefined,
  filters: {
    string: '',
    level: 'all',
  },
}

function courseReducers(state: ICourseState = INITIAL_STATE, action): ICourseState {
  switch (action.type) {
    case constants.SET_COURSE_PROP: {
      return {
        ...state,
        [action.propId]: action.value,
      }
    }

    case constants.CLEAR_COURSE_PROP: {
      return {
        ...state,
        [action.propId]: null,
      }
    }

    case constants.SET_COURSES: {
      return {
        ...state,
        courses: action.payload,
      }
    }

    case getSuccessActionType(constants.GET_API_COURSE): {
      return {
        ...state,
        [action.propId]: action.data,
      }
    }

    default: {
      return state
    }
  }
}

export {courseReducers}
