import React from 'react'
import {Container} from 'ac_ui'
import classNames from 'classnames'
import {CollapseQuestionWrapper} from '../CollapseQuestionWrapper'
import {QuestionAnswer} from '../../../types/question/QuestionAnswer'

interface IQuestionBlockProps {
  header?: string
  questions: QuestionAnswer[]
  css?: {
    wrapper?: string
    container?: string
    h2?: string
    list?: string
    listItem?: string
  }
}

const QuestionBlock: React.FC<IQuestionBlockProps> = ({css, questions, header}): React.ReactElement => {
  const renderQuestionList = (): React.ReactNode => {
    const cssList = classNames('question-b-list', css?.list)
    const cssListItem = classNames('question-b-list-item', css?.listItem)
    const items: React.ReactElement[] = []
    for (let i = 0; i < (questions || []).length; i++) {
      const question: QuestionAnswer = questions[i]
      const key = `${i.toString()}-${question.key}`
      items.push(
        <li key={key} className={cssListItem}>
          <CollapseQuestionWrapper
            key={key}
            label={question.question}
            labelBlockId={`list-question-${key}`}
            isOpenInitial={question.isOpenInitial}>
            <div className={'question-b-item-wrapper'} key={key}>
              <div className={'question-b-item-answer'}>{question.answer}</div>
            </div>
          </CollapseQuestionWrapper>
        </li>,
      )
    }

    return <ul className={cssList}>{items}</ul>
  }

  const cssWrapper = classNames('question-b-wrapper', css?.wrapper)
  const cssContainer = classNames('question-b-container', css?.container)
  const cssH2 = classNames('question-b-h2', css?.h2)

  return (
    <section className={cssWrapper}>
      <Container className={cssContainer}>
        <h2 className={cssH2}>{header ? header : 'Вопросы и ответы'}</h2>
        {renderQuestionList()}
      </Container>
    </section>
  )
}

export {QuestionBlock}
