import {constants} from '../../../redux/constants'
import {CourseDto} from 'learndb-share'

export const courseActions = {
  setProp: (propId, value) => ({
    type: constants.SET_COURSE_PROP,
    propId,
    value,
  }),

  clearProp: (propId) => ({
    type: constants.CLEAR_COURSE_PROP,
    propId,
  }),

  setCourses: (courses: CourseDto[]) => ({
    type: constants.SET_COURSES,
    payload: courses,
  }),

  getLevels: () => ({
    type: constants.GET_API_COURSE,
    propId: 'levels',
    uri: '/course/level',
  }),

  getNextTask: () => ({
    type: constants.GET_API_COURSE,
    propId: 'nextTask',
    uri: '/course/task/next',
  }),
}
