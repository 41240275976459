import React from 'react'
import {MainMenu} from './MainMenu'
import {Container, MultiLink} from 'ac_ui'

const Header: React.FC = () => {
  return (
    <div className={'page-header'}>
      <Container size={'big'}>
        <div className={'main-header-fullwidth-container'}>
          <section className={'main-header'}>
            <div className={'main-header-logo-wrapper'}>
              <MultiLink href={'/'} type={'href'}>
                <img src={'/img/logo.svg'} alt={'Логотип'} width={124} height={24} className={'main-header-logo'} />
              </MultiLink>
            </div>
            <div className={'main-menu-container-pc'}>
              <MainMenu isTaskHeader={false} />
            </div>
          </section>
        </div>
      </Container>
    </div>
  )
}

export {Header}
