import React, {useEffect, useState} from 'react'
import {SearchPanelCommon} from '../../../../../common/SearchPanelCommon'
import {ArticleSection} from '../../../list/components/ArticleSection'
import {StringBooleanMap} from 'ac-share'
import {ArticleDto, SectionArticlesDto} from 'learndb-share'
import {useIsomorphicLayoutEffect} from 'ac_ui'
import classNames from 'classnames'
import {EmptyList} from '../../../../../common/EmptyList'

interface IArticleSectionNavProps {
  article: ArticleDto
  articleSections: SectionArticlesDto[]
  handleChangeSearch: (value: string) => void
  searchValue: string
}

const ArticleSectionNav: React.FC<IArticleSectionNavProps> = ({
  article,
  articleSections,
  handleChangeSearch,
  searchValue,
}): React.ReactElement => {
  const idPrefix = 'pc'
  const [collapseMap, setCollapseMap] = useState<StringBooleanMap>({})

  const [isShowScroll, setShowScroll] = useState<boolean>(false)

  useEffect(() => {
    collapseMap[article.sectionId] = true
    setCollapseMap({...collapseMap})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article.sectionId])

  useIsomorphicLayoutEffect(() => {
    if (article.articleId) {
      const element = document.getElementById(`list-item-${idPrefix}-${article.articleId}`)
      element.scrollIntoView({behavior: 'smooth'})
    }
  }, [])

  const renderList = (): React.ReactElement => {
    const css = classNames('article-section-nav custom-scroll', !isShowScroll ? 'scroll-off' : '')

    if (articleSections && articleSections.length > 0) {
      const items: React.ReactNode = articleSections.map((articleSection) => (
        <li
          key={articleSection.sectionId + articleSection.sectionName + idPrefix}
          className={'article-section-nav-list-item'}>
          <ArticleSection
            idPrefix={idPrefix}
            articleSection={articleSection}
            sectionNumber={articleSection.number}
            collapseMap={collapseMap}
            setCollapseMap={setCollapseMap}
            activeArticleId={article.articleId}
            onClickHref={undefined}
            isInnerArticle={true}
          />
        </li>
      ))

      return (
        <div
          className={css}
          onMouseEnter={() => {
            setShowScroll(true)
          }}
          onMouseLeave={() => {
            setShowScroll(false)
          }}>
          <ul className={'article-section-nav-list'}>{items}</ul>
        </div>
      )
    } else {
      return (
        <div
          className={css}
          onMouseEnter={() => {
            // setShowScroll(true)
          }}
          onMouseLeave={() => {
            // setShowScroll(false)
          }}>
          <EmptyList
            type={'NOT_FOUND'}
            title={'Ничего не найдено'}
            description={'Попробуйте поискать по другим ключевым словам'}
            isMobileImg
            className={'pt-x6'}
          />
        </div>
      )
    }
  }

  const renderSearchBlock = (): React.ReactElement => {
    return (
      <div className={'w-100 d-flex justify-content-start'}>
        <SearchPanelCommon // TODO:: Ещё будет доработан +
          handleChangeString={handleChangeSearch}
          searchId={'article-nav-search'}
          value={searchValue}
          className={'article-section-nav-search'}
          classNameInput={'w-100'}
        />
      </div>
    )
  }

  return (
    <>
      {renderSearchBlock()}
      {renderList()}
    </>
  )
}

export {ArticleSectionNav}
