import React, {useState} from 'react'
import {Button, ButtonLink, Card, CardBody, Col, Container, FormFeedback, Input, Row} from 'ac_ui'
import {ResponseError} from 'ac-share'
import {apiGet} from '../../api'
import {URLS} from '../../constants/urls'
import {IIsSending} from './domain/IIsSending'

const RecoveryPasswordEmailPage: React.FC = () => {
  const [isSending, setIsSending] = useState<IIsSending>({
    isSendingRequest: false,
    isSendingSuccess: false,
  })
  const [email, setEmail] = useState<string | undefined>('')
  const [isMailSend, setIsMailSend] = useState<boolean>(false)
  const [error, setError] = useState<ResponseError | undefined>(undefined)

  const handleSubmit = async (evt): Promise<void> => {
    evt.preventDefault()
    setIsSending({
      isSendingSuccess: false,
      isSendingRequest: true,
    })
    setError(undefined)
    try {
      await apiGet('/user/change-password/send-email', {
        params: {email},
      })
      setIsMailSend(true)
    } catch (err: any) {
      setError({
        errorMessage: err.errorMessage,
        status: err.status,
      })
    } finally {
      setIsSending({
        isSendingSuccess: false,
        isSendingRequest: false,
      })
    }
  }
  return (
    <Container>
      <div className={'background-image-registration recovery-password'}>
        <Row>
          <Col lg={{size: 4, offset: 4}} md={{size: 5, offset: 4}}>
            <form className={'sign-up-form'} onSubmit={handleSubmit}>
              <Card className={'sign-in-card'}>
                <CardBody className={'px-xs-0 py-xs-x10 p-sm-x10'}>
                  <h1 className={'header-password-recovery'}>Восстановление пароля</h1>
                  <p className={'recovery-password-caption'}>Введите email, указанный при регистрации</p>
                  <Input
                    id={'recoverPasswordEmail'}
                    name={'email'}
                    type={'text'}
                    label={'Email'}
                    required
                    value={email}
                    className={'mb-x10'}
                    onChange={(value) => {
                      setEmail(value)
                    }}
                  />
                  <FormFeedback
                    type={'success'}
                    isShow={isMailSend}
                    className={'mt-n-x4 mb-x6'}
                    message={'Мы отправили на ваш email письмо с инструкциями по восстановлению пароля'}
                  />
                  {isMailSend && (
                    <ButtonLink href={URLS.signin} className={'w-100 text-center'}>
                      На страницу входа
                    </ButtonLink>
                  )}
                  <FormFeedback
                    message={error?.errorMessage}
                    type={'danger'}
                    isShow={!!error}
                    className={'mb-x6 mt-n-x4'}
                  />
                  {!isMailSend && (
                    <Button type={'submit'} color={'primary'} size={'md'} className={'w-100'}>
                      Далее
                    </Button>
                  )}
                </CardBody>
              </Card>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export {RecoveryPasswordEmailPage}
