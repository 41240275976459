import React, {ReactElement, ReactNode} from 'react'
import {Control, Controller, UseFormSetValue} from 'react-hook-form'
import {getErrorsControllerInput} from '../utils/getErrorsControllerInput'
import {Input} from 'ac_ui'

export interface ControllerCommonProps<T> {
  control: Control<T, any>
  setValue: UseFormSetValue<T>
}

export interface IControllerInputProps {
  name: string
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
  common: ControllerCommonProps<any> | any
  className?: string
}

const ControllerInput: React.FC<IControllerInputProps> = ({
  name,
  placeholder,
  label,
  required,
  disabled,
  common,
  className,
}): React.ReactElement => {
  const {control, setValue} = common
  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field, formState}) => {
        const onChangeValue = (newValue: string): void => {
          const newName = newValue
          field.onChange(newName)
          setValue(field.name, newName)
        }

        const errorItems: ReactNode[] = getErrorsControllerInput<any>(formState, field.name, true)

        const feedBack: ReactElement | undefined = errorItems.length ? (
          <div className={'d-flex flex-column'}>{errorItems}</div>
        ) : undefined

        return (
          <Input
            id={field.name}
            type={'text'}
            name={field.name}
            value={field.value}
            placeholder={placeholder}
            isShowRequired={required}
            valid={feedBack ? false : undefined}
            feedback={feedBack}
            className={className ? className : ''}
            label={label}
            onChange={onChangeValue}
            disabled={disabled}
          />
        )
      }}
    />
  )
}

export {ControllerInput}
