import React from 'react'
import {ButtonLink, Container} from 'ac_ui'
import {URLS} from '../../../constants/urls'

const PaymentSuccessPage: React.FC = () => {
  return (
    <Container>
      <section className={'payment-status-page'}>
        <img src={'/img/emptyList/coolRobot.svg'} width={288} height={288} className={'image'} alt={'Крутой робот'} />
        <div className={'payment-status-wrapper'}>
          <h1 className={'header'}>Поздравляем, вы оформили подписку!</h1>
          <ButtonLink href={URLS.themes}>Учиться</ButtonLink>
        </div>
      </section>
    </Container>
  )
}

export {PaymentSuccessPage}
