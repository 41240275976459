import React, {useEffect} from 'react'
import {IFilters} from '../domain/IFilters'
import {SearchPanelCommon} from '../../common/SearchPanelCommon'
import {
  Container,
  DiamondBlueSvg,
  DiamondPurpleSvg,
  DiamondYellowSvg,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownToggleType,
  Select,
  SelectOption,
} from 'ac_ui'
import {IReduxState} from '../../../redux/types/IReduxState'
import {useDispatch, useSelector} from 'react-redux'
import {courseActions} from '../redux/actions'
import {LevelEnum} from 'learndb-share'
import {acFilter} from 'ac-icon'

export function createInitialFilters(selectedLevel?: LevelEnum | 'all'): IFilters {
  return {
    string: '',
    level: selectedLevel ? selectedLevel : 'all',
  }
}

const SearchPanel: React.FC = () => {
  const courseDispatch = useDispatch()
  const levels = useSelector<IReduxState, any | undefined>((state) => state.course?.levels)
  const filters = useSelector<IReduxState, IFilters | undefined>((state) => state.course?.filters)

  useEffect(() => {
    if (levels && !filters) {
      const newFilters = createInitialFilters(levels)
      courseDispatch(courseActions.setProp('filters', {...newFilters}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeLevel = (value: LevelEnum | 'all'): void => {
    filters.level = value
    courseDispatch(courseActions.setProp('filters', {...filters}))
  }

  const handleChangeString = (value): void => {
    filters.string = value
    courseDispatch(courseActions.setProp('filters', {...filters}))
  }

  const getIconLevel = (level: LevelEnum, prefix: string): React.ReactElement => {
    switch (level) {
      case LevelEnum.BASE: {
        return <DiamondBlueSvg id={level + prefix} />
      }
      case LevelEnum.MEDIUM: {
        return <DiamondYellowSvg id={level + prefix} />
      }
      case LevelEnum.ADVANCED: {
        return <DiamondPurpleSvg id={level + prefix} />
      }
    }
  }

  let levelSelect
  let levelDropdown
  if (levels?.length > 0 && filters) {
    const options: SelectOption[] = [
      {
        value: 'all',
        label: 'Все уровни',
      },
    ]
    const dropdownItems: React.ReactElement[] = [
      <DropdownItem
        key={'all'}
        text={'Все уровни'}
        size={'xl'}
        className={filters.level === 'all' ? 'text-primary-i' : ''}
        onClick={() => {
          handleChangeLevel('all')
        }}
      />,
    ]
    for (const level of levels) {
      options.push({
        value: level.levelId,
        label: level.name,
        icon: getIconLevel(level.levelId, 'select'),
      })
      dropdownItems.push(
        <DropdownItem
          key={level.levelId}
          text={level.name}
          size={'xl'}
          iconPosition={'left'}
          className={filters.level === level.levelId ? 'text-primary-i' : ''}
          icon={getIconLevel(level.levelId, 'dropdown')}
          onClick={() => {
            handleChangeLevel(level.levelId)
          }}
        />,
      )
    }
    levelSelect = (
      <Select
        id={'levelSelect'}
        name={'levelSelect'}
        label={undefined}
        optionsWithIcons
        isSearchable={false}
        value={filters.level}
        options={options}
        className={'themes-filter-search-levels-select'}
        onChange={(value) => {
          handleChangeLevel(value as LevelEnum | 'all')
        }}
      />
    )
    levelDropdown = (
      <Dropdown id={'DropdownLevel'} direction={'bottom-end'}>
        <DropdownToggle
          type={DropdownToggleType.buttonIcon}
          buttonIcon={{
            iconPath: acFilter,
            title: 'Сложность',
            className: 'themes-filter-search-levels-dropdown',
            color: filters.level !== 'all' ? 'primary' : undefined,
          }}
        />
        <DropdownMenu>{dropdownItems}</DropdownMenu>
      </Dropdown>
    )
  }
  return (
    <Container className={'d-flex flex-row w-100 pt-x6 pb-x6'}>
      <SearchPanelCommon
        value={filters.string || ''}
        searchId={'theme-search'}
        handleChangeString={handleChangeString}
        className={'mb-0'}
        classNameInput={'w-100'}
      />
      {levelSelect && levelSelect}
      {levelDropdown && levelDropdown}
    </Container>
  )
}

export {SearchPanel}
