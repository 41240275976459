import React, {ReactNode} from 'react'
import {NavLink} from 'react-router-dom'
import classNames from 'classnames'

interface IMenuItemProps {
  id: string
  url: string
  isActive: boolean
  children?: ReactNode
}

const MenuItem: React.FC<IMenuItemProps> = ({id, url, isActive, children}) => {
  const css = classNames('profile-menu-item', id, {
    active: isActive,
  })
  return (
    <li key={id}>
      <NavLink to={url} className={css}>
        {children}
      </NavLink>
    </li>
  )
}

export {MenuItem}
