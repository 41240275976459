import React from 'react'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {UserDto} from 'learndb-share'
import {SubscriptionView} from './SubscriptionView'

const SubscriptionViewContainer: React.FC = (): React.ReactElement => {
  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)

  return <SubscriptionView user={user} />
}

export {SubscriptionViewContainer}
