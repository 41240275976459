import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {Header} from './header/Header'
import {SignInPage} from './auth/SignInPage'
import {SignUpPage} from './auth/SignUpPage'
import CoursesLayout from './courses/CoursesLayout'
import {Landing} from './landing/Landing'
import {PageFooter} from './footer/PageFooter'
import {ProfileLayout} from './profile/ProfileLayout'
import {URLS} from '../constants/urls'
import {ConfirmEmail} from './auth/ConfirmEmail'
import {LegalLayout} from './legal/LegalLayout'
import {RecoveryPasswordEmailPage} from './auth/RecoveryPasswordEmailPage'
import {ChangePasswordPage} from './auth/ChangePasswordPage'
import {WorkInProgressPage} from './workInProgress/WorkInProgressPage'
import {PaymentFinishPage} from './payment/PaymentFinishPage'
import {ArticlesLayout} from './article/components/layout/ArticlesLayout'
import {NotFoundNavigator} from './notFound/NotFoundNavigator'
import {CoinsViewContainer} from './coins/components/view/CoinsViewContainer'
import {PaymentView} from './payment/components/view/PaymentView'
import {CertificateContainer} from './certificate/view/CertificateContainer'
import {useMetricsUpdater} from './metrics/useMetricsUpdater'
import {useCouponWatcher} from './payment/hooks/useCouponWatcher'

export interface ILayoutProps {
  staticContext?: StaticContext
}

const Layout: React.FC<ILayoutProps> = ({staticContext}) => {
  useMetricsUpdater()
  useCouponWatcher()
  return (
    <div className={'main-layout'}>
      <Header />
      <div className={'main-content'}>
        <Routes>
          <Route path={'/'} element={<Landing />} />
          <Route path={URLS.payment} element={<PaymentView />} />
          <Route path={URLS.paymentFinish + '/:paymentId'} element={<PaymentFinishPage />} />
          <Route path={'/confirm-email/:token'} element={<ConfirmEmail />} />
          <Route path={URLS.themes + '/*'} element={<CoursesLayout staticContext={staticContext} />} />
          <Route path={URLS.articles + '/*'} element={<ArticlesLayout staticContext={staticContext} />} />
          <Route path={URLS.coins + '/*'} element={<CoinsViewContainer />} />
          {/*<Route path={URLS.marathon} component={MarathonPage} />*/}
          <Route path={URLS.profile + '/*'} element={<ProfileLayout staticContext={staticContext} />} />
          <Route path={URLS.signin + '/*'} element={<SignInPage />} />
          <Route path={URLS.signup + '/*'} element={<SignUpPage />} />
          <Route path={URLS.recoverPasswordEmail + '/*'} element={<RecoveryPasswordEmailPage />} />
          <Route path={URLS.changePassword + '/:token'} element={<ChangePasswordPage />} />
          <Route path={URLS.legal + '/*'} element={<LegalLayout staticContext={staticContext} />} />
          <Route path={URLS.workInProgress} element={<WorkInProgressPage />} />
          <Route
            path={URLS.checkCertificate + '/:certificateId'}
            element={<CertificateContainer certificateId={undefined} />}
          />
          <Route path={'/*'} element={<NotFoundNavigator staticContext={staticContext} />} />
        </Routes>
      </div>
      <PageFooter />
    </div>
  )
}

export default Layout
