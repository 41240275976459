import React, {useEffect} from 'react'
import {SignInPage} from './SignInPage'
import {useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {authActions} from './redux/actions'

const ConfirmEmail: React.FC = () => {
  const authDispatch = useDispatch()
  const {token} = useParams()

  useEffect(() => {
    authDispatch(authActions.confirmEmail(token))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SignInPage />
}

export {ConfirmEmail}
