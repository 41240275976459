import {constants} from '../../../redux/constants'
import {PaymentPeriodDto} from 'learndb-share'
import {IPaymentState} from './reducers'

export const paymentActions = {
  setState: (state: IPaymentState) => ({
    type: constants.SET_PAYMENT_STATE,
    state,
  }),

  createPayment: (period: PaymentPeriodDto) => ({
    type: constants.POST_PAYMENT,
    uri: '/payment',
    data: period,
  }),

  createPaymentCoins: (period: PaymentPeriodDto, onSuccess: (data?: any) => void) => ({
    type: constants.POST_PAYMENT_COINS,
    uri: '/payment/coins',
    data: period,
    onSuccess,
  }),

  refreshPaymentInfo: (paymentId: string) => ({
    type: constants.GET_PAYMENT_REFRESH,
    uri: '/payment/refresh/' + paymentId,
  }),

  getSubscriptionPrice: () => ({
    type: constants.GET_SUBSCRIPTION_PRICE_MAP,
    uri: '/subscription/price',
  }),

  getSubscriptionHistories: () => ({
    type: constants.GET_SUBSCRIPTION_HISTORIES,
    uri: '/subscription/history',
  }),
}
