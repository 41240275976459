import React from 'react'
import {feedbackActions} from '../redux/actions'
import {acMail} from 'ac-icon'
import {Button, ButtonSize, ButtonStyle, ThemeColor} from 'ac_ui'
import {useDispatch} from 'react-redux'

interface IFeedbackButtonProps {
  className?: string
  size?: ButtonSize
  color?: ThemeColor | 'yellow100' | 'gradient'
  style?: ButtonStyle
}

const FeedbackButton: React.FC<IFeedbackButtonProps> = ({
  className,
  size = 'md',
  color = 'primary',
  style = 'ghost',
}): React.ReactElement => {
  const feedbackDispatch = useDispatch()

  return (
    <Button
      type={'button'}
      color={color}
      style={style}
      size={size}
      className={className}
      iconPosition={'left'}
      onClick={() => {
        feedbackDispatch(
          feedbackActions.setState({
            isOpenModal: true,
          }),
        )
      }}
      iconPath={acMail}>
      Написать письмо
    </Button>
  )
}

export {FeedbackButton}
