import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {ILayoutProps} from '../Layout'
import {NotFoundNavigator} from '../notFound/NotFoundNavigator'
import {ThemesListContainer} from './list/ThemesListContainer'

const CoursesLayout: React.FC<ILayoutProps> = ({staticContext}) => {
  return (
    <Routes>
      <Route path={'/'} element={<ThemesListContainer />} />
      <Route path={'*'} element={<NotFoundNavigator staticContext={staticContext} />} />
    </Routes>
  )
}

export default CoursesLayout
