import React from 'react'
import {ArticleDto} from 'learndb-share'
import {ButtonDirection} from 'ac_ui'

interface IArticlePaginationProps {
  article: ArticleDto
}

const ArticlePagination: React.FC<IArticlePaginationProps> = ({article}) => {
  return (
    <div className={'article-buttons'}>
      {article.previous ? (
        <ButtonDirection
          id={'previousDescription'}
          direction={'left'}
          href={`/articles/article/${article.previous.articleId}`}
          text={`${article.previous.number} ${article.previous.name}`}
          className={'min-h-100 article-button-direction-left mb-x4'}
        />
      ) : (
        <div className={'w-100'} />
      )}
      {article.next ? (
        <ButtonDirection
          id={'nextDescription'}
          direction={'right'}
          href={`/articles/article/${article.next.articleId}`}
          text={`${article.next.number} ${article.next.name}`}
          className={'min-h-100 mb-x4'}
        />
      ) : (
        <div className={'w-100'} />
      )}
    </div>
  )
}

export {ArticlePagination}
