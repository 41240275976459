import React from 'react'
import {ButtonLink, Container, Divider, Icon} from 'ac_ui'
import {URLS} from '../../../constants/urls'
import {acAchievement, acCheckCircleOutline, acClock, acTaskList} from 'ac-icon'
import {getCountString} from 'ac-share'
import {LandingDto} from 'learndb-share'

interface IMainInfoProps {
  landing: LandingDto | undefined
}

const MainInfo: React.FC<IMainInfoProps> = ({landing}) => {
  let minTime: number
  let maxTime: number
  let countTotalTask: number
  let countTotalTheme: number
  if (landing) {
    minTime = landing.courseTaskStatistic.courseCompletionTimeIntervalMonth.min
    maxTime = landing.courseTaskStatistic.courseCompletionTimeIntervalMonth.max
    countTotalTask = landing.courseTaskStatistic.countTotalTask
    countTotalTheme = landing.courseTaskStatistic.countTotalTheme
  }
  return (
    <section className={'main-info'}>
      <Container className={'main-info-container'}>
        <div className={'main-info-wrapper'}>
          <div className={'main-info-img'}>
            <img
              src={'/img/landing/pcSql.svg'}
              alt={'Монитор с клавиатурой'}
              className={'img'}
              width={384}
              height={384}
            />
          </div>
          <div className={'main-info-start'}>
            <h1 className={'main-info-title'}>Онлайн курс по&nbsp;SQL</h1>
            <p className={'main-info-description'}>
              Освойте SQL за&nbsp;{maxTime}&nbsp;{getCountString(maxTime, 'месяц', 'месяца', 'месяцев')}. <br />
              С&nbsp;нуля. Онлайн. На&nbsp;практике.
            </p>
            <ButtonLink href={URLS.themes} size={'lg'}>
              Начать
            </ButtonLink>
          </div>
        </div>
        <Divider className={'w-100 mb-x12'} />
        <div className={'main-info-list'}>
          <div className={'main-info-card'}>
            <div className={'d-flex mr-x3'}>
              <Icon path={acCheckCircleOutline} title={undefined} />
            </div>
            <div className={'d-flex flex-column'}>
              <p className={'main-info-card-title'}>Онлайн</p>
              <p className={'main-info-card-description'}>в&nbsp;любое время</p>
            </div>
          </div>
          <div className={'main-info-card'}>
            <div className={'d-flex mr-x3'}>
              <Icon path={acClock} title={undefined} />
            </div>
            <div className={'d-flex flex-column'}>
              <p className={'main-info-card-title'}>
                {minTime} – {maxTime} {getCountString(maxTime, 'месяц', 'месяца', 'месяцев')}
              </p>
              <p className={'main-info-card-description'}>в&nbsp;среднем на&nbsp;прохождение</p>
            </div>
          </div>
          <div className={'main-info-card'}>
            <div className={'d-flex mr-x3'}>
              <Icon path={acTaskList} title={undefined} />
            </div>
            <div className={'d-flex flex-column'}>
              <p className={'main-info-card-title'}>Много практики</p>
              <p className={'main-info-card-description'}>
                {countTotalTheme} {getCountString(countTotalTheme, 'тема', 'темы', 'тем')}, {countTotalTask}{' '}
                {getCountString(countTotalTask, 'урок', 'урока', 'уроков')}
              </p>
            </div>
          </div>
          <div className={'main-info-card'}>
            <div className={'d-flex mr-x3'}>
              <Icon path={acAchievement} title={undefined} />
            </div>
            <div className={'d-flex flex-column'}>
              <p className={'main-info-card-title'}>Игровые механики</p>
              <p className={'main-info-card-description'}>достижения и&nbsp;монеты</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export {MainInfo}
