import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Layout from './Layout'
import {NotFoundPage} from './notFound/NotFoundPage'
import {OopsPage} from './oopsPage/OopsPage'

const CommonLayout: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/oops/*'} element={<OopsPage />} />
        <Route path={'/not-found/*'} element={<NotFoundPage />} />
        <Route path={'/*'} element={<Layout />} />
      </Routes>
    </BrowserRouter>
  )
}

export {CommonLayout}
