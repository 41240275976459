import React, {useCallback, useEffect} from 'react'
import {highlightAll} from 'prismjs/components/prism-core'
import 'prismjs/components/prism-sql'
import {MarkdownView} from '../../../../common/MarkdownView'
import {ArticleDto} from 'learndb-share'

interface IArticleContentProps {
  article: ArticleDto
}

const ArticleContent: React.FC<IArticleContentProps> = ({article}) => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({top: 0, behavior: 'auto'})
  }, [])

  useEffect(() => {
    highlightAll()
  }, [article])

  useEffect(() => {
    scrollToTop()
  }, [scrollToTop, article])

  return (
    <div className={'article-content'}>
      <MarkdownView content={article.contentPublic} />
    </div>
  )
}

export {ArticleContent}
