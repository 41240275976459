import React, {useEffect, useState} from 'react'
import {Col, Container, findElementPositionY, LearnDBPlusSvg, MultiLink, Row, useIsomorphicLayoutEffect} from 'ac_ui'
import {URLS} from '../../constants/urls'
import {SubscriptionPeriod, UserDto, YandexPaymentDto} from 'learndb-share'
import {ResponseError} from 'ac-share'
import {useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {CoinsCard} from '../coins/components/components/CoinsCard'
import {useSearchParams} from 'react-router-dom'
import classNames from 'classnames'
import {useLoadPriceMap} from './hooks/useLoadPriceMap'
import {PayCoinsModal} from '../coins/components/components/PayCoinsModal'
import {PaymentTypeCard} from './components/PaymentTypeCard'
import {PaymentModal} from './components/PaymentModal'
import {usePay} from './hooks/usePay'

export const PAYMENT_FORM_ID = 'yandex-payment-form'

const PaymentTypes: React.FC = (): React.ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isScrolled, setIsScrolled] = useState(false)
  let checkout: any | undefined
  const userAuth = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)
  const payment = useSelector<IReduxState, YandexPaymentDto | undefined>((state) => state.payment?.payment)
  const priceMap = useLoadPriceMap()
  const [error, setError] = useState<ResponseError | undefined>(undefined)
  const {pay} = usePay()

  const [subscriptionPeriodIdProp, setSubscriptionPeriodIdProp] = useState<SubscriptionPeriod>(undefined)

  useEffect(() => {
    if (payment) {
      if ((window as any).YooMoneyCheckoutWidget) {
        checkout = new (window as any).YooMoneyCheckoutWidget({
          confirmation_token: payment.confirmation.confirmation_token,
          return_url: window.location.origin + URLS.paymentFinish + '/' + payment.id,
          error_callback: (newError) => {
            //Обработка ошибок инициализации
            console.error(error)
            setError(newError)
          },
        })
        checkout.render(PAYMENT_FORM_ID)
      } else {
        console.error('Error loading YooMoney widget')
      }
    }
  }, [payment])

  useIsomorphicLayoutEffect(() => {
    const toMonth = searchParams.get('toMonth')

    if (toMonth) {
      const element = document.getElementById('month-card')
      if (element) {
        const positionY: number = findElementPositionY(element)
        const headerHeight = 56
        window.scrollTo({top: positionY - headerHeight, left: 0, behavior: 'smooth'})
        setIsScrolled(true)
      }
    }
  }, [searchParams])

  useIsomorphicLayoutEffect(() => {
    if (isScrolled) {
      if (searchParams.has('toMonth')) {
        searchParams.delete('toMonth')
        setSearchParams(searchParams)
        setIsScrolled(false)
      }
    }
  }, [searchParams, isScrolled])

  if (!priceMap) {
    // Идет загрузка данных
    return null
  }

  const priceMonth = priceMap[SubscriptionPeriod.MONTH]
  const priceYear = priceMap[SubscriptionPeriod.YEAR]

  const content = (
    <Row>
      <Col xs={12} sm={12} md={12} lg={4} xl={3}>
        <h2 className={'payment-type-block-header'}>
          Что дает <span className={'text-gradient header-gradient'}>подписка</span>
          <span className={'d-flex'} title={'Подписка'}>
            <LearnDBPlusSvg id={'payment-types-plus'} width={32} height={32} />
          </span>
        </h2>
        <p className={'payment-type-block-description'}>
          Подписка открывает доступ ко всем темам, включая все обновления на период подписки.
        </p>
        <p className={'payment-type-block-additional-description'}>
          Оплатить можно рублями или{' '}
          <MultiLink href={userAuth ? URLS.profile + URLS.coins : URLS.coins} type={userAuth ? 'to' : 'href'}>
            монетами
          </MultiLink>
          .
        </p>
        <CoinsCard className={'mb-x8'} />
      </Col>
      <Col xs={12} md={5} lg={4} xl={{size: 4, offset: 1}} className={'d-flex'}>
        <div id={'month-card'} />
        <PaymentTypeCard
          header={'Месяц'}
          description={<p className={'mb-0'}>Доступ ко&nbsp;всем темам на&nbsp;период действия подписки</p>}
          price={priceMonth}
          duration={'мес'}
          subscriptionPeriodId={SubscriptionPeriod.MONTH}
          setSubscriptionPeriodIdProp={setSubscriptionPeriodIdProp}
          pay={pay}
          userAuth={userAuth}
        />
      </Col>
      <Col xs={12} md={5} lg={4} xl={4} className={'d-flex'}>
        <PaymentTypeCard
          header={'Год'}
          description={
            <div>
              <p className={'mb-0'}>Доступ ко&nbsp;всем темам на&nbsp;период действия подписки</p>
            </div>
          }
          price={priceYear}
          duration={'год'}
          subscriptionPeriodId={SubscriptionPeriod.YEAR}
          setSubscriptionPeriodIdProp={setSubscriptionPeriodIdProp}
          pay={pay}
          userAuth={userAuth}
        />
      </Col>
    </Row>
  )

  const css = classNames('payment-type-section')

  return (
    <section className={css}>
      <Container>{content}</Container>
      <PaymentModal payment={payment} error={error} />
      {!!userAuth && !!subscriptionPeriodIdProp && (
        <PayCoinsModal
          subscriptionPeriodIdProp={subscriptionPeriodIdProp}
          onClose={() => {
            setSubscriptionPeriodIdProp(undefined)
          }}
        />
      )}
    </section>
  )
}

export {PaymentTypes}
