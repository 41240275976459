import React from 'react'
import {Container} from 'ac_ui'
import {StatsCard} from './components/StatsCard'
import {AchievementCategoryDto, TaskDto} from 'learndb-share'
import {ButtonTask} from '../common/buttonTask/ButtonTask'

interface IProfileStatsProps {
  currentTask: TaskDto
  themes: any[]
  achievementCategories: AchievementCategoryDto[]
}

interface IStatsItem {
  itemsTotal: number
  itemsDone: number
}

interface IStats {
  themes: IStatsItem
  tasks: IStatsItem
  achievements: IStatsItem
}

const ProfileStats: React.FC<IProfileStatsProps> = ({
  currentTask,
  themes,
  achievementCategories,
}): React.ReactElement => {
  const stats: IStats = {
    tasks: {itemsDone: 0, itemsTotal: 0},
    themes: {itemsDone: 0, itemsTotal: 0},
    achievements: {itemsDone: 0, itemsTotal: 0},
  }

  for (const category of achievementCategories || []) {
    for (const achievement of category.achievements || []) {
      stats.achievements.itemsTotal++
      if (achievement.userAchievement) {
        stats.achievements.itemsDone++
      }
    }
  }

  themes.forEach((t) => {
    let tasksDone = 0
    let tasksTotal = 0
    if (t.tasks && t.tasks.length) {
      for (const task of t.tasks) {
        tasksTotal++
        if (task.isSolved) {
          tasksDone++
        }
      }
    }
    stats.tasks.itemsDone += tasksDone
    stats.tasks.itemsTotal += tasksTotal

    stats.themes.itemsTotal++
    if (tasksDone === tasksTotal) {
      stats.themes.itemsDone++
    }
  })

  return (
    <section className={'profile-stats'}>
      <Container className={'profile-stats-container'}>
        <h2 className={'profile-stats-h2'}>Статистика</h2>
        <div className={'profile-stats-wrapper'}>
          <div className={'d-flex flex-row align-items-start flex-wrap'}>
            <StatsCard
              current={stats.themes.itemsDone}
              max={stats.themes.itemsTotal}
              type={'themes'}
              className={'mb-x6 mr-x4'}
            />
            <StatsCard
              current={stats.tasks.itemsDone}
              max={stats.tasks.itemsTotal}
              type={'tasks'}
              className={'mb-x6 mr-x4'}
            />
            <StatsCard
              current={stats.achievements.itemsDone}
              max={stats.achievements.itemsTotal}
              type={'achievements'}
              className={'mb-x8'}
            />
          </div>
          {currentTask ? (
            <ButtonTask id={'next-task'} task={currentTask} name={'Текущий урок'} className={'mb-x8'} />
          ) : (
            <div />
          )}
        </div>
      </Container>
    </section>
  )
}

export {ProfileStats}
