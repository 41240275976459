import React from 'react'
import {Col, Container, Row} from 'ac_ui'

const PaymentPageInfo: React.FC = (): React.ReactElement => {
  const content: React.ReactElement = (
    <Row>
      <Col xs={12} sm={12} md={12} lg={9} xl={6}>
        <h2 className={'payment-page-info-title'}>Первые 3 темы бесплатно &#129321;</h2>
        <p className={'payment-page-info-description'}>
          Вы можете зарегистрироваться и оформить подписку в любой момент и при этом ваш прогресс сохранится.
        </p>
      </Col>
    </Row>
  )
  return (
    <section className={'payment-page-info'}>
      <Container>{content}</Container>
    </section>
  )
}

export {PaymentPageInfo}
