import React, {useState} from 'react'
import {AchievementView} from './AchievementView'
import {useLoadAchievementCategories} from '../../hooks/useLoadAchievementCategories'
import {useSearchParams} from 'react-router-dom'
import {findElementPositionY, useIsomorphicLayoutEffect} from 'ac_ui'
import {YmTargetEnum} from '../../../../metrics/ym/YmTargetEnum'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../../redux/types/IReduxState'
import {achievementActions} from '../../redux/actions'
import {ymTarget} from '../../../../metrics/ym/ymTarget'

const AchievementViewContainer: React.FC = (): React.ReactElement => {
  const achievementDispatch = useDispatch()
  const achievementCategories = useLoadAchievementCategories()
  const isOpenAchievementPage = useSelector<IReduxState, boolean>((state) => state.achievement?.isOpenAchievementPage)

  const [isScrolled, setIsScrolled] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useIsomorphicLayoutEffect(() => {
    if (!isOpenAchievementPage) {
      ymTarget(YmTargetEnum.OPEN_ACHIEVEMENT_PAGE)
      achievementDispatch(achievementActions.setIsOpenAchievementPage())
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (achievementCategories?.length) {
      const achievementId = searchParams.get('achievementId')
      if (achievementId) {
        const element = document.getElementById('achievement-card-' + achievementId)
        if (element) {
          const positionY: number = findElementPositionY(element)
          const headerHeight = 56
          window.scrollTo({top: positionY - headerHeight, left: 0, behavior: 'smooth'})
          setIsScrolled(true)
        }
      }
    }
  }, [searchParams, achievementCategories])

  useIsomorphicLayoutEffect(() => {
    if (isScrolled) {
      if (searchParams.has('achievementId')) {
        searchParams.delete('achievementId')
        setSearchParams(searchParams)
        setIsScrolled(false)
      }
    }
  }, [searchParams, isScrolled])

  return <AchievementView achievementCategories={achievementCategories} />
}

export {AchievementViewContainer}
