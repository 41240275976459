import React from 'react'

interface IThreeCoinsSvgProps {
  className?: string
}

const ThreeCoinsSvg: React.FC<IThreeCoinsSvgProps> = ({className}): React.ReactElement => {
  return (
    <svg width="43" height="81" viewBox="0 0 43 81" fill="none" className={className}>
      <path
        d="M38.9135 47.7624L30.4725 42.889L29.6603 44.2958L26.8467 42.6714L26.0344 44.0782L24.6276 43.266L23.8154 44.6728L22.4086 43.8606L21.5964 45.2674L20.1895 44.4552L8.81836 64.1506L10.2252 64.9628L9.41295 66.3697L10.8198 67.1819L10.0075 68.5887L11.4144 69.4009L10.6021 70.8078L13.4158 72.4322L12.6035 73.839L21.0445 78.7124L21.8567 77.3056L24.6703 78.93L25.4825 77.5232L26.8894 78.3354L27.7016 76.9286L29.1084 77.7408L29.9206 76.334L31.3275 77.1463L42.6986 57.4508L41.2918 56.6386L42.104 55.2317L40.6972 54.4195L41.5095 53.0127L40.1026 52.2005L40.9149 50.7937L38.1012 49.1692L38.9135 47.7624Z"
        fill="#FFF0D3"
      />
      <path d="M29.6609 44.2958L36.695 48.3569L35.8827 49.7637L28.8486 45.7026L29.6609 44.2958Z" fill="white" />
      <path d="M25.2228 45.4849L26.035 44.0781L28.8486 45.7026L28.0364 47.1094L25.2228 45.4849Z" fill="white" />
      <path d="M23.0037 46.0795L23.8159 44.6727L25.2228 45.4849L24.4105 46.8918L23.0037 46.0795Z" fill="white" />
      <path d="M19.9724 48.0809L21.5969 45.2673L23.0037 46.0795L21.3793 48.8932L19.9724 48.0809Z" fill="white" />
      <path d="M19.9724 48.0809L11.8502 62.1491L10.4434 61.3369L18.5656 47.2687L19.9724 48.0809Z" fill="white" />
      <path
        d="M34.2583 52.5774L32.8514 51.7651L32.0392 53.1719L33.446 53.9842L32.6338 55.391L34.0406 56.2032L25.9184 70.2714L24.5115 69.4592L23.6993 70.866L22.2925 70.0538L21.4803 71.4606L15.853 68.2117L15.0408 69.6185L20.668 72.8674L21.4803 71.4606L22.8871 72.2728L23.6993 70.866L25.1061 71.6782L25.9184 70.2714L27.3252 71.0836L35.4475 57.0154L34.0406 56.2032L34.8529 54.7964L33.446 53.9842L34.2583 52.5774Z"
        fill="white"
      />
      <path d="M27.0065 52.1421L29.8202 53.7665L29.0079 55.1734L26.1943 53.5489L27.0065 52.1421Z" fill="white" />
      <path d="M26.1943 53.5489L19.6965 64.8034L18.2897 63.9912L24.7875 52.7367L26.1943 53.5489Z" fill="white" />
      <path
        d="M30.4735 42.8895L38.9144 47.7629L38.1022 49.1697L40.9158 50.7941L40.1036 52.201L41.5104 53.0132L40.6982 54.42L42.105 55.2322L41.2928 56.6391L42.6996 57.4513L41.0752 60.2649L42.482 61.0771L34.3597 75.1453L32.9529 74.3331L31.3284 77.1467L29.9216 76.3345L29.1094 77.7413L27.7026 76.9291L26.8903 78.3359L25.4835 77.5237L24.6713 78.9305L21.8577 77.3061L21.0454 78.7129L12.6045 73.8395L13.4167 72.4327L20.4508 76.4938L21.2631 75.087L22.6699 75.8992L23.4821 74.4924L24.8889 75.3047L25.7012 73.8978L27.108 74.7101L28.7324 71.8964L30.1392 72.7087L38.2615 58.6405L36.8547 57.8282L38.4792 55.0146L37.0723 54.2024L37.8846 52.7956L36.4777 51.9833L37.29 50.5765L35.8832 49.7643L36.6954 48.3575L29.6613 44.2963L30.4735 42.8895Z"
        fill="#F0D2A5"
      />
      <path d="M26.0354 44.0787L26.8477 42.6719L29.6613 44.2963L28.8491 45.7031L26.0354 44.0787Z" fill="#F0D2A5" />
      <path d="M23.8164 44.6733L24.6286 43.2665L26.0354 44.0787L25.2232 45.4855L23.8164 44.6733Z" fill="#F0D2A5" />
      <path d="M21.5973 45.2679L22.4096 43.8611L23.8164 44.6733L23.0042 46.0801L21.5973 45.2679Z" fill="#F0D2A5" />
      <path d="M18.5661 47.2693L20.1905 44.4556L21.5973 45.2679L19.9729 48.0815L18.5661 47.2693Z" fill="#F0D2A5" />
      <path d="M10.4438 61.3375L9.03697 60.5252L17.1592 46.4571L18.5661 47.2693L10.4438 61.3375Z" fill="#F0D2A5" />
      <path d="M10.2262 64.9633L8.81934 64.1511L10.4438 61.3375L11.8506 62.1497L10.2262 64.9633Z" fill="#F0D2A5" />
      <path d="M10.8207 67.1824L9.41393 66.3702L10.2262 64.9633L11.633 65.7756L10.8207 67.1824Z" fill="#F0D2A5" />
      <path d="M11.4153 69.4014L10.0085 68.5892L10.8207 67.1824L12.2276 67.9946L11.4153 69.4014Z" fill="#F0D2A5" />
      <path d="M11.4153 69.4014L10.6031 70.8082L13.4167 72.4327L14.229 71.0259L11.4153 69.4014Z" fill="#F0D2A5" />
      <path
        d="M33.6641 50.3589L28.0368 47.11L27.2246 48.5168L25.8178 47.7046L25.0056 49.1114L23.5987 48.2991L22.7865 49.706L21.3797 48.8937L13.2574 62.9619L14.6642 63.7742L13.852 65.181L15.2588 65.9932L14.4466 67.4L15.8534 68.2122L16.6657 66.8054L15.2588 65.9932L16.0711 64.5864L14.6642 63.7742L22.7865 49.706L24.1933 50.5182L25.0056 49.1114L26.4124 49.9236L27.2246 48.5168L32.8519 51.7657L33.6641 50.3589Z"
        fill="#F0D2A5"
      />
      <path d="M19.6969 64.804L22.5106 66.4285L21.6983 67.8353L18.8847 66.2108L19.6969 64.804Z" fill="#F0D2A5" />
      <path d="M22.5106 66.4285L23.9174 67.2407L30.4152 55.9861L29.0084 55.1739L22.5106 66.4285Z" fill="#F0D2A5" />
      <path
        d="M6.9377 19.1304L1.96878 24.0993L2.79693 24.9275L1.14062 26.5838L1.96878 27.4119L1.14062 28.2401L1.96878 29.0682L1.14062 29.8964L1.96878 30.7245L1.14062 31.5527L12.7348 43.1468L13.5629 42.3187L14.3911 43.1468L15.2192 42.3187L16.0474 43.1468L16.8755 42.3187L17.7037 43.1468L19.36 41.4905L20.1881 42.3187L25.1571 37.3498L24.3289 36.5216L25.9852 34.8653L25.1571 34.0372L25.9852 33.209L25.1571 32.3808L25.9852 31.5527L25.1571 30.7245L25.9852 29.8964L14.3911 18.3022L13.5629 19.1304L12.7348 18.3022L11.9066 19.1304L11.0785 18.3022L10.2503 19.1304L9.42215 18.3022L7.76585 19.9586L6.9377 19.1304Z"
        fill="#FFF4DE"
      />
      <path d="M2.7969 24.9279L6.93767 20.7871L7.76582 21.6153L3.62506 25.756L2.7969 24.9279Z" fill="white" />
      <path d="M2.7969 28.2405L1.96875 27.4123L3.62506 25.756L4.45321 26.5842L2.7969 28.2405Z" fill="white" />
      <path d="M2.7969 29.8968L1.96875 29.0686L2.7969 28.2405L3.62506 29.0686L2.7969 29.8968Z" fill="white" />
      <path d="M3.62506 32.3812L1.96875 30.7249L2.7969 29.8968L4.45321 31.5531L3.62506 32.3812Z" fill="white" />
      <path d="M3.62506 32.3812L11.9066 40.6628L11.0784 41.4909L2.7969 33.2094L3.62506 32.3812Z" fill="white" />
      <path
        d="M9.42213 23.2716L8.59397 24.0997L9.42213 24.9279L10.2503 24.0997L11.0784 24.9279L11.9066 24.0997L20.1881 32.3812L19.36 33.2094L20.1881 34.0376L19.36 34.8657L20.1881 35.6939L16.8755 39.0065L17.7037 39.8346L21.0163 36.522L20.1881 35.6939L21.0163 34.8657L20.1881 34.0376L21.0163 33.2094L20.1881 32.3812L21.0163 31.5531L12.7347 23.2716L11.9066 24.0997L11.0784 23.2716L10.2503 24.0997L9.42213 23.2716Z"
        fill="white"
      />
      <path d="M7.76582 28.2405L9.42213 26.5842L10.2503 27.4123L8.59397 29.0686L7.76582 28.2405Z" fill="white" />
      <path d="M8.59397 29.0686L15.2192 35.6939L14.391 36.522L7.76582 29.8968L8.59397 29.0686Z" fill="white" />
      <path
        d="M1.96878 24.0993L6.9377 19.1304L7.76585 19.9586L9.42215 18.3022L10.2503 19.1304L11.0785 18.3022L11.9066 19.1304L12.7348 18.3022L13.5629 19.1304L14.3911 18.3022L16.0474 19.9586L16.8755 19.1304L25.1571 27.4119L24.3289 28.2401L25.9852 29.8964L25.1571 30.7245L25.9852 31.5527L25.1571 32.3808L25.9852 33.209L25.1571 34.0372L25.9852 34.8653L24.3289 36.5216L25.1571 37.3498L20.1881 42.3187L19.36 41.4905L23.5008 37.3498L22.6726 36.5216L23.5008 35.6935L22.6726 34.8653L23.5008 34.0372L22.6726 33.209L23.5008 32.3808L21.8444 30.7245L22.6726 29.8964L14.3911 21.6149L13.5629 22.443L11.9066 20.7867L11.0785 21.6149L10.2503 20.7867L9.42215 21.6149L8.594 20.7867L7.76585 21.6149L6.9377 20.7867L2.79693 24.9275L1.96878 24.0993Z"
        fill="#F4DDBC"
      />
      <path d="M1.96878 27.4119L1.14062 26.5838L2.79693 24.9275L3.62508 25.7556L1.96878 27.4119Z" fill="#F4DDBC" />
      <path d="M1.96878 29.0682L1.14062 28.2401L1.96878 27.4119L2.79693 28.2401L1.96878 29.0682Z" fill="#F4DDBC" />
      <path d="M1.96878 30.7245L1.14062 29.8964L1.96878 29.0682L2.79693 29.8964L1.96878 30.7245Z" fill="#F4DDBC" />
      <path d="M2.79693 33.209L1.14062 31.5527L1.96878 30.7245L3.62508 32.3808L2.79693 33.209Z" fill="#F4DDBC" />
      <path d="M11.0785 41.4905L10.2503 42.3187L1.96878 34.0372L2.79693 33.209L11.0785 41.4905Z" fill="#F4DDBC" />
      <path d="M13.5629 42.3187L12.7348 43.1468L11.0785 41.4905L11.9066 40.6624L13.5629 42.3187Z" fill="#F4DDBC" />
      <path d="M15.2192 42.3187L14.3911 43.1468L13.5629 42.3187L14.3911 41.4905L15.2192 42.3187Z" fill="#F4DDBC" />
      <path d="M16.8755 42.3187L16.0474 43.1468L15.2192 42.3187L16.0474 41.4905L16.8755 42.3187Z" fill="#F4DDBC" />
      <path d="M16.8755 42.3187L17.7037 43.1468L19.36 41.4905L18.5318 40.6624L16.8755 42.3187Z" fill="#F4DDBC" />
      <path
        d="M7.76585 23.2712L4.45324 26.5838L5.28139 27.4119L4.45324 28.2401L5.28139 29.0682L4.45324 29.8964L5.28139 30.7245L4.45324 31.5527L12.7348 39.8342L13.5629 39.0061L14.3911 39.8342L15.2192 39.0061L16.0474 39.8342L16.8755 39.0061L16.0474 38.1779L15.2192 39.0061L14.3911 38.1779L13.5629 39.0061L5.28139 30.7245L6.10954 29.8964L5.28139 29.0682L6.10954 28.2401L5.28139 27.4119L8.594 24.0993L7.76585 23.2712Z"
        fill="#F4DDBC"
      />
      <path d="M15.2192 35.6935L16.8755 34.0372L17.7037 34.8653L16.0474 36.5216L15.2192 35.6935Z" fill="#F4DDBC" />
      <path d="M16.8755 34.0372L17.7037 33.209L11.0785 26.5838L10.2503 27.4119L16.8755 34.0372Z" fill="#F4DDBC" />
      <path
        d="M36.4834 5.43431L31.7769 2.71705L31.3241 3.50146L29.7552 2.5957L29.3024 3.38011L28.518 2.92723L28.0651 3.71164L27.2807 3.25876L26.8278 4.04317L26.0434 3.59029L19.7031 14.572L20.4875 15.0248L20.0347 15.8092L20.8191 16.2621L20.3662 17.0465L21.1506 17.4994L20.6977 18.2838L22.2665 19.1896L21.8136 19.974L26.5201 22.6912L26.973 21.9068L28.5418 22.8126L28.9946 22.0282L29.779 22.4811L30.2319 21.6966L31.0163 22.1495L31.4692 21.3651L32.2536 21.818L38.5939 10.8363L37.8095 10.3834L38.2624 9.59903L37.478 9.14616L37.9308 8.36175L37.1464 7.90888L37.5993 7.12447L36.0305 6.21872L36.4834 5.43431Z"
        fill="#FFF7E9"
      />
      <path d="M31.3236 3.50172L35.2456 5.7661L34.7927 6.55051L30.8707 4.28612L31.3236 3.50172Z" fill="white" />
      <path d="M28.849 4.16478L29.3019 3.38037L30.8707 4.28612L30.4178 5.07053L28.849 4.16478Z" fill="white" />
      <path d="M27.6117 4.49631L28.0646 3.7119L28.849 4.16478L28.3961 4.94918L27.6117 4.49631Z" fill="white" />
      <path d="M25.9216 5.61224L26.8273 4.04343L27.6117 4.49631L26.706 6.06512L25.9216 5.61224Z" fill="white" />
      <path d="M25.9216 5.61224L21.3928 13.4563L20.6084 13.0034L25.1372 5.15936L25.9216 5.61224Z" fill="white" />
      <path
        d="M33.887 8.11932L33.1026 7.66644L32.6497 8.45085L33.4341 8.90372L32.9812 9.68813L33.7656 10.141L29.2369 17.9851L28.4525 17.5322L27.9996 18.3166L27.2152 17.8637L26.7623 18.6481L23.6247 16.8366L23.1718 17.621L26.3094 19.4325L26.7623 18.6481L27.5467 19.101L27.9996 18.3166L28.784 18.7695L29.2369 17.9851L30.0213 18.4379L34.55 10.5939L33.7656 10.141L34.2185 9.3566L33.4341 8.90372L33.887 8.11932Z"
        fill="white"
      />
      <path d="M29.8436 7.87662L31.4124 8.78238L30.9595 9.56678L29.3907 8.66103L29.8436 7.87662Z" fill="white" />
      <path d="M29.3907 8.66103L25.7677 14.9363L24.9833 14.4834L28.6063 8.20815L29.3907 8.66103Z" fill="white" />
      <path
        d="M31.7769 2.71705L36.4834 5.43431L36.0305 6.21872L37.5993 7.12447L37.1464 7.90888L37.9308 8.36175L37.478 9.14616L38.2624 9.59903L37.8095 10.3834L38.5939 10.8363L37.6881 12.4051L38.4725 12.858L33.9438 20.7021L33.1594 20.2492L32.2536 21.818L31.4692 21.3651L31.0163 22.1495L30.2319 21.6966L29.779 22.4811L28.9946 22.0282L28.5418 22.8126L26.973 21.9068L26.5201 22.6912L21.8136 19.974L22.2665 19.1896L26.1885 21.4539L26.6414 20.6695L27.4258 21.1224L27.8787 20.338L28.6631 20.7909L29.116 20.0065L29.9004 20.4594L30.8061 18.8906L31.5906 19.3434L36.1193 11.4994L35.3349 11.0465L36.2407 9.47769L35.4563 9.02481L35.9091 8.2404L35.1247 7.78753L35.5776 7.00312L34.7932 6.55025L35.2461 5.76584L31.3241 3.50146L31.7769 2.71705Z"
        fill="#F7E8D2"
      />
      <path d="M29.3024 3.38011L29.7552 2.5957L31.3241 3.50146L30.8712 4.28586L29.3024 3.38011Z" fill="#F7E8D2" />
      <path d="M28.0651 3.71164L28.518 2.92723L29.3024 3.38011L28.8495 4.16451L28.0651 3.71164Z" fill="#F7E8D2" />
      <path d="M26.8278 4.04317L27.2807 3.25876L28.0651 3.71164L27.6122 4.49604L26.8278 4.04317Z" fill="#F7E8D2" />
      <path d="M25.1376 5.1591L26.0434 3.59029L26.8278 4.04317L25.9221 5.61198L25.1376 5.1591Z" fill="#F7E8D2" />
      <path d="M20.6089 13.0032L19.8245 12.5503L24.3532 4.70622L25.1376 5.1591L20.6089 13.0032Z" fill="#F7E8D2" />
      <path d="M20.4875 15.0248L19.7031 14.572L20.6089 13.0032L21.3933 13.456L20.4875 15.0248Z" fill="#F7E8D2" />
      <path d="M20.8191 16.2621L20.0347 15.8092L20.4875 15.0248L21.2719 15.4777L20.8191 16.2621Z" fill="#F7E8D2" />
      <path d="M21.1506 17.4994L20.3662 17.0465L20.8191 16.2621L21.6035 16.715L21.1506 17.4994Z" fill="#F7E8D2" />
      <path d="M21.1506 17.4994L20.6977 18.2838L22.2665 19.1896L22.7194 18.4052L21.1506 17.4994Z" fill="#F7E8D2" />
      <path
        d="M33.5559 6.88177L30.4183 5.07027L29.9654 5.85467L29.181 5.4018L28.7281 6.1862L27.9437 5.73332L27.4909 6.51773L26.7065 6.06485L22.1777 13.9089L22.9621 14.3618L22.5092 15.1462L23.2936 15.5991L22.8407 16.3835L23.6252 16.8363L24.078 16.0519L23.2936 15.5991L23.7465 14.8147L22.9621 14.3618L27.4909 6.51773L28.2753 6.97061L28.7281 6.1862L29.5125 6.63908L29.9654 5.85467L33.103 7.66618L33.5559 6.88177Z"
        fill="#F7E8D2"
      />
      <path d="M25.7682 14.936L27.337 15.8418L26.8841 16.6262L25.3153 15.7204L25.7682 14.936Z" fill="#F7E8D2" />
      <path d="M27.337 15.8418L28.1214 16.2946L31.7444 10.0194L30.96 9.56652L27.337 15.8418Z" fill="#F7E8D2" />
    </svg>
  )
}

export {ThreeCoinsSvg}
