import React from 'react'
import Renderer from 'markdown-it'
import anchor from 'markdown-it-anchor'
import attrs from 'markdown-it-attrs'

interface IMarkdownViewProps {
  content: string
  className?: string
}

const MarkdownView: React.FC<IMarkdownViewProps> = ({content, className}) => {
  const md = new Renderer().use(attrs).use(anchor)
  md.renderer.rules.table_open = function (tokens, idx, options, env, self) {
    return `<div class='table-wrapper-scroll'><div class='table-wrapper'>` + self.renderToken(tokens, idx, options)
  }
  md.renderer.rules.table_close = function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options) + `</div></div>`
  }
  const renderContent = md.render(content, {
    breaks: true,
  })
  return (
    <div
      dangerouslySetInnerHTML={{__html: renderContent}}
      className={'markdown-container ' + (className ? className : '')}
    />
  )
}

export {MarkdownView}
