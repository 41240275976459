export const constants = {
  POST_LOGIN: 'POST_LOGIN',
  POST_SIGNUP: 'POST_SIGNUP',
  GET_LOGOUT: 'GET_LOGOUT',
  GET_CHECK: 'GET_CHECK',
  POST_USER: 'POST_USER',
  POST_ANONYMOUS_USER: 'POST_ANONYMOUS_USER',
  POST_CHANGE_PASSWORD: 'POST_CHANGE_PASSWORD',
  CHANGE_VISIBILITY_SIGNIN: 'CHANGE_VISIBILITY_SIGNIN',
  CHANGE_VISIBILITY_SIGNUP: 'CHANGE_VISIBILITY_SIGNUP',
  SET_AUTH_STATE: 'SET_AUTH_STATE',
  GET_CONFIRM_EMAIL: 'GET_CONFIRM_EMAIL',

  GET_SECTIONS: 'GET_SECTIONS',

  GET_ARTICLE: 'GET_ARTICLE',
  SET_ARTICLE: 'SET_ARTICLE',
  CLEAR_ARTICLE: 'CLEAR_ARTICLE',
  POST_ARTICLE: 'POST_ARTICLE',
  GET_ARTICLE_BY_NAME: 'GET_ARTICLE_BY_NAME',

  //achievement
  SET_ACHIEVEMENT_CATEGORIES: 'SET_ACHIEVEMENT_CATEGORIES',
  SET_LAST_COMPLETED_ACHIEVEMENTS: 'SET_LAST_COMPLETED_ACHIEVEMENTS',
  SET_IS_NEED_UPDATE_ACHIEVEMENT_CATEGORIES: 'SET_IS_NEED_UPDATE_ACHIEVEMENT_CATEGORIES',
  SET_IS_NEED_UPDATE_LAST_COMPLETED_ACHIEVEMENTS: 'SET_IS_NEED_UPDATE_LAST_COMPLETED_ACHIEVEMENTS',
  SET_IS_OPEN_ACHIEVEMENT_PAGE: 'SET_IS_OPEN_ACHIEVEMENT_PAGE',

  //courses
  SET_COURSES: 'SET_COURSES',

  GET_THEMES: 'GET_THEMES',
  CLEAR_COURSE_PROP: 'CLEAR_COURSE_PROP',
  SET_COURSE_PROP: 'SET_COURSE_PROP',
  GET_API_COURSE: 'GET_API_COURSE',

  GET_TASK: 'GET_TASK',
  GET_API_TASK: 'GET_API_TASK',
  GET_API_ANSWER_VIEW: 'GET_API_ANSWER_VIEW',
  SET_TASK_STATE: 'SET_TASK_STATE',
  CLEAR_TASK_PROP: 'CLEAR_TASK_PROP',
  CLEAR_TASK_STATE: 'CLEAR_TASK_STATE',
  SET_TASK_CONTAINER_HEIGHT: 'SET_TASK_CONTAINER_HEIGHT',

  SET_WINDOW: 'SET_WINDOW',

  SET_PROFILE_STATE: 'SET_PROFILE_STATE',
  GET_API_PROFILE: 'GET_API_PROFILE',
  POST_SAVE_USER_INFO: 'POST_SAVE_USER_INFO',
  POST_USER_SOCIAL: 'POST_USER_SOCIAL',
  POST_USER_SETTING: 'POST_USER_SETTING',
  POST_USER_AVATAR: 'POST_USER_AVATAR',
  GET_API_CERTIFICATE: 'GET_API_CERTIFICATE',
  GET_WALLET_HISTORIES: 'GET_WALLET_HISTORIES',

  GET_ARTICLE_SECTIONS: 'GET_ARTICLE_SECTIONS',

  CONFIRMATION_COOKIE: 'CONFIRMATION_COOKIE',

  SET_PAYMENT_STATE: 'SET_PAYMENT_STATE',
  POST_PAYMENT: 'POST_PAYMENT',
  POST_PAYMENT_COINS: 'POST_PAYMENT_COINS',
  GET_PAYMENT_REFRESH: 'GET_PAYMENT_REFRESH',
  GET_SUBSCRIPTION_PRICE_MAP: 'GET_SUBSCRIPTION_PRICE_MAP',
  GET_SUBSCRIPTION_HISTORIES: 'GET_SUBSCRIPTION_HISTORIES',

  SET_WEB_STATE: 'SET_WEB_STATE',
  SET_LEGAL_STATE: 'SET_LEGAL_STATE',
  SET_FEEDBACK_STATE: 'SET_FEEDBACK_STATE',
}

export const prefixes = {
  SUCCESS: '_SUCCESS',
  UNSUCCESS: '_UNSUCCESS',
}
