import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {LandingDto} from 'learndb-share'
import {useEffect} from 'react'
import {webActions} from '../redux/actions'
import {useRequest, UseRequestTypeEnum} from '../../../hooks/useRequest'

function useLoadLanding(): LandingDto {
  const landingDispatch = useDispatch()
  const landing = useSelector<IReduxState, LandingDto | undefined>((state) => state.web?.landing)

  const [request] = useRequest<LandingDto>({
    type: UseRequestTypeEnum.API_GET,
    url: '/web/landing/info',
    onDispatch: (res) => {
      landingDispatch(
        webActions.setState({
          landing: res,
        }),
      )
    },
  })

  useEffect(() => {
    if (!landing) {
      // console.log('load landing')
      request()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return landing
}

export {useLoadLanding}
