import React from 'react'
import {ControllerInput, IControllerInputProps} from './components/ControllerInput'
import {ControllerTextArea, IControllerTextAreaProps} from './components/ControllerTextArea'
import {ControllerSelect, IControllerSelectProps} from './components/ControllerSelect'
import {ControllerDatePicker, IControllerDatePickerProps} from './components/ControllerDatePicker'
import {ControllerCheckbox, IControllerCheckboxProps} from './components/ControllerCheckbox'

const FormController: {
  Input: React.FC<IControllerInputProps>
  TextArea: React.FC<IControllerTextAreaProps>
  Select: React.FC<IControllerSelectProps>
  DatePicker: React.FC<IControllerDatePickerProps>
  Checkbox: React.FC<IControllerCheckboxProps>
} = {
  Input: ControllerInput,
  TextArea: ControllerTextArea,
  Select: ControllerSelect,
  DatePicker: ControllerDatePicker,
  Checkbox: ControllerCheckbox,
}

export {FormController}
