import React from 'react'
import classNames from 'classnames'

export type EmptyListType =
  | 'NOT_FOUND'
  | 'NOT_FOUND_CERTIFICATE'
  | 'NOT_FOUND_BLOCK'
  | 'NOT_FOUND_ACHIEVEMENTS'
  | 'NOT_FOUND_COINS'
  | 'NOT_FOUND_SUBSCRIPTION'
  | 'CAT_MAIL'
  | 'TABLE'
  | 'DEV'

export enum EmptyListImgEnum {
  NOT_FOUND = '/img/emptyList/notFound.svg',
  NOT_FOUND_CERTIFICATE = '/img/emptyList/notFoundCertificate.svg',
  NOT_FOUND_BLOCK = '/img/emptyList/notFoundBlock.svg',
  NOT_FOUND_ACHIEVEMENTS = '/img/emptyList/achievements.svg',
  NOT_FOUND_COINS = '/img/emptyList/catEat.svg',
  NOT_FOUND_SUBSCRIPTION = '/img/emptyList/catEat.svg',
  CAT_MAIL = '/img/emptyList/catMail.svg',
  TABLE = '/img/emptyList/table.svg',
  DEV = '/img/emptyList/hourGlass.svg',
}

export enum EmptyListAltEnum {
  NOT_FOUND = 'Кот на лупе',
  NOT_FOUND_CERTIFICATE = 'Сертификат',
  NOT_FOUND_BLOCK = 'Страница не найдена',
  NOT_FOUND_ACHIEVEMENTS = 'Кубок',
  NOT_FOUND_COINS = 'Грустный кот',
  NOT_FOUND_SUBSCRIPTION = 'Подписку не оформляли',
  CAT_MAIL = 'Кот на письме',
  TABLE = 'Таблица',
  DEV = 'Часы',
}

interface IEmptyListProps {
  type: EmptyListType
  title?: string | React.ReactElement
  description?: string | React.ReactElement
  button?: React.ReactElement
  additionalContent?: React.ReactElement
  className?: string
  classNameImg?: string
  isMobileImg?: boolean
}

const EmptyList: React.FC<IEmptyListProps> = ({
  type,
  title,
  description,
  button,
  additionalContent,
  className,
  classNameImg,
  isMobileImg,
}) => {
  const css = classNames('empty-list', className)
  const cssImg = classNames('empty-list-img', {'is-mobile': isMobileImg}, classNameImg)
  return (
    <div className={css}>
      <img src={EmptyListImgEnum[type]} className={cssImg} alt={EmptyListAltEnum[type]} />
      <div className={'empty-list-wrapper'}>
        {title && <p className={'empty-list-title'}>{title}</p>}
        {description && <p className={'empty-list-description'}>{description}</p>}
        {button && button}
        {additionalContent && additionalContent}
      </div>
    </div>
  )
}

export {EmptyList}
