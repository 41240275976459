import React from 'react'
import {MultiLink} from 'ac_ui'
import {LandingDto, SubscriptionPeriod, SubscriptionPriceMapDto} from 'learndb-share'
import {getCountString} from 'ac-share'
import {FeedbackButton} from '../../feedback/components/FeedbackButton'
import {QuestionAnswer} from '../../../types/question/QuestionAnswer'
import {QuestionBlock} from '../../common/questionBlock/QuestionBlock'

interface IQuestionsAndAnswersProps {
  landing: LandingDto | undefined
  priceMap: SubscriptionPriceMapDto | undefined
}

const QuestionsAndAnswers: React.FC<IQuestionsAndAnswersProps> = ({landing, priceMap}) => {
  let countTotalTask: number
  let countTotalTheme: number

  if (landing) {
    countTotalTask = landing.courseTaskStatistic.countTotalTask
    countTotalTheme = landing.courseTaskStatistic.countTotalTheme
  }

  const QUESTIONS: QuestionAnswer[] = [
    {
      question: <>Почему стоит выбрать именно ваши курсы?</>,
      answer: (
        <span>
          Выше мы подробно распинаемся 🙃, но&nbsp;можно и&nbsp;кратко:
          <br />
          - онлайн курсы написаны практикующим разработчиком с&nbsp;опытом преподавания;
          <br />- низкая стоимость в&nbsp;сравнении с аналогами:{' '}
          {priceMap ? priceMap[SubscriptionPeriod.MONTH].priceDiscount : '-'} в&nbsp;месяц;
          <br />- много практики на&nbsp;реальных задачах: {countTotalTheme}{' '}
          {getCountString(countTotalTheme, 'тема', 'темы', 'тем')}, {countTotalTask}{' '}
          {getCountString(countTotalTask, 'урок', 'урока', 'уроков')};
          <br />- достижения подталкивают выполнять задания самостоятельно;
          <br />- 3&nbsp;темы бесплатно и&nbsp;без&nbsp;регистрации, этого хватит чтобы принять решение о&nbsp;покупке.
        </span>
      ),
    },
    {
      question: <>Как много времени нужно уделять обучению?</>,
      answer: (
        <span>
          Все зависит от&nbsp;вас. В среднем пользователи уделяют 4&nbsp;часа в&nbsp;неделю на&nbsp;протяжении
          2-х&nbsp;месяцев. При&nbsp;большом желании программу можно освоить и&nbsp;за&nbsp;3&nbsp;недели. Если большую
          часть тем решите самостоятельно, то&nbsp;получите в&nbsp;подарок еще&nbsp;месяц обучения.
        </span>
      ),
    },
    {
      question: <>Я никогда не&nbsp;работал с SQL. У&nbsp;меня получится?</>,
      answer: (
        <span>
          Каких-то&nbsp;специальных знаний не&nbsp;требуется. Есть и&nbsp;сложные задания, но&nbsp;всегда можно задать
          вопрос в&nbsp;общий чат в&nbsp;телеграмме. Рекомендуем сначала попробовать бесплатные темы
          и&nbsp;уже&nbsp;решить, подходит ли&nbsp;вам данное направление.
        </span>
      ),
    },
    {
      question: <>Что делать, если я&nbsp;использую Microsoft SQL, MySQL, Oracle?</>,
      answer: (
        <span>
          В&nbsp;курсе используется PostgreSQL, но&nbsp;80% конструкций будут работать и&nbsp;в&nbsp;других реляционных
          базах данных. СУБД PostgreSQL – бесплатная с&nbsp;открытым исходным кодом. Если хотите проектировать
          устойчивые к&nbsp;нагрузкам приложения для&nbsp;массового рынка, рекомендуем попробовать эту&nbsp;СУБД.
        </span>
      ),
    },
    {
      question: <>У&nbsp;меня есть вопросы по&nbsp;оплате</>,
      answer: (
        <span>
          На&nbsp;популярные вопросы по&nbsp;оплате мы&nbsp;ответили на&nbsp;странице{' '}
          <MultiLink href={'/payment'} type={'href'}>
            «Подписка»
          </MultiLink>
          .
        </span>
      ),
    },
    {
      question: 'Остались вопросы?',
      answer: (
        <div className={'w-100 d-flex flex-column align-items-start'}>
          <span>
            Воспользуйтесь кнопкой ниже или напишите нам на <a href={'mailto:support@learndb.ru'}>support@learndb.ru</a>
          </span>
          <FeedbackButton className={'mt-x4'} />
        </div>
      ),
    },
  ]

  return <QuestionBlock questions={QUESTIONS} />
}

export {QuestionsAndAnswers}
