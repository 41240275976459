import React from 'react'
import classNames from 'classnames'
import {getBorderLineAndIndicatorImgSrc} from '../utils'
import {LevelEnum} from 'learndb-share'

interface ICompletionIndicatorProps {
  image?: string
  isBottomLine?: boolean
  countSolvedTasks: number
  countTotalTasks: number
  levelId: LevelEnum
}

const CompletionIndicator: React.FC<ICompletionIndicatorProps> = ({
  image,
  isBottomLine = true,
  countSolvedTasks,
  countTotalTasks,
  levelId,
}) => {
  const isRightLineCompleted = countSolvedTasks >= countTotalTasks / 2
  const isCompleted = countSolvedTasks === countTotalTasks

  const {indicatorImgSrc, borderLineSrc} = getBorderLineAndIndicatorImgSrc(countSolvedTasks, countTotalTasks, levelId)

  return (
    <div className={'course-theme-indicator'}>
      <div className={'indicator-container'}>
        <img className={'indicator-border'} src={borderLineSrc} width={82} height={95} alt={''} />
        {(image || indicatorImgSrc) && (
          <img className={'indicator-img'} src={image || indicatorImgSrc} width={48} height={48} alt={''} />
        )}
        <div
          className={classNames('indicator-right-line', {
            completed: isRightLineCompleted,
          })}
        />
      </div>
      {isBottomLine && (
        <div
          className={classNames('indicator-bottom-line', {
            completed: isCompleted,
          })}
        />
      )}
    </div>
  )
}

export {CompletionIndicator}
