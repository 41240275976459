import React from 'react'
import {ButtonLink, Container} from 'ac_ui'
import {URLS} from '../../../constants/urls'

const PaymentPendingPage: React.FC = () => {
  return (
    <Container>
      <section className={'payment-status-page'}>
        <img src={'/img/emptyList/hourGlass.svg'} width={288} height={288} className={'image'} alt={'Песочные часы'} />
        <h1 className={'header pending'}>Платеж в обработке, ожидайте…</h1>
        <div className={'payment-status-wrapper'}>
          <p className={'description'}>После обработки платежа подписка активируется</p>
          <ButtonLink href={URLS.profile + URLS.profileI}>В профиль</ButtonLink>
        </div>
      </section>
    </Container>
  )
}

export {PaymentPendingPage}
