import {prefixes} from '../constants'
import {getErrorActionType, getSuccessActionType} from '../utils'
import {getAuthorizationHeader} from '../../component/auth/utils'
import {getBaseApiURL} from '../../api'
import {LSKeysEnum} from '../../../storage/LSKeysEnum'

const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
  mode: 'cors',
  cache: 'no-cache',
}

const unknownErrorMessage = 'Произошла неизвестная ошибка. Обратитесь в службу поддержки.'

const isNotResultAction = (action, method): boolean => {
  return (
    action.type.indexOf(method + '_') !== -1 &&
    action.type.indexOf(prefixes.SUCCESS) === -1 &&
    action.type.indexOf(prefixes.UNSUCCESS) === -1
  )
}

const checkStatus = async (response): Promise<void> => {
  if (response.status >= 300) {
    let error
    try {
      error = await response.json()
    } catch (err) {
      error = {
        errorMessage: unknownErrorMessage,
      }
    }
    throw error
  }
}

const apiQuery = async (store, action, uri, options): Promise<void> => {
  try {
    const response = await fetch(uri, options)

    if (response.status === 401) {
      // localStorage.removeItem(LSKeysEnum.TOKEN)
      localStorage.removeItem(LSKeysEnum.USER)
      localStorage.removeItem(LSKeysEnum.ANONYMOUS)
      window.location.replace('/signin')
    }

    await checkStatus(response)

    const data = await response.json()

    action.onSuccess && action.onSuccess(data)
    store.dispatch({
      ...action,
      type: getSuccessActionType(action.type),
      data,
    })
  } catch (err) {
    action.onError && action.onError(err)

    store.dispatch({
      ...action,
      type: getErrorActionType(action.type),
      error: err,
    })
  }
}

const postData = (store) => (next) => async (action) => {
  if (isNotResultAction(action, 'POST')) {
    await apiQuery(store, action, getBaseApiURL() + action.uri, {
      method: 'POST',
      headers: {...headers, ...getAuthorizationHeader()},
      body: JSON.stringify(action.data),
    })
  }
  next(action)
}

const getData = (store) => (next) => async (action) => {
  if (isNotResultAction(action, 'GET')) {
    const url = new URL(getBaseApiURL() + action.uri)
    if (action.params) {
      for (const param in action.params) {
        url.searchParams.append(param, action.params[param])
      }
    }

    await apiQuery(store, action, url, {
      method: 'GET',
      headers: {...headers, ...getAuthorizationHeader()},
    })
  }
  next(action)
}

export default {
  postData,
  getData,
}
