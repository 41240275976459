import {useEffect, useState} from 'react'
import {useRequest, UseRequestTypeEnum} from '../../../hooks/useRequest'
import {FeedbackThemeDto} from 'learndb-share'

function useLoadFeedbackThemes(): FeedbackThemeDto[] {
  const [feedbackThemes, setFeedbackThemes] = useState<FeedbackThemeDto[]>([])

  const [request] = useRequest<FeedbackThemeDto[]>({
    type: UseRequestTypeEnum.API_GET,
    url: '/support/feedback-theme',
  })

  const load = async (): Promise<void> => {
    const feedbackThemesDB = await request()
    setFeedbackThemes(feedbackThemesDB || [])
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return feedbackThemes
}

export {useLoadFeedbackThemes}
