import React, {useEffect, useState} from 'react'
import {ArticleList} from './ArticleList'
import {useDispatch, useSelector} from 'react-redux'
import {findElementPositionY, useDebounce, useIsomorphicLayoutEffect} from 'ac_ui'
import {IReduxState} from '../../../../redux/types/IReduxState'
import {SectionArticlesDto} from 'learndb-share'
import {StringBooleanMap} from 'ac-share'
import {useSearchParams} from 'react-router-dom'
import {articleActions} from '../../redux/actions'

const ArticleListContainer: React.FC = () => {
  const articleDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const articleSections = useSelector<IReduxState, SectionArticlesDto[] | undefined>(
    (state) => state.article?.articleSections,
  )
  const [collapseMap, setCollapseMap] = useState<StringBooleanMap>({})

  const [searchString, setSearchString] = useState<string>('')
  const debouncedSearchTerm = useDebounce<string>(searchString, 300)

  const loadArticles = async (): Promise<void> => {
    articleDispatch(articleActions.getSections(undefined))
  }

  useIsomorphicLayoutEffect(() => {
    if (searchParams) {
      const sectionId = searchParams.get('sectionId')
      if (sectionId) {
        collapseMap[sectionId] = true
        setCollapseMap({...collapseMap})
        const element = document.getElementById(`list-main-list-${sectionId}`)
        const positionY: number = findElementPositionY(element)
        const headerHeight = 56
        window.scrollTo({top: positionY - headerHeight, left: 0, behavior: 'smooth'})
      }
    }
  }, [])

  const handleChangeSearch = (value: string): void => {
    setSearchString(value)
  }

  const search = async (name: string): Promise<void> => {
    if (name.length === 0) {
      await loadArticles()
    } else {
      articleDispatch(articleActions.findArticleByName(name))
    }
  }

  useEffect(() => {
    search(debouncedSearchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  return (
    <ArticleList
      articleSections={articleSections}
      handleChangeSearch={handleChangeSearch}
      searchValue={searchString}
      collapseMap={collapseMap}
      setCollapseMap={setCollapseMap}
    />
  )
}

export {ArticleListContainer}
