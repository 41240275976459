import {ResponseError, StringAnyMap, StringStringMap} from 'ac-share'

interface RestConfig {
  params?: StringStringMap
  onError?: (error: ResponseError) => void
  options?: StringAnyMap
  isWebUrl?: boolean
}

const INIT_REST_CONFIG: RestConfig = {
  params: undefined,
  onError: undefined,
  options: undefined,
  isWebUrl: undefined,
}

export {INIT_REST_CONFIG, RestConfig}
