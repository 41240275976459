import React, {useState} from 'react'
import {Button, Card, CardBody, Checkbox, Col, COLORS, Container, FormFeedback, Icon, Input, Row} from 'ac_ui'
import {acAccountPlusCenter} from 'ac-icon'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {authActions} from './redux/actions'

const SignUpPage: React.FC = () => {
  const authDispatch = useDispatch()

  const userAnonymous = useSelector<IReduxState, any | undefined>((state) => state.auth?.userAnonymous)

  const [email, setEmail] = useState<string | undefined>('')
  const [firstName, setFirstName] = useState<string | undefined>('')
  const [lastName, setLastName] = useState<string | undefined>('')
  const [password, setPassword] = useState<string | undefined>('')
  const [repeatPassword, setRepeatPassword] = useState<string | undefined>('')
  const [confirmLicense, setConfirmLicense] = useState<boolean>(false)
  const [saveAnonymousProgress, setSaveAnonymousProgress] = useState<boolean>(true)
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false)
  const [isSuccessRegistred, setIsSuccessRegistred] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const isSubmitDisabled = (): boolean => {
    return !(
      !isSendingRequest &&
      email &&
      email !== '' &&
      firstName &&
      firstName !== '' &&
      lastName &&
      lastName !== '' &&
      password &&
      password !== '' &&
      repeatPassword === password &&
      confirmLicense
    )
  }

  const handleSubmit = (evt): void => {
    evt.preventDefault()
    if (!isSubmitDisabled()) {
      setIsSendingRequest(true)
      setIsSuccessRegistred(false)

      const user = {
        email,
        firstName,
        lastName,
        middleName: undefined,
        password,
        saveAnonymousProgress,
      }

      authDispatch(
        authActions.createUser(
          user,
          () => {
            setIsSendingRequest(false)
            setIsSuccessRegistred(true)
          },
          (err) => {
            setIsSendingRequest(false)
            setErrorMessage(err.errorMessage)
          },
        ),
      )
    }
  }

  return (
    <Container>
      <div className={'background-image-registration'}>
        <Row>
          <Col md={{size: 4, offset: 4}}>
            <form className={'sign-up-form'} onSubmit={handleSubmit}>
              <Card className={'sign-in-card'}>
                <CardBody className={'px-xs-0 py-xs-x10 p-sm-x10'}>
                  <div className={'caption-authorization'}>
                    <Icon
                      path={acAccountPlusCenter}
                      color={COLORS.blue}
                      size={1.5}
                      className={'mr-x2'}
                      title={undefined}
                    />
                    <h1 className={'caption-authorization-h1'}>Регистрация</h1>
                  </div>
                  <Input
                    id={'signUpFirstName'}
                    name={'firstName'}
                    type={'text'}
                    label={'Имя'}
                    value={firstName}
                    required
                    onChange={(value) => {
                      setFirstName(value)
                    }}
                  />
                  <Input
                    id={'signUpLastName'}
                    name={'lastName'}
                    type={'text'}
                    label={'Фамилия'}
                    value={lastName}
                    required
                    onChange={(value) => {
                      setLastName(value)
                    }}
                  />
                  <Input
                    id={'signUpEmail'}
                    name={'email'}
                    type={'text'}
                    label={'Email'}
                    value={email}
                    required
                    onChange={(value) => {
                      setEmail(value)
                    }}
                  />
                  <Input
                    id={'signUpPassword'}
                    name={'password'}
                    type={'password'}
                    label={'Пароль'}
                    value={password}
                    required
                    onChange={(value) => {
                      setPassword(value)
                    }}
                  />
                  <Input
                    id={'signUpRepeatPassword'}
                    name={'repeatPassword'}
                    type={'password'}
                    label={'Повторите пароль'}
                    value={repeatPassword}
                    required
                    onChange={(value) => {
                      setRepeatPassword(value)
                    }}
                  />
                  <Checkbox
                    id={'signUpConfirmLicense'}
                    color={'primary'}
                    name={'confirmLicense'}
                    type={'checkbox'}
                    label={'Согласен с пользовательским соглашением и политикой о данных пользователей.'}
                    checked={confirmLicense}
                    className={'checkbox-confirm-license mb-x10'}
                    onChange={(value) => {
                      setConfirmLicense(value)
                    }}
                  />
                  {userAnonymous ? (
                    <Checkbox
                      id={'signUpSaveProgress'}
                      color={'primary'}
                      name={'saveAnonymousProgress'}
                      type={'checkbox'}
                      label={'Сохранить прогресс'}
                      className={'checkbox-confirm-license mb-x10 mt-n-x4'}
                      checked={saveAnonymousProgress}
                      onChange={(value) => {
                        setSaveAnonymousProgress(value)
                      }}
                    />
                  ) : null}
                  {isSuccessRegistred ? (
                    <FormFeedback
                      message={
                        'Спасибо за регистрацию! На ' +
                        email +
                        ' было отправлено сообщение с ссылкой для подтверждения адреса электронной почты'
                      }
                      type={'success'}
                      isShow={!errorMessage}
                      className={'mb-x6 mt-n-x4'}
                    />
                  ) : (
                    <FormFeedback
                      message={errorMessage}
                      type={'danger'}
                      isShow={!!errorMessage}
                      className={'mb-x6 mt-n-x4'}
                    />
                  )}
                  <Button
                    type={'submit'}
                    color={'primary'}
                    size={'md'}
                    className={'w-100'}
                    disabled={isSubmitDisabled()}>
                    Зарегистрироваться
                  </Button>
                </CardBody>
              </Card>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export {SignUpPage}
