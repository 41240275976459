import React, {useEffect} from 'react'
import {UserInfo} from './UserInfo'
import {UserDto} from 'learndb-share'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {courseActions} from '../courses/redux/actions'
import {AchievementBlock} from '../achievement/components/components/AchievementBlock'
import {ProfileStats} from './ProfileStats'
import {useLoadAchievementCategories} from '../achievement/hooks/useLoadAchievementCategories'
import {useLoadCourses} from '../courses/hooks/useLoadCourses'

const Profile: React.FC = () => {
  const courseDispatch = useDispatch()
  const courses = useLoadCourses()

  const user = useSelector<IReduxState, UserDto | undefined>((state) => state.auth?.user)
  const themes = courses ? courses[0]?.themes : undefined
  const currentTask = useSelector<IReduxState, any | undefined>((state) => state.course?.nextTask)

  const achievementCategories = useLoadAchievementCategories()

  const loadData = async (): Promise<void> => {
    courseDispatch(courseActions.getNextTask())
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (themes) {
    return (
      <div className={'profile-view'}>
        <UserInfo user={user} className={'w-100'} />
        <ProfileStats currentTask={currentTask} themes={themes} achievementCategories={achievementCategories} />
        <AchievementBlock isPaddingBottom={false} isBackgroundWhite={false} />
      </div>
    )
  } else {
    return null
  }
}

export {Profile}
