import {constants} from '../../../redux/constants'
import {AchievementCategoryDto, AchievementDto} from 'learndb-share'

export const achievementActions = {
  setAchievementCategories: (achievementCategories: AchievementCategoryDto[]) => ({
    type: constants.SET_ACHIEVEMENT_CATEGORIES,
    payload: achievementCategories,
  }),
  setLastCompletedAchievements: (lastCompletedAchievements: AchievementDto[]) => ({
    type: constants.SET_LAST_COMPLETED_ACHIEVEMENTS,
    payload: lastCompletedAchievements,
  }),
  setIsNeedUpdateAchievementCategories: () => ({
    type: constants.SET_IS_NEED_UPDATE_ACHIEVEMENT_CATEGORIES,
  }),
  setIsNeedUpdateLastCompletedAchievements: () => ({
    type: constants.SET_IS_NEED_UPDATE_LAST_COMPLETED_ACHIEVEMENTS,
  }),
  setIsOpenAchievementPage: () => ({
    type: constants.SET_IS_OPEN_ACHIEVEMENT_PAGE,
  }),
}
