import reducers from './reducers'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import {applyMiddleware, createStore} from 'redux'

declare let USE_DEVTOOLS: boolean

let middlewares
import apiMiddleware from './middlewares'

if (USE_DEVTOOLS) {
  middlewares = composeWithDevTools(applyMiddleware(thunk, apiMiddleware.getData, apiMiddleware.postData))
} else {
  middlewares = applyMiddleware(thunk, apiMiddleware.getData, apiMiddleware.postData)
}

function createAppStore(preloadedState?: any, taskReducers?: any): any {
  return createStore(taskReducers ? taskReducers : reducers, preloadedState, middlewares)
}

export {createAppStore}
