import React from 'react'
import {Col, Container, Row} from 'ac_ui'
import {ArticleSection} from './components/ArticleSection'
import {SearchPanelCommon} from '../../../common/SearchPanelCommon'
import {ArticleListHeader} from './components/ArticleListHeader'
import {SectionArticlesDto} from 'learndb-share'
import {StringBooleanMap} from 'ac-share'
import {EmptyList} from '../../../common/EmptyList'

interface IArticleListProps {
  articleSections: SectionArticlesDto[]
  handleChangeSearch: (evt: any) => void
  searchValue: string
  collapseMap: StringBooleanMap
  setCollapseMap: (map: StringBooleanMap) => void
}

const ArticleList: React.FC<IArticleListProps> = ({
  articleSections,
  handleChangeSearch,
  searchValue,
  collapseMap,
  setCollapseMap,
}) => {
  const renderArticleSectionList = (): React.ReactNode => {
    if (articleSections && articleSections.length > 0) {
      const items: React.ReactNode = articleSections.map((articleSection, index) => (
        <li
          key={articleSection.sectionName + index + articleSection.articles[0].articleId}
          className={'section-list mb-0'}>
          <ArticleSection
            idPrefix={'main-list'}
            articleSection={articleSection}
            sectionNumber={articleSection.number}
            collapseMap={collapseMap}
            setCollapseMap={setCollapseMap}
            onClickHref={undefined}
            isInnerArticle={false}
          />
        </li>
      ))
      return <ul className={'article-list-page article-list-page-padding mb-0'}>{items}</ul>
    } else {
      return (
        <div className={'article-list-page-padding'}>
          <EmptyList
            type={'NOT_FOUND'}
            title={'Ничего не найдено'}
            description={'Попробуйте поискать по другим ключевым словам'}
          />
        </div>
      )
    }
  }

  return (
    <div>
      <ArticleListHeader />
      <Container size={'big'}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={{size: 8, offset: 2}} xl={{size: 6, offset: 3}}>
            <div className={'w-100 d-flex justify-content-start'}>
              <SearchPanelCommon // TODO:: Ещё будет доработан +
                searchId={'article-search'}
                handleChangeString={handleChangeSearch}
                value={searchValue}
                className={'article-list-search-panel'}
                classNameInput={'w-100'}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={{size: 8, offset: 2}} xl={{size: 6, offset: 3}}>
            {renderArticleSectionList()}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export {ArticleList}
