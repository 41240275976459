import {constants} from '../../../redux/constants'
import {LandingDto} from 'learndb-share'

export interface IWebState {
  landing?: LandingDto | undefined
}

const INITIAL_STATE: IWebState = {
  landing: undefined,
}

function webReducers(state: IWebState = INITIAL_STATE, action): IWebState {
  switch (action.type) {
    case constants.SET_WEB_STATE: {
      return {...state, ...action.state}
    }

    default:
      return state
  }
}

export {webReducers}
