import React from 'react'
import {Col, Container, Row} from 'ac_ui'

const Author: React.FC = () => {
  return (
    <section className={'author-landing'}>
      <Container className={'author-landing-container'}>
        <h2 className={'landing-h2 author-landing-h2'}>Автор курса</h2>
        <p className={'author-landing-description'}>Практикующий разработчик с опытом преподавания</p>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5} className={'author-landing-info-wrapper'}>
            <p className={'author-landing-fio'}>Ледомский Петр</p>
            <p className={'author-landing-p'}>С&nbsp;отличием закончил Алтайский государственный Университет.</p>
            <p className={'author-landing-p'}>
              В&nbsp;2014 году вошел в&nbsp;тройку лидеров на&nbsp;международном финале олимпиады Oracle
              по&nbsp;программированию SQL, проходившей в&nbsp;рамках IT-Планеты.
            </p>
            <p className={'author-landing-p mb-0'}>
              В&nbsp;IT сфере более 10 лет. Занимал должности от&nbsp;разработчика до&nbsp;начальника отдела
              и&nbsp;архитектора.
            </p>
            {/*<p className={'author-landing-p mb-0'}>*/}
            {/*  В&nbsp;данный момент работает руководителем направления разработки в компании, которая является лидером*/}
            {/*  по&nbsp;объему торгов акциями в&nbsp;России. Точное название называть нельзя, но&nbsp;догадаться несложно)*/}
            {/*</p>*/}
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xl={7} className={'author-landing-avatar-wrapper'}>
            <div className={'author-landing-avatar'}>
              <img src={'/img/landing/author.png'} width={360} height={360} className={'img'} alt={'Автор курсов'} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export {Author}
