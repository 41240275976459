import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {metricsHitPage} from '../../metrics/hitPage/hitPage'

/**
 * Вызывает metricsHitPage если адрес страницы поменялся. При первоначальной загрузке SPA не вызывается.
 */
const useMetricsUpdater = (): void => {
  const location = useLocation()

  useEffect(() => {
    const newUrl = location.pathname
    metricsHitPage(newUrl)
  }, [location])
}

export {useMetricsUpdater}
