import React from 'react'
import {Menu} from './menu/Menu'
import {Route, Routes, useParams} from 'react-router-dom'
import {URLS} from '../../constants/urls'
import {Profile} from './Profile'
import {Preferences} from './Preferences'
import {CertificatePage} from './CertificatePage'
import {NotFoundNavigator} from '../notFound/NotFoundNavigator'
import {ILayoutProps} from '../Layout'
import {CoinsViewContainer} from '../coins/components/view/CoinsViewContainer'
import {AchievementViewContainer} from '../achievement/components/view/AchievementViewContainer'
import {SubscriptionViewContainer} from './subscription/SubscriptionViewContainer'

const ProfileLayout: React.FC<ILayoutProps> = ({staticContext}) => {
  const {url} = useParams()
  return (
    <div className={'profile-layout'}>
      <Menu currentUrl={url} />
      <Routes>
        <Route path={URLS.profileI} element={<Profile />} />
        <Route path={URLS.profilePreferences} element={<Preferences />} />
        <Route path={URLS.profileSubscription} element={<SubscriptionViewContainer />} />
        <Route path={URLS.profileCertificate} element={<CertificatePage />} />
        <Route path={URLS.profileAchievement} element={<AchievementViewContainer />} />
        <Route path={URLS.coins} element={<CoinsViewContainer />} />
        <Route path={'*'} element={<NotFoundNavigator staticContext={staticContext} />} />
      </Routes>
    </div>
  )
}

export {ProfileLayout}
