import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {achievementActions} from '../redux/actions'
import {useEffect} from 'react'
import {useRequest, UseRequestTypeEnum} from '../../../hooks/useRequest'
import {AchievementDto} from 'learndb-share'

function useLoadLastCompletedAchievements(): AchievementDto[] {
  const achievementDispatch = useDispatch()
  const lastCompletedAchievements = useSelector<IReduxState, AchievementDto[] | undefined>(
    (state) => state.achievement?.lastCompletedAchievements,
  )
  const isNeedUpdateLastCompletedAchievements = useSelector<IReduxState, boolean>(
    (state) => state.achievement?.isNeedUpdateLastCompletedAchievements,
  )

  const [request] = useRequest<AchievementDto[]>({
    type: UseRequestTypeEnum.API_GET,
    url: '/achievement/last-completed',
    onDispatch: (res) => {
      achievementDispatch(achievementActions.setLastCompletedAchievements(res))
    },
  })

  useEffect(() => {
    if (!lastCompletedAchievements || isNeedUpdateLastCompletedAchievements) {
      // console.log('load lastCompletedAchievements')
      request()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedUpdateLastCompletedAchievements])

  return lastCompletedAchievements
}

export {useLoadLastCompletedAchievements}
