import {combineReducers} from 'redux'
import {authReducers} from '../../component/auth/redux/reducers'
import {courseReducers} from '../../component/courses/redux/reducers'
// import {taskReducers} from "../../component/task/redux/reducers";
import {profileReducers} from '../../component/profile/redux/reducers'
import {articleReducers} from '../../component/article/redux/reducers'
import {paymentReducers} from '../../component/payment/redux/reducers'
import {achievementReducers} from '../../component/achievement/redux/reducers'
import {webReducers} from '../../component/landing/redux/reducers'
import {legalReducers} from '../../component/legal/redux/reducers'
import {feedbackReducers} from '../../component/feedback/redux/reducers'

export default combineReducers({
  auth: authReducers,
  course: courseReducers,
  // task: taskReducers,
  profile: profileReducers,
  payment: paymentReducers,
  article: articleReducers as any,
  achievement: achievementReducers,
  web: webReducers,
  feedback: feedbackReducers,
  legal: legalReducers,
})
