import React, {useEffect, useState} from 'react'
import {ArticleView} from './ArticleView'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../../redux/types/IReduxState'
import {ArticleDto, SectionArticlesDto} from 'learndb-share'
import {ButtonLink, useDebounce} from 'ac_ui'
import {articleActions} from '../../redux/actions'
import {EmptyList} from '../../../common/EmptyList'

const ArticleViewContainer: React.FC = () => {
  const {articleId} = useParams()
  const articleDispatch = useDispatch()
  const articleSections = useSelector<IReduxState, SectionArticlesDto[] | undefined>(
    (state) => state.article?.articleSections,
  )
  const loaded = useSelector<IReduxState, boolean>((state) => state.article?.loaded)

  const [searchString, setSearchString] = useState<string>('')
  const debouncedSearchTerm = useDebounce<string>(searchString, 300)

  const article = useSelector<IReduxState, ArticleDto | undefined>((state) => {
    if (state && state.article && state.article.article) {
      return state.article.article
    } else {
      return undefined
    }
  })

  const [isLoad, setIsLoad] = useState<boolean>(loaded)

  const loadArticles = async (): Promise<void> => {
    articleDispatch(articleActions.getSections(undefined))
  }

  const loadData = async (): Promise<void> => {
    articleDispatch(
      articleActions.getArticle(articleId, () => {
        if (!isLoad) {
          setIsLoad(true)
        }
      }),
    )
  }

  useEffect(() => {
    loadData()
    loadArticles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId])

  const handleChangeSearch = (value: string): void => {
    setSearchString(value)
  }

  const search = async (name: string): Promise<void> => {
    if (name.length === 0) {
      await loadArticles()
    } else {
      articleDispatch(articleActions.findArticleByName(name))
    }
  }

  useEffect(() => {
    search(debouncedSearchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  if (article && article.articleId) {
    return (
      <ArticleView
        article={article}
        articleSections={articleSections}
        handleChangeSearch={handleChangeSearch}
        searchString={searchString}
      />
    )
  } else {
    if (isLoad) {
      return (
        <EmptyList
          className={'article-not-article'}
          type={'NOT_FOUND_BLOCK'}
          title={'Упс… Страница не найдена'}
          description={'Возможно ее никогда не существовало'}
          button={
            <div className={'d-flex flex-column align-items-center mt-x6'}>
              <ButtonLink href={'/'}>На главную</ButtonLink>
            </div>
          }
        />
      )
    } else {
      return <></>
    }
  }
}

export {ArticleViewContainer}
