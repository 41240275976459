import React from 'react'
import {Icon} from 'ac_ui'
import {acClose, acTreeView} from 'ac-icon'

interface ISectionNavOpenButtonProps {
  isCloseButton: boolean
  onClick: () => void
}

const SectionNavOpenButton: React.FC<ISectionNavOpenButtonProps> = ({isCloseButton, onClick}): React.ReactElement => {
  return (
    <div className={'section-nav-open-button'} onClick={onClick}>
      <Icon path={isCloseButton ? acClose : acTreeView} size={'24px'} title={undefined} />
    </div>
  )
}

export {SectionNavOpenButton}
