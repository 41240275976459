import React, {useEffect} from 'react'
import {Container} from 'ac_ui'
import {SubscriptionPeriod, YandexPaymentDto, YandexPaymentStatus} from 'learndb-share'
import {PaymentErrorPage} from './status/PaymentErrorPage'
import {PaymentSuccessPage} from './status/PaymentSuccessPage'
import {PaymentPendingPage} from './status/PaymentPendingPage'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../redux/types/IReduxState'
import {paymentActions} from './redux/actions'
import {useLoadPriceMap} from './hooks/useLoadPriceMap'
import {ymTarget} from '../../metrics/ym/ymTarget'
import {YmTargetEnum} from '../../metrics/ym/YmTargetEnum'

const PaymentFinishPage: React.FC = () => {
  const paymentDispatch = useDispatch()
  const {paymentId} = useParams()
  const priceMap = useLoadPriceMap()
  const paymentInfo = useSelector<IReduxState, YandexPaymentDto | undefined>((state) => state.payment?.paymentInfo)

  useEffect(() => {
    paymentDispatch(paymentActions.refreshPaymentInfo(paymentId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderStatusPage = (): React.ReactNode => {
    if (!paymentInfo) {
      return null
    }

    if (paymentInfo.status === YandexPaymentStatus.succeeded) {
      if (priceMap) {
        const priceYear = priceMap[SubscriptionPeriod.YEAR]
        if (priceYear?.priceDiscount && paymentInfo?.amount?.value) {
          const sum = parseInt(paymentInfo.amount.value.toString(), 10)
          if (sum) {
            if (priceYear.priceDiscount === sum) {
              ymTarget(YmTargetEnum.PAY_MONEY_YEARS)
            } else {
              ymTarget(YmTargetEnum.PAY_MONEY_MONTH)
            }
          }
        }
      }
      return <PaymentSuccessPage />
    } else if (paymentInfo.status === YandexPaymentStatus.pending) {
      return <PaymentPendingPage />
    } else {
      return <PaymentErrorPage />
    }
  }

  return <Container>{renderStatusPage()}</Container>
}

export {PaymentFinishPage}
