import {LSKeysEnum} from './LSKeysEnum'
import {isServer} from 'ac-share'

function setStorageData(storageKey: string | LSKeysEnum, value: any, isNotStringify?: boolean): void {
  if (!isServer) {
    try {
      localStorage.setItem(`${storageKey}`, isNotStringify ? value : JSON.stringify(value))
    } catch (e) {
      console.error(e)
    }
  }
}

function getStorageData(storageKey: string | LSKeysEnum, isNotParse?: boolean, defaultValue?: any): any {
  if (!isServer) {
    try {
      const value: any = localStorage.getItem(`${storageKey}`)
      if (value) {
        return isNotParse ? value : JSON.parse(value)
      } else {
        return defaultValue ? defaultValue : null
      }
    } catch (e) {
      console.error(e)
    }
  } else {
    return null
  }
}

function clearStorageData(): void {
  try {
    localStorage.clear()
  } catch (e) {
    console.error(e)
  }
}

function removeStorageData(storageKey: string | LSKeysEnum): void {
  try {
    localStorage.removeItem(`${storageKey}`)
  } catch (e) {
    console.error(e)
  }
}

export {setStorageData, getStorageData, clearStorageData, removeStorageData}
