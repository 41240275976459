import React from 'react'
import {PaymentPageInfo} from '../../PaymentPageInfo'
import {PaymentTypes} from '../../PaymentTypes'
import {QuestionsPaymentAndSubscription} from '../../QuestionsPaymentAndSubscription'
import {Container} from 'ac_ui'

const PaymentView: React.FC = (): React.ReactElement => {
  return (
    <div className={'payment-view'}>
      <section className={'profile-view-header'}>
        <Container>
          <h1 className={'profile-view-header-h1'}>Стоимость подписки</h1>
        </Container>
      </section>
      <PaymentPageInfo />
      <PaymentTypes />
      <QuestionsPaymentAndSubscription />
    </div>
  )
}

export {PaymentView}
