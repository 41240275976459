import {ResponseError, StringAnyMap} from 'ac-share'
import {ERROR_MESSAGE_DEFAULT} from '../../api/constants'
import {INIT_REST_CONFIG, RestConfig} from './RestConfig'

function getHeaders(): any {
  return Object.assign(
    {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    {},
  )
}

function getBaseApiURL(isWebUrl?: boolean): string {
  return window.location.origin + (isWebUrl ? '' : '/api')
}

function getUploadCdnURL(): string {
  return window.location.origin + '/cdn/upload'
}

async function handleRequest(
  url: string,
  options: any,
  onError?: (error: ResponseError) => void,
  isDeleteContentType?: boolean,
): Promise<any> {
  try {
    const headers = Object.assign(getHeaders(), options.headers || {})
    if (isDeleteContentType) {
      delete headers['Content-Type']
    }
    const requestOptions: RequestInit = {
      ...options,
      headers: headers,
      mode: 'cors',
    }
    const response: Response = await fetch(url, requestOptions)

    if (response?.ok) {
      const contentType = response.headers.get('content-type')
      let responseData

      if (contentType && contentType.includes('application/json')) {
        responseData = response.json()
      } else {
        if (contentType && (contentType.includes('text/plain') || contentType.includes('text/html'))) {
          responseData = response.text()
        } else {
          responseData = response.blob()
        }
      }

      return responseData
    } else {
      let status: number = response.status
      let errorMessage: string
      const contentType = response?.headers?.get('content-type')
      if (contentType && contentType.includes('application/json')) {
        const responseData: ResponseError = await response.json()
        status = status || responseData.status
        errorMessage = responseData.errorMessage
      } else {
        errorMessage = ERROR_MESSAGE_DEFAULT
      }

      const error: ResponseError = new ResponseError(status, errorMessage)
      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }
  } catch (e: any) {
    console.error('handleRequest, ', e)
    let err
    if (e?.status && e?.errorMessage) {
      err = e
    } else {
      err = new ResponseError(500, ERROR_MESSAGE_DEFAULT)
    }
    if (onError) {
      onError(err)
    } else {
      throw err
    }
  }
}

async function apiGet(url: string, config: RestConfig = INIT_REST_CONFIG): Promise<any> {
  const {params, onError, options, isWebUrl} = config
  const urlObject = new URL(getBaseApiURL(isWebUrl) + url)
  if (params) {
    for (const param in params) {
      urlObject.searchParams.append(param, params[param])
    }
  }
  const handleRequestOptions: StringAnyMap = {method: 'GET', ...options}
  return await handleRequest(urlObject.toString(), handleRequestOptions, onError)
}

async function apiPost(url: string, data: any, config: RestConfig = INIT_REST_CONFIG): Promise<any> {
  const {options, onError} = config
  const handleRequestOptions: StringAnyMap = {method: 'POST', body: JSON.stringify(data), ...options}
  return handleRequest(getBaseApiURL() + url, handleRequestOptions, onError)
}

async function apiUploadToCdn(formData: any, config: RestConfig = INIT_REST_CONFIG): Promise<any> {
  const {options, onError} = config
  const handleRequestOptions: StringAnyMap = {
    method: 'POST',
    body: formData,
    ...options,
  }
  return handleRequest(getUploadCdnURL(), handleRequestOptions, onError, true)
}

export {apiGet, apiPost, apiUploadToCdn, getBaseApiURL}
