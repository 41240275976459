import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLoadCourses} from '../hooks/useLoadCourses'
import {IFilters} from '../domain/IFilters'
import {IReduxState} from '../../../redux/types/IReduxState'
import {TaskInfoDto} from 'learndb-share'
import {courseActions} from '../redux/actions'
import {ThemesList} from './ThemesList'

const ThemesListContainer: React.FC = () => {
  const courseDispatch = useDispatch()

  const courses = useLoadCourses()

  const filters = useSelector<IReduxState, IFilters>((state) => state.course?.filters)
  const nextTask = useSelector<IReduxState, TaskInfoDto>((state) => state.course?.nextTask)
  const token = useSelector<IReduxState, any | undefined>((state) => state.auth?.token)

  const loadData = (): void => {
    courseDispatch(courseActions.getNextTask())
    courseDispatch(courseActions.getLevels())
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const course = courses ? courses[0] : undefined

  if (!course) {
    return <></>
  }

  return <ThemesList course={course} filters={filters} nextTask={nextTask} user={token?.user} />
}

export {ThemesListContainer}
