import {useDispatch, useSelector} from 'react-redux'
import {IReduxState} from '../../../redux/types/IReduxState'
import {LandingDto} from 'learndb-share'
import {useEffect} from 'react'
import {useRequest, UseRequestTypeEnum} from '../../../hooks/useRequest'
import {legalActions} from '../redux/actions'

function useLoadLegal(type: 'license' | 'privacy' | 'offer' | 'offerLegal'): string {
  const legalDispatch = useDispatch()
  const license = useSelector<IReduxState, string | undefined>((state) => state.legal?.license)
  const privacy = useSelector<IReduxState, string | undefined>((state) => state.legal?.privacy)
  const offer = useSelector<IReduxState, string | undefined>((state) => state.legal?.offer)
  const offerLegal = useSelector<IReduxState, string | undefined>((state) => state.legal?.offerLegal)

  const [request] = useRequest<LandingDto>({
    type: UseRequestTypeEnum.API_GET,
    url: `/html/${type}.html`,
    config: {isWebUrl: true},
    onDispatch: (res) => {
      if (type === 'license') {
        legalDispatch(
          legalActions.setState({
            license: res,
          }),
        )
      } else if (type === 'privacy') {
        legalDispatch(
          legalActions.setState({
            privacy: res,
          }),
        )
      } else if (type === 'offer') {
        legalDispatch(
          legalActions.setState({
            offer: res,
          }),
        )
      } else if (type === 'offerLegal') {
        legalDispatch(
          legalActions.setState({
            offerLegal: res,
          }),
        )
      }
    },
  })

  useEffect(() => {
    if (type === 'license') {
      if (!license) {
        // console.log('load license')
        request()
      }
    } else if (type === 'privacy') {
      if (!privacy) {
        // console.log('load privacy')
        request()
      }
    } else if (type === 'offer') {
      if (!offer) {
        // console.log('load offer')
        request()
      }
    } else if (type === 'offerLegal') {
      if (!offerLegal) {
        // console.log('load offerLegal')
        request()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  if (type === 'license') {
    return license
  } else if (type === 'privacy') {
    return privacy
  } else if (type === 'offer') {
    return offer
  } else if (type === 'offerLegal') {
    return offerLegal
  }
}

export {useLoadLegal}
