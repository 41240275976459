import React from 'react'
import classNames from 'classnames'
import {Button, Card, CardBody} from 'ac_ui'
import {SubscriptionPeriod, SubscriptionPriceDto, UserDto} from 'learndb-share'
import {PayFunction} from '../hooks/usePay'

interface IPaymentTypeCardProps {
  header: string
  description: React.ReactNode
  price: SubscriptionPriceDto
  duration: string
  subscriptionPeriodId: SubscriptionPeriod
  setSubscriptionPeriodIdProp: (newValue: SubscriptionPeriod) => void
  pay: PayFunction
  userAuth: UserDto | undefined
}

const PaymentTypeCard: React.FC<IPaymentTypeCardProps> = ({
  header,
  description,
  price,
  duration,
  subscriptionPeriodId,
  setSubscriptionPeriodIdProp,
  pay,
  userAuth,
}): React.ReactElement => {
  const css = classNames('payment-type-card', subscriptionPeriodId)
  return (
    <Card className={css}>
      <CardBody className={'payment-type-card-body'}>
        <h3 className={'payment-type-card-header'}>{header}</h3>
        <div className={'payment-type-card-description'}>{description}</div>
        <div className={'d-flex justify-content-start'}>
          <div className={'payment-type-card-price'}>
            {price.priceFull !== price.priceDiscount ? (
              <div className={'mb-x4'}>
                <div className={'d-flex flex-row align-items-center '}>
                  <span className={'payment-type-card-price-old'}>
                    {price.priceFull}&nbsp;<>&#8381;</>
                    &nbsp;
                  </span>
                  <span className={'payment-type-card-price-discount-percent'}>
                    -{price.discountPercent || price.discountSum}&nbsp;{price.discountPercent ? '%' : <>&#8381;</>}
                    &nbsp;
                  </span>
                </div>
                {price.discountFromSubjectName && (
                  <p className={'payment-type-card-price-discount-from-subject mt-x2 mb-0'}>
                    скидка от <span className={'font-weight-bold'}>{price.discountFromSubjectName}</span>
                  </p>
                )}
              </div>
            ) : null}
            <div className={'d-flex flex-row align-items-start'}>
              <span className={'payment-type-card-price-main'}>{price.priceDiscount}</span>
              <span className={'payment-type-card-price-time'}>&#8381;/{duration}</span>
            </div>
          </div>
        </div>

        <div className={'payment-type-card-buttons'}>
          <Button
            color={'lime'}
            size={'lg'}
            onClick={() => {
              pay(price.priceDiscount, subscriptionPeriodId, null, null)
            }}
            className={'w-100'}>
            Оформить за рубли
          </Button>
          {!!userAuth && userAuth.wallet.coins >= price.priceDiscount && (
            <Button
              color={'yellow100'}
              size={'lg'}
              onClick={() => {
                setSubscriptionPeriodIdProp(subscriptionPeriodId)
              }}
              className={'w-100 mt-x2'}>
              Оформить за монеты
            </Button>
          )}
        </div>
        <p className={'payment-type-card-notice'}>
          Оформляя подписку вы принимаете условия лицензионного договора, пользовательского соглашения и политики
          конфиденциальности
        </p>
      </CardBody>
    </Card>
  )
}

export {PaymentTypeCard}
